import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { Tooltip } from 'antd';
import HelpTooltip from "../HelpTooltip/helpTooltip";
import useWindowSize from "../Hooks/windowSize";


const TotalReferrals = (props) => {
    const { totalEarnings } = props;
    const [width, height] = useWindowSize();
    return (
        <div className="referrals">
            <div className="referrals-heading">
                Breakdown of referrals
            </div>
            <div className="referrals-content">
                <div className="referrals-left">
                    <div className="total-earnings-graph-right-classes">
                        <div className="total-earnings-graph-right-classes-label" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            Stage 1 referrals
                            <HelpTooltip
                                title={"Total students that used your referral code and converted to paid membership."}
                            />
                        </div>
                        <span className="total-earnings-graph-right-classes-amount">
                            {totalEarnings.referrals ? totalEarnings.referrals.stage1Referrals : 0}

                        </span>
                    </div>

                    <div style={{ borderTop: '1px solid #e8efee', width: '100%' }}></div>

                    <div className="total-earnings-graph-right-referrals" style={{ marginBottom: 22 }}>
                        <span className="total-earnings-graph-right-referrals-label" style={{
                            display: 'flex', justifyContent: 'center',
                            width: width < 1024 && width > 767 ? "165px" : "unset"
                        }}>
                            <span style={{ width: width < 350 ? 150 : "unset"}}>
                                Stage 1 referrals earnings
                            </span>
                            <HelpTooltip
                                title={"Total earnings from the first payment a student made using your referral code (your earnings are 40% of their first payment)."}
                                margin={width < 1024 && width > 767 ? "-26px" : "unset"}
                            />
                        </span>
                        <span className="total-earnings-graph-right-referrals-amount">
                            {/* {totalEarnings.referrals == 0 ? "Coming soon" : `$ ${"totalEarnings.referrals.toFixed(2)"}`} */}
                            ${totalEarnings.referrals ? totalEarnings.referrals.stage1Earnings.toFixed(2) : 0}
                        </span>
                    </div>
                </div>
                <div className="referrals-right">
                    <div className="total-earnings-graph-right-classes">
                        <span className="total-earnings-graph-right-classes-label" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            Stage 2 referrals
                            <HelpTooltip
                                title={"Total students that used your referral code and stayed on the platform long enough to make four payments."}
                            />
                        </span>
                        <span className="total-earnings-graph-right-classes-amount">
                            {/* ${totalEarnings.classes ? totalEarnings.classes.toFixed(2) : 0} */}
                            {totalEarnings.referrals ? totalEarnings.referrals.stage2Referrals : 0}
                        </span>
                    </div>

                    <div style={{ borderTop: '1px solid #e8efee', width: '100%' }}></div>

                    <div className="total-earnings-graph-right-referrals" style={{ marginBottom: 22 }}>
                        <span className="total-earnings-graph-right-referrals-label" style={{
                            display: 'flex', justifyContent: 'center',
                            width: width < 1024 && width > 767 ? "165px" : "unset"
                        }}>
                            <span style={{ width: width < 350 ? 150 : "unset"}}>
                                Stage 2 referrals earnings
                            </span>
                            <HelpTooltip
                                title={"Total earnings from the fourth payment a student made that signed up with your referral code (your earnings are 80% of their fourth payment)."}
                                margin={width < 1024 && width > 767 ? "-26px" : "unset"}
                            />
                        </span>
                        <span className="total-earnings-graph-right-referrals-amount">
                            {/* {totalEarnings.referrals == 0 ? "Coming soon" : `$ ${"totalEarnings.referrals.toFixed(2)"}`} */}
                            ${totalEarnings.referrals ? totalEarnings.referrals.stage2Earnings.toFixed(2) : 0}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TotalReferrals;