import React from "react";


const CalendarBadge = (props) => {
    const { title, count, lastTile } = props;
    return (
        <div className="calendar-badge" >
            <div className="calendar-badge-popup">
                <span className="calendar-badge-popup-span" >
                    {lastTile ? `${count}` + " more" : title}
                </span>
            </div>
        </div>
    );
}

export default CalendarBadge;