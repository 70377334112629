import React from "react";


const SimpleClassesDescription = (props) => {

    const { description } = props;

    return (
        <div>
            <span className='classes-tab-description'>{description}</span>
        </div>
    );
}

export default SimpleClassesDescription;