import React, { useEffect, useRef } from 'react';
import { Tooltip } from 'antd';

import useWindowSize from '../Hooks/windowSize';
import { ReactComponent as SyncIcon } from "../../assets/img/icons/sync.svg";
import { ReactComponent as HelpIcon } from '../../assets/img/icons/help.svg';
import { ReactComponent as CloseIcon } from "../../assets/img/icons/close.svg";
import { ReactComponent as MicOff } from "../../assets/img/icons/mic_off.svg";
import { ReactComponent as MicOffIcon } from "../../assets/img/icons/mic_off.svg";
import { ReactComponent as MicOnIcon } from "../../assets/img/icons/mic_on.svg";

const ZoomDialog = (props) => {
  let { syncVideoWithStudent, handleClickOpen, audioRef, videoTracks, identity, calculateHorizontalLayoutDetails, isClicked, isDoubleClicked} = props;

  const videoReff = useRef();

  let dimensions = calculateHorizontalLayoutDetails(1, 0);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoReff.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  const wrapperStyles = {
    display: 'flex',
    position: 'fixed', /* Stay in place */
    zIndex: 1, /* Sit on top */
    left: 0,
    top: 0,
    width: '100vw', /* Full width */
    height: 'calc(100vh - 71px)', /* Full height */
    overflow: 'auto', /* Enable scroll if needed */
    backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
  }
  const participantStyle = {
    position: "relative",
    width: dimensions.cellWidth,
    height: dimensions.cellHeight,
    margin: 'auto',
    backgroundColor: '#fff',
    display: 'flex',
    borderRadius: 5,
  };

  return (
    <div onClick={() => handleClickOpen()} style={wrapperStyles}>
      <div style={participantStyle}>
        <div className="zoom-dialog-modal-left">
          {/* <div className="identity">
            {identity}
          </div> */}
          <video
            onClick={(e) => { e.stopPropagation(); }}
            style={{ boxShadow: "none", borderRadius: 5 }} ref={videoReff} autoPlay={true} 
          />
          <audio
            onClick={(e) => { e.stopPropagation(); }}
            ref={audioRef} autoPlay={true} muted={false} 
          />
          <div
              className="identity"
            >
              <div className='student-mic'
                style={{
                  backgroundColor: isDoubleClicked ? '#f57f17'
                    : isClicked ? '#1ab3b5' : 'transparent'
                }}>
                {isDoubleClicked || isClicked ?
                  <MicOnIcon className='mic-off-icon' />
                  :
                  <MicOffIcon className='mic-off-icon' />
                }
              </div>
              {isClicked || isDoubleClicked ?
                <span style={{ marginLeft: 10, marginTop: 3 }}>
                  Stop
                </span>
                :
                <span style={{ marginLeft: 10, marginTop: 3 }}>
                  {`Talk to ${identity}`}
                </span>

              }
            </div>
          {/* <div className="zoom-dialog-modal-left-talk">
            <MicOff className="zoom-dialog-modal-left-mic" />
            <span className="zoom-dialog-modal-left-talk-span">Talk to Eva</span>
          </div> */}
        </div>
        <div className="zoom-dialog-modal-right">
          <div className="zoom-dialog-modal-right-name">
            <span className="zoom-dialog-modal-right-name-span">{identity}</span>
            <div className="cross-icon-modal"
              onClick={(e) => {
                e.stopPropagation();
                handleClickOpen();
              }}
            >
              <CloseIcon className="zoom-dialog-modal-left-close" />
            </div>
          </div>

          <div>

          </div>
          <div className="zoom-dialog-modal-right-fix">
            <SyncIcon style={{ margin: 'auto' }} />
            <span 
            onClick={(e)=>{
              syncVideoWithStudent();
              e.stopPropagation();
            }} 
            style={{cursor: 'pointer'}}
            className="zoom-dialog-modal-right-fix-span">
              Fix Lagging Video
              </span>
            <Tooltip overlayClassName={"zoom-dialog-modal-right-fix-tooltip"} getPopupContainer={triggerNode => triggerNode.parentNode} placement="top" title={
              "Help Content"
            }>
              <HelpIcon className="zoom-dialog-modal-right-fix-help" />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default (ZoomDialog);
