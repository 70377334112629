import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Layout, Row, Button, Menu, Popover } from "antd";

import { logoutUser } from "../../actions/actions";
import Logo from "../../assets/img/icons/bml-logo-dark.svg";
import { ReactComponent as Plus } from "../../assets/img/icons/plus.svg";
import { ReactComponent as Menuicon } from "../../assets/img/icons/menu.svg";
import { ReactComponent as NotificationIcon } from "../../assets/img/icons/notifications.svg";

const { SubMenu } = Menu;
const { Content, Sider } = Layout;
const content = (
  <div className="plus-drawer" >
    <Link to="/createDirectCoach">
      <Button className="add-new-class-button" disabled="true"><span className="add-new-class-button-span">Add New class</span></Button>
    </Link>
    <Button className="direct-coach-button" disabled="true"><span className="add-new-class-button-span">Direct Coach a class</span></Button>

  </div>
);

const Content_profile = (props) => {

  return (

    <div className="profile-drawer">
      <Link to="/profileandaccount">
        <Button className="profile-drawer-button" >
          <span className="profile-drawer-button-span" style={{ marginRight: "40%" }}>
            Account
          </span>
        </Button>
        <Button className="profile-drawer-button" >
          <span className="profile-drawer-button-span" style={{ marginRight: "50%" }}>
            Profile
          </span>
        </Button>
      </Link>
      <hr style={{ border: "1px solid white", margin: 0 }} />
      <Button className="profile-drawer-button" onClick={props.logout}><span className="profile-drawer-button-logout" style={{ marginRight: "40%" }}>Log out</span></Button>
    </div>);
}

const Header = (props) => {
  const { tokenExpire, loginData } = props;
  const { Header } = Layout;

  let firstName = loginData ? loginData.userName.split(" ")[0] : null;
  const { SubMenu } = Menu;

  const logout = () => {
    props.logoutUser();
  }

  return (
    <>
      <Header className="header" style={{ height: 60 }}>
        <Row style={{ justifyContent: "space-between", }}>
          <div className="menuIconDiv">
            <Menuicon className="menuIcon" onClick={props.toggleCollapseState} />
          </div>
          <Link to="/" className="logo-link">
            <div className="logo-header">
              <img
                className="logo-image-header"
                width="auto"
                height="auto"
                src={Logo}
                alt=""
              />
            </div>
          </Link>
          <div>
            {
              !loginData
                ? (
                  <div className="footer-button"
                    style={{
                      width: '150px',
                      height: 60,
                      display: 'flex',
                      margin: 0,
                      alignItems: 'center',
                      textAlign: "right",
                      justifyContent: "right",

                    }}>
                    <a
                      href="https://app.bodymindlife.online/user/signup/"
                    >
                      <Button
                        style={{
                          backgroundColor: "#8fb594",
                          borderColor: "#8fb594",
                          width: "100px",
                          height: 40,
                          color: "black",

                        }}
                        type="primary"
                        htmlType="submit"
                        className="logout-button"
                      >
                        Signup
                      </Button>
                    </a>
                  </div>
                ) : (
                  <div className="footer-button"
                    style={{
                      width: 'auto',
                      height: 60,
                      display: 'flex',
                      margin: 0,
                      alignItems: 'center',
                      textAlign: "right",
                      justifyContent: "right",

                    }}>
                    {/* <Button
                      style={{
                        backgroundColor: "#8fb594",
                        borderColor: "#8fb594",
                        width: 100,
                        height: 40,
                        color: "black"
                      }}
                      type="primary"
                      htmlType="submit"
                      className="logout-button"
                      onClick={logout}
                    >
                      Logout
                    </Button> */}
                    <div className="content-profile">
                      <Popover overlayClassName={'content-profile'} placement="bottom" content={<Content_profile logout={logout} />} trigger="click">
                        <div className="content-profile-data" >
                          <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar" className="avatar" />
                          <span className="userName">{firstName}</span>
                        </div>
                      </Popover>
                    </div>
                    <Popover overlayClassName={'transparent-popup'} placement="bottomRight" color={'transparent'} content={content} >
                      <div className="PlusDiv">
                        <Plus className={"plus"} /> </div>
                    </Popover>
                  </div>
                )
            }
          </div>
        </Row>
      </Header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tokenExpire: state.tokenExpire,
    loginData: state.loginData,
    isLogin: state.isLogin,
    teacherProfileDetails: state.teacherProfileDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: (data) => dispatch(logoutUser(data)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);