import React from 'react';
import moment from 'moment';
import HelpTooltip from '../HelpTooltip/helpTooltip';

const TotalEarnings = (props) => {

    const { totalEarnings, name, monthlyGraph, activeBar } = props;
    return (

        <div className="total-earnings-graph">
            <div className="total-earnings-graph-left">
                <span className="total-earnings-graph-left-label">
                    {name ? "Total earnings" : !monthlyGraph && activeBar === 0 ?
                        "So far earned this week" : monthlyGraph && activeBar === 0 ?
                            "So far earned this month" : !monthlyGraph ?
                                `${moment(totalEarnings.start).format("ddd")} - 
                                ${moment(totalEarnings.end).format("ddd")},
                                ${moment(totalEarnings.start).format("DD")} - 
                                ${moment(totalEarnings.end).format("DD MMM YYYY")}`
                                : moment(totalEarnings.start).format("MMMM YYYY")
                    }
                </span>
                <span className="total-earnings-graph-left-amount">
                    ${totalEarnings.total ? totalEarnings.total.toFixed(2) : 0}

                </span>
            </div>
            <div className="total-earnings-graph-right">
                <div className="total-earnings-graph-right-classes">
                    <span className="total-earnings-graph-right-classes-label">
                        From classes
                    </span>
                    <span className="total-earnings-graph-right-classes-amount">
                        ${totalEarnings.classes ? totalEarnings.classes.toFixed(2) : 0}
                    </span>
                </div>

                <div style={{ borderTop: '1px solid #e8efee', width: '100%' }}></div>

                <div className="total-earnings-graph-right-referrals" style={{ marginBottom: 22 }}>
                    <span className="total-earnings-graph-right-referrals-label" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        From referrals
                        {name ?

                            <HelpTooltip
                                title={"Total earnings from your referral code"}
                            />
                            :
                            null}
                    </span>
                    <span className="total-earnings-graph-right-referrals-amount">
                        {totalEarnings.referrals && name ? `$ ${totalEarnings.referrals.earnings.toFixed(2)}` :
                            totalEarnings.referrals === 0 && name ? 0 : "Coming soon"}
                    </span>
                </div>

                <div style={{ borderTop: '1px solid #e8efee', width: '100%' }}></div>
                {name ?

                    <div className="total-earnings-graph-right-referrals" >
                        <span className="total-earnings-graph-right-referrals-label" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            Number of referrals
                            <HelpTooltip
                                title={"Total students that used your referral code and signed up for a paid membership."}
                            />
                        </span>
                        <span className="total-earnings-graph-right-referrals-amount">
                            {totalEarnings.referrals == 0 ? 0 : `${totalEarnings.referrals.referrals}`}
                        </span>
                    </div>
                    : null
                }
            </div>
        </div>
    );
}

export default TotalEarnings;