import React, { useEffect } from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button } from 'antd';
import moment from 'moment';

import { cancelOTSClass, closeCancelOTSClassModal } from '../../actions/actions';

const CancelClassModal = (props) => {

    const { handleChangeCancelModal, handleChangeDateModal, pending, otsClassCanceled, handleClassChange, selectedClass, loginData } = props;
    const enableTimeModal = (moment(selectedClass.classData.OTS.startDateTime).subtract(48, 'hours')).isAfter(moment());

    useEffect(() => {
        if (otsClassCanceled) {
            handleChangeCancelModal();
            handleClassChange(false);
            props.closeCancelOTSClassModal(false);
        }
    }, [otsClassCanceled]);

    const onChangeCanel = () => {
        const data = {
            token: loginData ? loginData.access : null,
            otsId: selectedClass ? selectedClass.classData.OTS.id : null
        };
        props.cancelOTSClass(data);
    };

    const openDateTimeModal = () => {
        handleChangeCancelModal();
        handleChangeDateModal();
    };

    const wrapperStyles = {
        display: 'flex',
        position: 'absolute', /* Stay in place */
        zIndex: 1, /* Sit on top */
        left: 0,
        top: 0,
        width: '100vw', /* Full width */
        height: '100vh', /* Full height */
        overflow: 'auto', /* Enable scroll if needed */
        backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
    }
    const participantStyle = {
        position: "relative",
        width: window.innerWidth > 766 ? 608 : 344,
        height: window.innerWidth > 766 ? 328 : 418,
        margin: 'auto',
        borderRadius: 5,
        backgroundColor: "white"
    };

    return (
        <div onClick={() => { handleChangeCancelModal() }} style={wrapperStyles}>
            <div className='modal' style={participantStyle} onClick={(e) => { e.stopPropagation(); }} >
                <span className='modal-heading' >
                    Are you sure you want to cancel this class?
                </span>
                <span className='modal-description'>
                    We understand that sometimes life gets in the way. We’ll
                    send a message to all attending students appologising for
                    the cancellation.
                </span>
                <span className='modal-description'>
                    <div>
                        <span>
                            You can change the date and time upto 48 hour prior to the
                            class.{' '}
                        </span>
                        <span
                            onClick={(e) => {
                                enableTimeModal ?
                                    openDateTimeModal() : e.preventDefault()
                            }}
                            style={{
                                color: '#1d615a', 
                                textDecoration: 'underline',
                                cursor: enableTimeModal ? 'pointer' : 'default',
                            }}
                        >
                            Change date and time.
                        </span>
                    </div>
                </span>
                <div className='modal-select'>
                    <Button className="modal-button" onClick={() => { handleChangeCancelModal() }} >
                        Close
                    </Button>
                    <Button className="modal-button-cancel" loading={pending} onClick={() => { onChangeCanel() }}>Cancel class</Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        pending: state.pending,
        loginData: state.loginData,
        otsClassCanceled: state.otsClassCanceled,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        cancelOTSClass: (data) => dispatch(cancelOTSClass(data)),
        closeCancelOTSClassModal: (data) => dispatch(closeCancelOTSClassModal(data)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CancelClassModal);