import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { Calendar, Row } from 'antd';
import moment from 'moment';

import Circle from '../SideNav/Circle';
import CalendarBadge from './calendarBadge';
import { ReactComponent as LeftArrow } from '../../assets/img/icons/arrow-back.svg';
import { ReactComponent as DownArrow } from '../../assets/img/icons/arrow-down.svg';
import { ReactComponent as RightArrow } from '../../assets/img/icons/arrow-next.svg';
import { ReactComponent as UpArrow } from '../../assets/img/icons/arrow-triangle-up.svg';
import useWindowSize from '../Hooks/windowSize';

const CalendarView = (props) => {
  const { handleDateChange, classesParsed } = props;

  const [isToday, setToday] = useState(false);
  const date = new Date();
  const currentDateRef = useRef(null);
  const [width, height] = useWindowSize();
  const [verticalCalendar, setVerticalCalendar] = useState(false);

  moment.updateLocale('en', {
    weekdaysMin: window.innerWidth > 1025 ? ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"] :
      ["S", "M", "T", "W", "T", "F", "S"]
  });

  useEffect(() => {
    if (width < 1025) {
      if (currentDateRef && currentDateRef.current) {
        currentDateRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      } else {
        setTimeout(() => {
          currentDateRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          })
        }, 100)
      }
    }
  }, [isToday])

  useEffect(() => {
    setVerticalCalendar(false)
  }, [width])



  const getListData = (value) => {
    return classesParsed[`${value.format("YYYYMMDD")}`] || [];
  }

  const onDateChange = (value) => {
    handleDateChange(value);
  }

  const changeCalendarRotation = () => {
    setVerticalCalendar(!verticalCalendar);
  }

  const dateFullCellRender = (value) => {
    const listData = getListData(value);
    return (
      <div className='date-fullcell-responsive'
        onClick={() => { onDateChange(value) }}
      >
        <span className="date-fullcell-responsive-span"
          ref={moment(value).isSame(date, 'day') ? currentDateRef : null}
        >
          {moment(value).format('DD')}
        </span>
        <div className="calendar-circle">
          {listData.map((item, index) => (
            <>
              {index < 3 ?
                <Circle

                  color={(item.status === 'Published' || item.status === "Publishing") ?
                    '#1d615a' : (item.status === "pending" || item.status === "In review") ?
                      '#f57f17' : item.status === "Video Linked" ? "#d32f2f" : "red"
                  }
                  borderColor={'#fff'}
                  circleHeight={6}
                  circleWidth={6}
                  circlePaddingTop={true}
                  circleAlignSelf={'center'}
                  circleMarginLeft={true}
                />
                :
                null}
            </>
          ))}
        </div>
      </div>
    )
  }

  const dateFullScreenCellRender = (value) => {
    const listData = getListData(value);
    return (
      <div
        className='date-fullscreen-cell'
        onClick={() => { onDateChange(value) }}
      >
        <div className="date-fullscreen-cell-container">
          <span className="date-fullscreen-cell-date">{moment(value).format('DD')}</span>
        </div>
        <div className='date-fullscreen-cell-content' >
          <ul className="events">
            {listData.map((item, index) => {
              return (
                <li key={index}
                  style={{ backgroundColor: (item.status === "Publishing" || item.status === "Published") ? '#2e7d32' : item.status === "Video Linked" ? '#d32f2f' : "#f57f17" }}
                >
                  {index < 2 ?
                    <CalendarBadge title={item.content} classData={item.classData} count={listData?.length} lastTile={false} />
                    :
                    null
                  }
                </li>
              )
            })}
            {
              listData.length > 2
              &&
              <span className="more-tile">+ {listData.length - 2} more</span>
            }
          </ul>
        </div>
      </div>
    )
  };

  return (
    <div className='calendar-main' style={{
      display: 'flex',
      boxShadow: "0 8px 6px -6px rgb(0 0 0 / 10%)"
    }}>
      <div className={verticalCalendar ? 'classes-full-calendar' : 'classes-calendar'} >
        <Calendar
          className='calendar-new'
          dateFullCellRender={width < 1025 ? dateFullCellRender : dateFullScreenCellRender}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const current = value.clone();
            const localeData = value.localeData();
            const months = [];
            for (let i = 0; i < 12; i++) {
              current.month(i);
              months.push(localeData.months(current));
            }

            let month = value.month();
            let year = value.year();
            let today = moment().toDate();

            const handleLeftClick = () => {
              const newValue = value.clone();
              newValue.subtract(1, 'months');
              onChange(newValue);
            }

            const handleRightClick = () => {
              const newValue = value.clone();
              newValue.add(1, 'months');
              onChange(newValue);
            }

            const handleTodayClick = () => {
              onChange(moment());
              setToday(!isToday);
            }

            return (
              <div className="calendar-header">
                <Row style={{ width: '100%' }}>
                  <span className="calendar-month-title">{months[month]} {year}</span>
                  <div className='calendar-header-responsive-arrow' onClick={() => { changeCalendarRotation() }} >
                    {verticalCalendar ? (
                      <UpArrow className='calendar-header-down-arrow' />
                    ) : (
                      <DownArrow className='calendar-header-down-arrow' />
                    )}
                  </div>
                  <div className='calendar-header-arrows'>
                    <LeftArrow style={{ height: 15, margin: "5px 0px 0px 16px", cursor: "pointer" }} onClick={handleLeftClick} />
                    <RightArrow style={{ height: 15, marginLeft: 26, cursor: "pointer" }} onClick={handleRightClick} />
                  </div>
                  <div className="calendar-today-button" onClick={handleTodayClick}>
                    <span >Today</span>
                  </div>
                </Row>
              </div>
            );
          }
          }

        />
      </div>
    </div>
  );
}

export default CalendarView;