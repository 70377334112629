import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { Collapse } from 'antd';
import PopularClassLength from './popularClassLength';
import MostActiveHour from './mostActiveHour';
import HighChartGraph from '../HighCharts/highChartGraph';
import LocationInformation from './locationInformation';
import ActiveHourImage from '../../assets/img/option1.jpg';

const { Panel } = Collapse;

const Layout = (props) => {
  const { item, active } = props;
  const { name, detail } = item;

  let largest = 0;
  let countryName = '';

  const [biggestCountryCount, setBiggestCountryCount] = useState({
    country: countryName,
    count: largest
  });

  useEffect(() => {
    if (item.name === 'location') {
      let totalViews = 0;
      Object.keys(detail.country).map(function (key, index) {
        if (detail.country[key] > largest) {
          largest = detail.country[key];
          countryName = key;
        }
        totalViews += detail.country[key];
      })
      setBiggestCountryCount(Object.assign({},
        biggestCountryCount,
        { country: countryName },
        {
          count: largest === 0 || totalViews === 0 ? '0%' :
            `${parseInt(largest / totalViews * 100)}%`
        })
      );
    }
  }, []);

  return (
    <div>
      <Card size="size"
        className="audience-card"
        style={{ borderRadius: 10, border:"none" }}
        cover={<div className="card-cover-audience">
          <div className="card-cover-audience-container" style={{ zIndex: 99 }}>
            {name === 'location' &&
              (
                <span className="card-cover-audience-count" style={{ display: 'flex' }}>
                  {biggestCountryCount.country}
                </span>
              )
            }
            {item.name === 'classLength' && detail.mostCommon !== '' ||
              item.name === 'activeHour' && detail.mostCommon !== '' ||
              item.name === 'location' && detail.country[Object.keys(detail.country)[0]] ||
              item.name === 'completion' && detail.all[0] ? (

              <span className="card-cover-audience-count"
                style={{ fontSize: name === 'location' ? 16 : 24 }}
              >
                {name === 'location' ? biggestCountryCount.count :
                  name === 'completion' ? `${detail.average.toFixed(2) * 100}%`
                    : detail.mostCommon}
              </span>
            ) : (
              <span className="card-cover-audience-count" style={{ fontSize: 16, fontWeight: 600 }}>
                Your performance will appear here
              </span>
            )
            }
          </div>
          <span className="card-cover-audience-image-title">
            {detail.title}
          </span>
          <div className={
            item.name === 'classLength' && detail.mostCommon !== '' ||
              item.name === 'activeHour' && detail.mostCommon !== '' ||
              item.name === 'location' && detail.country[Object.keys(detail.country)[0]] ||
              item.name === 'completion' && detail.all[0] ? '' : '___wrappper'}>
            <img className={
              item.name === 'classLength' && detail.mostCommon !== '' ||
                item.name === 'activeHour' && detail.mostCommon !== '' ||
                item.name === 'location' && detail.country[Object.keys(detail.country)[0]] ||
                item.name === 'completion' && detail.all[0] ?
                "card-cover-audience-image" :
                "card-cover-audience-image _imagegray"
            } alt="card-image"
              src={
                //image Size should be 512 x 290
                name === 'completion' ? "https://i.ibb.co/9cTvrzM/4.jpg" :
                  name === 'classLength' ? "https://i.ibb.co/bPC47SP/photo-1544367567-0f2fcb009e0b.jpg" :
                    name === 'activeHour' ? ActiveHourImage :
                      "https://www.healthshots.com/wp-content/uploads/2020/04/yoga-for-core.jpg"
              }
              style={{
                objectFit: "cover", width: '100%',
                height: "auto", borderRadius: active ? '10px 10px 0px 0px' : 10,
              }}
            />
          </div>

        </div>}
      >
      </Card>
    </div>
  );
}


const AudienceCard = (props) => {
  const { item, index } = props;
  const [active, setActive] = useState(false)
  let completionData = [];
  let xAxisCompletionData = [];

  const [dataAverageCompletionRate, setDataAverageCompletionRate] = useState({
    firstDataName: "",
    firstDataColor: "#1d615a",
    firstData: completionData,
    xAxisData: xAxisCompletionData,
  });

  useEffect(() => {
    if (item.name === 'completion') {
      let sorted = item.detail.all.sort((a, b) => a - b);
      const occurrences = sorted.reduce(function (acc, curr) {
        let resultant = curr.toFixed(1);
        return acc[resultant] ? ++acc[resultant] : acc[resultant] = 1, acc
      }, {});
      Object.keys(occurrences).map(function (key, index) {
        completionData.push(occurrences[key])
        xAxisCompletionData.push(`${key * 100}%`)
      })
      setDataAverageCompletionRate(Object.assign({},
        dataAverageCompletionRate,
        { firstData: completionData },
        { xAxisData: xAxisCompletionData }))
    }
  }, []);

  const onChange = () => {
    setActive(!active)
  }

  return (
    <>
      <Collapse onChange={onChange} bordered='false'
        className="audience-collapse"
        collapsible={
          (item.name === 'activeHour' && item.detail.all[Object.keys(item.detail.all)[0]]) ||
            (item.name === 'classLength' && item.detail.all[Object.keys(item.detail.all)[0]]) ||
            (item.name === 'location' && item.detail.country[Object.keys(item.detail.country)[0]]) ?
            "header" : "disabled"
        }

        bordered={false} ghost
        style={{
          boxShadow: '0 0 10px 1px #9999995e', borderRadius: 10,
          //height: '98%' 
        }}>

        <Panel showArrow={false} header={<Layout item={item} active={active} />}
          key={index} expandIcon="disable" className='audience-panel'
        >

          {item.name === 'classLength' ? (

            <PopularClassLength data={item.detail} />

          ) : item.name === 'location' ? (

            <LocationInformation data={item.detail} />

          ) : item.name === 'activeHour' ? (

            <MostActiveHour data={item.detail} />

          )
            : (
              null
              // <HighChartGraph
              //   enableFirstAndLastXaxisLables={true}
              //   removeYaxis={true}
              //   data={dataAverageCompletionRate}
              // />
            )

          }
        </Panel>
      </Collapse>

    </>
  );
}

export default AudienceCard;