import React, { useState, useEffect, useRef, useStyles } from "react";
import { usePubNub } from "pubnub-react";
import { connect } from "react-redux";

import {
  addLocalParticipantId,
  changeStudentRules,
  communicationWay,
  muteUnmuteVideoPlayer,
  addId,
  removeId,
  zoomModal,
  syncVideo
} from "../../actions/actions";
import { Tag, Popover } from 'antd';
import AboutIcon from "../../assets/img/icons/aboutIcon.svg";
import StudentInfoDialog from "./StudentInfoDialog";
import AudioLevelIndicator from "../AudioLevelIndicator";
import demoImage from "../../assets/img/aylanto.jpg";
import useWindowSize from "../Hooks/windowSize";
import { ReactComponent as TalkIcon } from '../../assets/img/icons/talk.svg';
import { ReactComponent as MicOffIcon } from "../../assets/img/icons/mic_off.svg";
import { ReactComponent as MicOnIcon } from "../../assets/img/icons/mic_on.svg";
import { ReactComponent as GraphIcon } from "../../assets/img/icons/graph.svg";
import { ReactComponent as SyncIcon } from "../../assets/img/icons/sync.svg";
import { ReactComponent as MaximizeIcon } from "../../assets/img/icons/maximise.svg";
import { ReactComponent as ConverseIcon } from '../../assets/img/icons/converse.svg';
import { ReactComponent as HelpIcon } from '../../assets/img/icons/help.svg';
import ZoomDialog from "./ZoomDialog";

const Participant = (props) => {
  const {
    participant,
    localParticipant,
    localParticipantId,
    localParticipantTracks,
    localParticipantSid,
    newParticipant,
    isTeacherMuted,
    otsRoomData,
    muteStudents,
    speakToAllEnabled,
    roomId,
    isZoomModal,
    participantIndex,
    isSyncVideo
  } = props;

  const [width, height] = useWindowSize();
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [localAudioTrack, setLocalAudioTrack] = useState([]);
  const [isDoubleClicked, setDoubleClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const channelName = `OTS_TWL_${otsRoomData.OTS.roomID}`;
  const [isClicked, setClicked] = useState(false);
  const [showNewParticipantTag, setShowNewParticipantTag] = useState(false);
  const [display, setDisplay] = useState("notHoverStudentVideoTag");

  const pubnub = usePubNub();
  const styles = {
    border: isDoubleClicked
      ? "2px solid 	#f57f17"
      : isClicked
        ? "2px solid 	#1ab3b5"
        : null,
  };
  let clickCount = 0;
  let singleClickTimer = "";

  const videoRef = useRef();
  const audioRef = useRef();

  if (localParticipantSid) {
    props.addLocalParticipantId(localParticipantSid);
  }

  useEffect(() => {
    setTimeout(function () {
      setShowNewParticipantTag(true);
    }, 3000);
  }, []);

  useEffect(() => {
    setClicked(false);
    setDoubleClicked(muteStudents);
  }, [muteStudents]);

  useEffect(() => {
    setClicked(speakToAllEnabled);
    if (muteStudents === false) setDoubleClicked(false);
  }, [speakToAllEnabled]);

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    const player = props.participantIds.length === 0 ? false : true;
    props.muteUnmuteVideoPlayer(player);
  }, [props.participantIds]);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));
    if (localParticipantTracks) {
      setLocalAudioTrack(trackpubsToTracks(localParticipantTracks.audioTracks));
    }

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks, open, isZoomModal]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack) {
      if (localParticipant && audioTrack.isEnabled === true) {
        audioTrack.disable();
      }
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks, localParticipant]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      if (localParticipant) {
        if (isTeacherMuted) {
          audioTrack.enable();
          props.muteUnmuteVideoPlayer(true);
        } else {
          audioTrack.disable();
          props.muteUnmuteVideoPlayer(false);
        }
      }
    }
  }, [isTeacherMuted]);

  const handleClick = () => {
    if (!muteStudents && !speakToAllEnabled) {
      clickCount++;
      if (clickCount === 1) {
        singleClickTimer = setTimeout(function () {
          clickCount = 0;
          singleClick();
        }, 300);
      } else if (clickCount === 2) {
        clearTimeout(singleClickTimer);
        clickCount = 0;
        doubleClick();
      }
    }
  };

  const allClicks = (code, stId, rules, player, way) => {
    pubnub.publish({
      channel: channelName,
      message: {
        statusCode: code,
        studentId: stId,
      },
    });
    props.changeStudentRules(rules);
    if (way) {
      props.communicationWay(way);
    }
    if (props.participantIds) {
      props.muteUnmuteVideoPlayer(player);
    }
  };

  const singleClick = () => {
    const audioTrack = localAudioTrack[0];
    const id = participant.sid;
    if (isClicked) {
      if (!muteStudents) {
        audioTrack.disable();
      }
      props.removeId(id);
      const rules = {
        type: "exclude",
        roomId: roomId,
        studentId: participant.sid,
        teacherId: localParticipantId,
      };
      allClicks(100, id, rules, false);
    } else {
      const rules = {
        type: "include",
        roomId: roomId,
        studentId: participant.sid,
        teacherId: localParticipantId,
      };
      audioTrack && audioTrack.enable();
      allClicks(101, id, rules, true, false);
      setDoubleClicked(false);
      props.addId(id);
    }
    setClicked(!isClicked);
  };

  const doubleClick = () => {
    const audioTrack = localAudioTrack[0];
    const id = participant.sid;
    if (!localParticipant) {
      if (isDoubleClicked) {
        if (!muteStudents) {
          audioTrack.disable();
        }
        props.removeId(id);
        const rules = {
          type: "exclude",
          roomId: roomId,
          studentId: participant.sid,
          teacherId: localParticipantId,
        };
        allClicks(104, id, rules, false, false);
      } else {
        const rules = {
          type: "include",
          roomId: roomId,
          studentId: participant.sid,
          teacherId: localParticipantId,
        };
        props.addId(id);
        audioTrack && audioTrack.enable();
        allClicks(103, id, rules, true, true);
        setClicked(false);
      }
      setDoubleClicked(!isDoubleClicked);
    }
  };

  const syncVideoWithStudent = () => {
    props.syncVideo(!isSyncVideo)
  }

  const cellSpacing = 9;
  const footerHeight = 72;
  const calculateHorizontalLayoutDetails = (numberOfCellsInRow, numberOfCellsInColumn) => {
    //Screen height
    const sideSpacing = numberOfCellsInRow === 1 ? 179 : numberOfCellsInColumn === 2 ? 119 : cellSpacing;
    const numberOfSpacingHorizontal = numberOfCellsInRow + 1;
    const responsiveWidth = width > 767 && width < 1025 ? height : width;
    const ScreenPaddingHorizontal = sideSpacing * numberOfSpacingHorizontal;
    const cellWidth = (responsiveWidth - ScreenPaddingHorizontal) / numberOfCellsInRow;
    const cellHeight = cellWidth * (9 / 16);

    const numberOfSpacingVertical = numberOfCellsInColumn + 1;
    const ScreenPaddingVertical = sideSpacing * numberOfSpacingVertical + footerHeight;
    const isHorizontalLayout = (ScreenPaddingVertical + (cellHeight * numberOfCellsInColumn)) > height ? false : true;

    return {
      cellWidth,
      cellHeight,
      isHorizontalLayout
    }
  }

  const calculateVerticalLayoutDetails = (numberOfCellsInRow, numberOfCellsInColumn) => {
    const numberOfSpacing = numberOfCellsInColumn + 1;
    const ScreenPadding = cellSpacing * numberOfSpacing;

    const cellHeight = `calc((100vh - ${footerHeight}px - ${ScreenPadding}px) * 0.33)`;
    const cellWidth = `calc(((100vh - ${footerHeight}px - ${ScreenPadding}px) * 0.33) * (16/9))`;
    return {
      cellWidth,
      cellHeight
    }

  }
  const getDimensionForTheLayout = (cellLayout) => {
    let dimensions;
    switch (cellLayout) {

      case 1:
        dimensions = calculateHorizontalLayoutDetails(1, 0);
        return {
          width: dimensions.cellWidth,
          height: dimensions.cellHeight
        }

      case 2:
        dimensions = calculateHorizontalLayoutDetails(2, 1);
        return {
          width: dimensions.cellWidth,
          height: dimensions.cellHeight
        }
      case 3:
      case 4:
        dimensions = calculateHorizontalLayoutDetails(2, 2);
        return {
          width: dimensions.cellWidth,
          height: dimensions.cellHeight
        }

      case 5:
      case 6:
        dimensions = calculateHorizontalLayoutDetails(3, 1);
        if (dimensions?.isHorizontalLayout){
          const newWidth = newParticipant > 4 && newParticipant < 7 ? dimensions.cellWidth -10 : dimensions.cellWidth;
          const newHeight = newParticipant > 4 && newParticipant < 7 ? dimensions.cellHeight -5 : dimensions.cellHeight;
          return {
            width: newWidth,
            height: newHeight
          }
        }
        else {
          const dimensions = calculateVerticalLayoutDetails(3, 2);
          return {
            width: dimensions.cellWidth,
            height: dimensions.cellHeight
          }
        }

      case 7:
      case 8:
        dimensions = width > 767 && width < 1024 ?
          calculateHorizontalLayoutDetails(3, 2) : calculateHorizontalLayoutDetails(4, 3);
        return {
          width: dimensions.cellWidth,
          height: dimensions.cellHeight
        }

      case 9:
      case 10:
      case 11:
      case 12:
        dimensions = calculateHorizontalLayoutDetails(4, 3);
        const newWidth = newParticipant > 8 && newParticipant < 10 ? dimensions.cellWidth -10 : dimensions.cellWidth;
        const newHeight = newParticipant > 8 && newParticipant < 10 ? dimensions.cellHeight -5 : dimensions.cellHeight;
        return {
          width: newWidth,
          height: newHeight
        }

      case 13:
      case 14:
      case 15:
        dimensions = calculateHorizontalLayoutDetails(5, 3);
        return {
          width: dimensions.cellWidth,
          height: dimensions.cellHeight
        }

      default:
        dimensions = calculateHorizontalLayoutDetails(5, 3);
        return {
          width: dimensions.cellWidth,
          height: dimensions.cellHeight
        }
    }
  }
  const getClassName = (cellLayout) => {
    switch (cellLayout) {
      case 2:
      case 3:
      case 4:
        return `cell-layout-child-4`

      case 5:
      case 6:
        return `cell-layout-child-6`

      case 7:
      case 8:
        return `cell-layout-child-8`

      case 9:
      case 10:
      case 11:
      case 12:
        return `cell-layout-child-12`

      case 13:
      case 14:
      case 15:
        return `cell-layout-child-15`
      default:
        break;
    }

  }
  const details = getDimensionForTheLayout(newParticipant);

  const participantStyle = {
    position: "relative",
    width: width < 767 ? 'unset' : details.width,
    height: width < 767 ? 'unset' : details.height,
  };

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const closeDetailModal = () => {
    setOpen(false);
  };

  const showCommunicationTag = e => {
    e.preventDefault();
    setDisplay("hoverStudentVideoTag");
  };

  const hideCommunicationTag = e => {
    e.preventDefault();
    setDisplay("notHoverStudentVideoTag");
  };

  const content = (
    <div>
      <p className='sync-icon-container'>
        <div className='sync-icon' style={{width: '10%'}}>
          <SyncIcon className='' />
        </div>
        <div style={{ display: 'flex', width: '80%', cursor: 'pointer' }} onClick={()=>syncVideoWithStudent()}>
          <span className='sync-icon-description'>
            Fix Lagging Video
          </span>
        </div>
        <div className='popover-help-icon-container'>
          <HelpIcon className="popover-help-icon" />
        </div>
      </p>
    </div>
  );


  return (
    <>

      {!localParticipant ? (
        <>
          <div
            className={`${getClassName(newParticipant)}`}
            style={
              {...participantStyle, 
                marginBottom: width < 767 && newParticipant === participantIndex + 1 ? 80 : null
              }
            }
            onMouseEnter={e => showCommunicationTag(e)}
            onMouseLeave={e => hideCommunicationTag(e)}
          >
            <div
              className="participant"
              style={
                participantStyle
              }
              onClick={()=>handleClick()}
            >
              {/* <img src={demoImage} style={{ width: '100%', objectFit: 'cover', maxHeight: '100%' }} /> */}

              <video ref={videoRef} autoPlay={true} style={styles} />
              <audio
                ref={audioRef}
                autoPlay={true} muted={false} />
            </div>
            <div className={display}
              onClick={() => handleClick()}
            >
              {isDoubleClicked || !isClicked &&
                <span className='hover-video-tag' style={{cursor:'pointer'}}>
                  Tap once to Talk. Double tap to Converse.
                </span>
              }
            </div>
            <div className='hoverStudentVideoTag'>
              {isDoubleClicked ?
                <span className='hover-video-tag' style={{ display: 'flex', alignItems: 'center' }}>
                  <ConverseIcon className='converse-icon-tag' />
                  {` Talking with ${participant.identity}`}
                </span>
                :
                isClicked ?
                  <span className='hover-video-tag'>
                    <TalkIcon className='talk-icon-tag' />
                    {` Talking to ${participant.identity}`}
                  </span>
                  : null

              }
            </div>
            <div className='student-graph'>
              <Popover
                overlayClassName={'student-graph'}
                // trigger="click" 
                placement="bottomLeft"
                content={content}
                title={participant.identity}
              >
                <GraphIcon className='student-graph-icon' />
              </Popover>
            </div>
            <div
              className="identity"
            >
              <div className='student-mic'
                style={{
                  backgroundColor: isDoubleClicked ? '#f57f17'
                    : isClicked ? '#1ab3b5' : 'transparent'
                }}>
                {isDoubleClicked || isClicked ?
                  <MicOnIcon className='mic-off-icon' />
                  :
                  <MicOffIcon className='mic-off-icon' />
                }
              </div>
              {isClicked || isDoubleClicked ?
                <span style={{ marginLeft: 10, marginTop: 3 }}>
                  Stop
                </span>
                :
                <span style={{ marginLeft: 10, marginTop: 3 }}>
                  {`Talk to ${participant.identity}`}
                </span>

              }
              {!showNewParticipantTag &&
                <Tag color="#00ffe5" className='tag-new'>New</Tag>
              }
            </div>
            <div
              className="student-video-expand-icon"
              onClick={handleClickOpen}
            >
              <MaximizeIcon className="collapsed-footer-video-overlays-icon" />
            </div>
          </div>
          {open && (
            <ZoomDialog
              calculateHorizontalLayoutDetails={calculateHorizontalLayoutDetails}
              handleClickOpen={handleClickOpen}
              identity={participant.identity}
              videoTracks={videoTracks}
              audioRef={audioRef}
              videoRef={videoRef}
              isClicked={isClicked}
              isDoubleClicked={isDoubleClicked}
              syncVideoWithStudent={syncVideoWithStudent}
            />
          )}
        </>
      ) : null}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    communicationWay: (data) => dispatch(communicationWay(data)),
    changeStudentRules: (data) => dispatch(changeStudentRules(data)),
    addLocalParticipantId: (data) => dispatch(addLocalParticipantId(data)),
    muteUnmuteVideoPlayer: (data) => dispatch(muteUnmuteVideoPlayer(data)),
    addId: (data) => dispatch(addId(data)),
    removeId: (data) => dispatch(removeId(data)),
    zoomModal: (data) => dispatch(zoomModal(data)),
    syncVideo: (data) => dispatch(syncVideo(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    studentIds: state.studentIds,
    otsRoomData: state.otsRoomData,
    muteStudents: state.muteStudents,
    isTeacherMuted: state.isTeacherMuted,
    newParticipant: state.newParticipant,
    speakToAllEnabled: state.speakToAllEnabled,
    participantIds: state.participantIds,
    isZoomModal: state.isZoomModal,
    isSyncVideo: state.isSyncVideo
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Participant);
