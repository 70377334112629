import React, { useEffect } from "react";
import moment from 'moment'
import 'moment-timezone'
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { Button, Row, Col } from "antd";

import ClassDetailImage from "../../assets/img/thumbnailOTS.png";

const OTSClassItem = ({ props, val }) => {
    const { loginData } = props;
    const date = new Date();
    const tz = moment.tz.guess();

    const startOtsClass = (id) => {
        const data = {
            token: loginData.access,
            otsId: id
        }
        props.createOTSRoom(data);
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>{` `}Class</span>;
        } else {
            // Render a countdown
            return <span>{` - `}{hours}:{minutes}:{seconds}</span>;
        }
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div style={{ backgroundColor: "#eef4ef" }}>
                <Row>
                    <Col sm={{ span: 14 }}>
                        <Row>
                            <Col sm={{ span: 10 }} style={{ maxWidth: 300, postion: "relative", overflow: "hidden" }}>
                                {val.Max === val.Booked ? (
                                    <div className="tag-full">FULL</div>
                                ) : null}
                                <img className="ots-home-image"
                                    style={{ marginTop: 5, maxWidth: "100%", width: 295, height: "auto", maxHeight: 172, overflow: "hidden", position: "relative" }}
                                    src={val.OTS.thumbNailURL ? val.OTS.thumbNailURL : ClassDetailImage} alt="" />                    </Col>
                            <Col sm={{ span: 14 }}>
                                <div style={{ marginLeft: 15 }}>
                                    <Row style={{ marginBottom: 0, marginTop: 10 }}>
                                        <h4 style={{ fontWeight: "bold", fontSize: 25 }}>
                                            {val.OTS.name}
                                        </h4>
                                    </Row>
                                    <Row style={{ height: 27 }}>
                                        <p ><span style={{ fontSize: 15, fontWeight: 500 }}>Total Participants: </span><span style={{
                                            fontSize: 15,
                                            color: "#aeb4be",
                                            marginLeft: 5,
                                            fontWeight: 500
                                        }}>{val.OTS.maxParticipants}</span></p>
                                    </Row>
                                    <Row style={{ height: 27 }}>
                                        <p ><span style={{ fontSize: 15, fontWeight: 500 }}>Waitlist:</span><span style={{
                                            fontSize: 15,
                                            color: "#aeb4be",
                                            marginLeft: 5,
                                            fontWeight: 500
                                        }}>{val.Waitlist > 1 ? `${val.Waitlist} Students` : `${val.Waitlist} Student`}</span></p>

                                    </Row>
                                    <Row style={{ height: 27 }}>
                                        <p ><span style={{ fontSize: 15, fontWeight: 500 }}>Time:</span><span style={{
                                            fontSize: 15,
                                            color: "#aeb4be",
                                            marginLeft: 5,
                                            fontWeight: 500
                                        }}>                          {`${moment(val.OTS.startDateTime).tz(tz).format(
                                            "hh:mm a"
                                        )} to ${moment(val.OTS.endDateTime).tz(tz).format("hh:mm a")}`}</span></p>

                                    </Row>
                                    <Row style={{ height: 27 }}>
                                        <p ><span style={{ fontSize: 15, fontWeight: 500 }}>Date:</span><span style={{
                                            fontSize: 15,
                                            color: "#aeb4be",
                                            marginLeft: 5,
                                            fontWeight: 500
                                        }}>{moment(val.OTS.startDateTime).format("DD MMM YYYY")}</span></p>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={{ span: 10 }}>
                        <div>
                            {
                                ((moment(moment().add(1, 'hours')).isSameOrAfter(moment(val.OTS.startDateTime))) &&
                                    (moment(moment()).isSameOrBefore(moment(val.OTS.endDateTime))))
                                    ? (
                                        <Row>
                                            <div className="time-left">
                                                Ready to start
                                                <Countdown
                                                    // precision={3}
                                                    key={val.OTS.id}
                                                    date={moment(val.OTS.startDateTime).valueOf()}
                                                    renderer={renderer}
                                                ></Countdown>
                                            </div>
                                        </Row>
                                    ) : null}
                        </div>
                    </Col>
                    <Row style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div className="ots-message" style={{ display: "flex", alignSelf: "center" }}>
                            <a style={{ color: "#8fb594" }}>
                                <span>
                                    Send message to students before class starts
                                </span>
                            </a>
                        </div>
                        {
                            (moment(moment().add(5, 'minutes')).isSameOrAfter(moment(val.OTS.startDateTime))) &&
                                (moment(moment()).isSameOrBefore(moment(val.OTS.endDateTime)))
                                ? (
                                    <div
                                        className="ots-start-button-box"
                                    >
                                        <Button
                                            style={{
                                                marginLeft: 15,
                                                boxShadow: "none"
                                            }}
                                            type="primary"
                                            htmlType="submit"
                                            className="ots-start-button"
                                            onClick={() => startOtsClass(val.OTS.id)}
                                        >
                                            Start the Class
                                        </Button>
                                        <Link to="/testScreen">
                                            <Button
                                                style={{
                                                    boxShadow: "none"
                                                }}
                                                type="primary"
                                                htmlType="submit"
                                                className="ots-test-button"
                                            >
                                                Test the Class
                                            </Button>
                                        </Link>
                                    </div>
                                ) : (
                                    <div
                                        className="ots-start-button-box"
                                    >
                                        <Link
                                            to={{
                                                pathname: `/otsClass/${val.OTS.id}`,
                                                state: {
                                                    item: val
                                                },
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    marginLeft: 15,
                                                    backgroundColor: "#8fb594",
                                                    boxShadow: "none"
                                                }}
                                                type="primary"
                                                htmlType="submit"
                                                className="ots-manage-button"
                                                disabled={moment(val.OTS.startDateTime).isSameOrAfter(date) ? false : true}
                                            >
                                                {moment(val.OTS.startDateTime).isSameOrAfter(date) ? "Manage the class" : "Class Expired"}
                                            </Button>
                                        </Link>
                                    </div>
                                )
                        }
                    </Row>
                </Row>
            </div>
        </div>)
}

export default OTSClassItem;