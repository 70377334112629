import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from "react-player";
import { usePubNub } from "pubnub-react";
import { connect } from "react-redux";
import { Rnd } from "react-rnd";
import { Slider } from 'antd';

import { playVideo, videoPlayed } from '../../actions/actions';
import { ReactComponent as PauseVideoIcon } from "../../assets/img/icons/pause.svg";
import { ReactComponent as MaximizeIcon } from "../../assets/img/icons/maximise.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/img/icons/minimise.svg";
import { ReactComponent as PlayVideoIcon } from "../../assets/img/icons/play-circle.svg";
import { ReactComponent as ReplayVideoIcon } from "../../assets/img/icons/replay-10.svg";
import { ReactComponent as ForwardVideoIcon } from "../../assets/img/icons/forward-10.svg";


const format = (seconds) => {
    if (isNaN(seconds)) {
        return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
};



const CustomPlayer = (props) => {

    const { otsClassVideoUrl, otsRoomData, isPlayerMuted,
        twoWayCommunication, muteStudents, isSyncVideo,
        newParticipant, speakToAllEnabled } = props;
    const pubnub = usePubNub();
    let speakAll = useRef(twoWayCommunication);
    let mutedStudent = useRef(muteStudents);
    const channelName = `OTS_TWL_${otsRoomData.OTS.roomID}`;
    const [play, setPlay] = useState(false)
    const [played, setPlayed] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");
    const [status, setStatus] = useState(false);
    const [miniViewerWidth, setMiniViewerWidth] = useState('auto');
    const [miniViewerHeight, setMiniViewerHeight] = useState(48);
    const [maxViewerWidth, setMaxViewerWidth] = useState(400);
    const [maxViewerHeight, setMaxViewerHeight] = useState(225);
    const [maxViewerPositionX, setMaxViewerPositionX] = useState(0)
    const [maxViewerPositionY, setMaxViewerPositionY] = useState(-155)
    const [disableDragging, setDisableDragging] = useState(false);
    const [playerStatus, setPlayerStatus] = useState(200);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [showControls, setShowControls] = useState(false);
    const [bufferStatus, setBufferStatus] = useState();
    const [videoEnded, setVideoEnded] = useState(false);

    useEffect(() => {
        pubnub.addListener({ message: handleMessage });
        pubnub.subscribe({ channelName });
    }, [pubnub]);

    let percentage;
    const playerRef = useRef(null);
    const currentTime =
        playerRef && playerRef?.current
            ? playerRef.current.getCurrentTime()
            : "00:00";

    const duration =
        playerRef && playerRef.current ? format(playerRef.current.getDuration()) : "00:00";
    const elapsedTime =
        timeDisplayFormat == "normal"
            ? format(currentTime)
            : `-${format(duration - currentTime)}`;

    const onSeekChange = (newVal) => {
        setSeeking(true)
        setPlayed(parseFloat(newVal))
    }

    const onAfterChange = (newVal) => {
        percentage = parseFloat(playerRef?.current?.getDuration()) * (newVal / 100)
        playerRef.current.seekTo(percentage);
        setSeeking(false);
        let playedSecond = playerRef?.current?.getCurrentTime();
        setPlayedSeconds(playedSecond);
        pubnub.publish({
            channel: channelName,
            message: {
                playerStatus: playerStatus,
                seconds: playedSecond,
                muteStatus: muteStudents ? 501 : 500,
            },
        });
    }

    useEffect(() => {
        if (!seeking) {
            setPlayed((parseFloat(playerRef?.current?.getCurrentTime()) / parseFloat(playerRef?.current?.getDuration()) * 100))
        }
    }, [parseFloat(playerRef?.current?.getCurrentTime()), play])

    const onRewind = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
    };

    const onProgress = (seconds) => {
        setPlayedSeconds(seconds.playedSeconds);
    };

    const onForward = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10)
    };

    useEffect(() => {
        speakAll.current = twoWayCommunication;
    }, [twoWayCommunication]);

    useEffect(() => {
        pubnub.publish({
            channel: channelName,
            message: {
                playerStatus: playerStatus,
                seconds: playedSeconds,
                muteStatus: muteStudents ? 501 : 500,
            },
        });
    }, [isSyncVideo]);

    let participantCount = React.useRef(0);
    useEffect(() => {
        const seconds = playedSeconds + 1;
        if (newParticipant >= participantCount.current) {
            participantCount.current = newParticipant;
            pubnub.publish({
                channel: channelName,
                message: {
                    playerStatus: playerStatus,
                    seconds: seconds,
                    muteStatus: muteStudents ? 501 : 500,
                    statusCode: speakToAllEnabled ? 101 : muteStudents ? 103 : null,
                },
                //send 103 statusCode to enable two way communication
                //send 101 statusCode to enable one way communication
            });
        } else if (newParticipant < participantCount.current) {
            participantCount.current = newParticipant;
        }
    }, [newParticipant]);

    useEffect(() => {
        if (!videoEnded) {
            mutedStudent.current = muteStudents;
            pubnub.publish({
                channel: channelName,
                message: {
                    playerStatus: playerStatus,
                    seconds: playedSeconds,
                },
            });
        }
    }, [playerStatus, muteStudents]);

    const onPause = () => {
        setPlayerStatus(200);
        // props.playVideo(false);
    };

    const onPlay = () => {
        setPlayerStatus(201);
        // props.playVideo(true);
    };

    const onSeek = (seconds) => {
        pubnub.publish({
            channel: channelName,
            message: {
                playerStatus: playerStatus,
                seconds: playedSeconds,
                muteStatus: muteStudents ? 501 : 500,
            },
        });
    };

    const handleMessage = (event) => {
        //receive message with statusCode 402 to handle specific usecase of iOS stuent app
        if (event.message.statusCode === "402") {
            let studentId = event.message.sid;
            setPlayerStatus((playerStatusCurrent) => {
                const seconds = playedSeconds + 1;
                pubnub.publish({
                    channel: channelName,
                    message: {
                        playerStatus: playerStatusCurrent,
                        seconds: seconds,
                        muteStatus: mutedStudent.current ? 501 : 500,
                        statusCode: speakAll.current ? 1012 : 1011,
                        studentId: studentId,
                    },
                });
                return playerStatusCurrent;
            });
        }
    };

    const handleOnEnded = (val) => {
        //Send statuscode 465 when class video is ended
        playerRef.current.seekTo(0)
        setPlay(false)
        setVideoEnded(true)
        // setPlayedSeconds(0)
        pubnub.publish({
            channel: channelName,
            message: {
                statusCode: 465,
            },
        });
    }

    const handleOnBuffer = () => {
        //Send statuscode 466 when video starts to Buffer
        setBufferStatus(true)
        pubnub.publish({
            channel: channelName,
            message: {
                statusCode: 466,
                playerStatus: playerStatus,
                seconds: playedSeconds,
            },
        });

    }

    const handleOnBufferEnd = () => {
        //Send statuscode 467 when video Ends to Buffer
        if (bufferStatus) {
            pubnub.publish({
                channel: channelName,
                message: {
                    statusCode: 467,
                    playerStatus: playerStatus,
                    seconds: playedSeconds,
                },
            });
        }
    }


    return (
        <Rnd
            bounds="window"
            lockAspectRatio={true}
            enableResizing={!!status}
            disableDragging={!status || disableDragging}
            minHeight={225}
            minWidth={400}
            size={{ width: !!status ? maxViewerWidth : miniViewerWidth, height: !!status ? maxViewerHeight : maxViewerHeight }}
            onResizeStop={(e, direction, ref, delta, position) => {
                if (parseInt(ref.style.width) > 400) {
                    setMaxViewerWidth(ref.style.width);
                    setMaxViewerHeight(ref.style.height);
                    setMaxViewerPositionX(position.x);
                    setMaxViewerPositionY(position.y);
                }
            }}
            onResize={(e, direction, ref, delta, position) => {
                if (parseInt(ref.style.width) > 400) {
                    setMaxViewerWidth(ref.style.width);
                    setMaxViewerHeight(ref.style.height);
                    setMaxViewerPositionX(position.x);
                    setMaxViewerPositionY(position.y);
                }
            }}
            onDrag={(e, d) => {
                if (status) {
                    setMaxViewerPositionX(d.x);
                    setMaxViewerPositionY(d.y);
                }
            }}
            position={{ x: !!status ? maxViewerPositionX : 0, y: !!status ? maxViewerPositionY : 0 }}
        >
            <div
                onTouchStart={() => {
                    setShowControls(true);
                }}
                onTouchEnd={() => {
                    setTimeout(() => setShowControls(false), 3000);
                }}
                className={status ? "waiting-screen-video" : "waiting-screen-video-collapsed"}
            >
                < ReactPlayer
                    ref={playerRef}
                    className={status ? "waiting-video-player" : "collapsed-footer-video-player"}
                    controls={false}
                    playing={play}
                    muted={isPlayerMuted}
                    onSeek={onSeek}
                    onPause={onPause}
                    onPlay={onPlay}
                    width={!!status ? maxViewerWidth : '85px'}
                    height={!!status ? maxViewerHeight : '48px'}
                    url={otsClassVideoUrl ? otsClassVideoUrl.streamURL : null}
                    onProgress={onProgress}
                    onEnded={handleOnEnded}
                    onBuffer={handleOnBuffer}
                    onBufferEnd={handleOnBufferEnd}
                />
                {status ?
                    <div className="waiting-screen-video-overlay-bottom waiting-screen-video-duration">
                        <span className="waiting-screen-video-overlay-bottom-span">
                            {format(currentTime)}/{duration}
                        </span>
                    </div>
                    :
                    null
                }
                <div className={showControls && status ? "waiting-screen-video-overlay-ipad" : status ? "waiting-screen-video-overlay" : "collapsed-footer-video"} >
                    {status ?
                        <>
                            <div className="waiting-screen-video-overlay-top" >
                                <div className="waiting-screen-video-overlay-top-text-wrap">
                                    <span className="waiting-screen-video-overlay-top-text-title">{otsRoomData.OTS.name}</span>
                                    <span className="waiting-screen-video-overlay-top-text-description">{otsRoomData.OTS.category}</span>
                                </div>
                                <div className="waiting-screen-video-overlay-top-icon"
                                    onClick={() => {
                                        setStatus(false);
                                    }}
                                    onTouchStart={(e) => {
                                        setStatus(false);
                                        e.stopPropagation();
                                    }}
                                >
                                    <MinimizeIcon className="waiting-screen-minimizie-icon" />
                                </div>
                            </div>
                            <div className="waiting-screen-video-overlay-middle">
                                <div className="waiting-screen-video-overlay-middle-wrap" >
                                    <ReplayVideoIcon className={playerRef?.current?.getCurrentTime() <= 0 ? "collapsed-footer-replay-icon-disabled" : "collapsed-footer-replay-icon"} onClick={() => { onRewind() }} style={{ margin: 'auto auto auto 16px', width: 21, height: 24, cursor: 'pointer' }}
                                        onTouchStart={(e) => {
                                            onRewind();
                                            e.stopPropagation();
                                        }}
                                    />
                                    {!play ?

                                        <PlayVideoIcon className="collapsed-footer-play-icon" onClick={() => { setPlay(true) }} style={{ margin: 'auto', width: 28, height: 28, cursor: 'pointer' }}
                                            onTouchStart={(e) => {
                                                setPlay(true);
                                                e.stopPropagation();
                                            }}
                                        />
                                        :
                                        <PauseVideoIcon className="collapsed-footer-pause-icon" onClick={() => { setPlay(false) }} style={{ margin: 'auto', width: 20, height: 20, cursor: 'pointer' }}
                                            onTouchStart={(e) => {
                                                setPlay(false);
                                                e.stopPropagation();
                                            }}
                                        />
                                    }

                                    <ForwardVideoIcon className="collapsed-footer-forward-icon" onClick={() => { onForward() }} style={{ margin: 'auto 16px auto auto', width: 21, height: 27, cursor: 'pointer' }}
                                        onTouchStart={(e) => {
                                            onForward();
                                            e.stopPropagation();
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="waiting-screen-video-overlay-bottom">
                                <span className="waiting-screen-video-overlay-bottom-span">
                                    {format(currentTime)}/{duration}
                                </span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}
                                onMouseEnter={() => status && setDisableDragging(true)}
                                onMouseLeave={() => status && setDisableDragging(false)}
                                onTouchStart={() => status && setDisableDragging(true)}
                                onTouchEnd={() => status && setDisableDragging(false)}
                            >
                                <Slider
                                    value={played}
                                    onChange={onSeekChange}
                                    onAfterChange={onAfterChange}
                                    tooltipVisible={false}
                                    className="player-slider"
                                />
                            </div>
                        </>
                        :
                        <div className="collapsed-footer-video-wrapper">
                            <div className="collapsed-footer-video-overlays"
                                onClick={() => {
                                    setStatus(true)
                                }}
                            >
                                <MaximizeIcon className="collapsed-footer-video-overlays-icon" />
                            </div>
                            <div className="collapsed-footer-video-wrapper-icons">
                                <span className="collapsed-footer-replay" onClick={() => {
                                    onRewind()
                                }}>
                                    <ReplayVideoIcon className={playerRef?.current?.getCurrentTime() <= 0 ? "collapsed-footer-replay-icon-disabled" : "collapsed-footer-replay-icon"} />
                                </span>
                                {!play ?
                                    <span className="collapsed-footer-play" onClick={() => { setPlay(true) }}>
                                        <PlayVideoIcon className="collapsed-footer-play-icon" />
                                    </span>
                                    :
                                    <span className="collapsed-footer-pause" onClick={() => { setPlay(false) }}>
                                        <PauseVideoIcon className="collapsed-footer-pause-icon" style={{ margin: 'auto' }} />
                                    </span>
                                }
                                <span className="collapsed-footer-forward" onClick={() => {
                                    onForward()
                                }}>
                                    <ForwardVideoIcon className="collapsed-footer-forward-icon" />
                                </span>
                                <span className="collapsed-footer-duration">
                                    {format(currentTime)}/{duration}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Rnd>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        videoPlayed: (data) => dispatch(videoPlayed(data)),
        playVideo: (data) => dispatch(playVideo(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        otsRoomData: state.otsRoomData,
        isSyncVideo: state.isSyncVideo,
        isPlayerMuted: state.isPlayerMuted,
        otsClassVideoUrl: state.otsClassVideoUrl,
        twoWayCommunication: state.twoWayCommunication,
        muteStudents: state.muteStudents,
        newParticipant: state.newParticipant,
        speakToAllEnabled: state.speakToAllEnabled,

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPlayer);