import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Button, Popover } from "antd";

import Circle from '../SideNav/Circle';
import { ReactComponent as Menuicon } from "../../assets/img/icons/menu.svg";
import { ReactComponent as Plus } from "../../assets/img/icons/plus.svg";
import { ReactComponent as Close } from "../../assets/img/icons/close.svg";


const content = (
  <div className="plus-drawer" style={{ display: 'flex', alignItems: 'center' }}>
    <Link to="/createDirectCoach">
      <Button className="add-new-class-button-bottom" disabled="true">Add New class</Button>
    </Link>
    <Button className="add-new-coaching-class-button-bottom" disabled="true" style={{ marginTop: 10 }}>Direct Coach a class</Button>

  </div>
);

const Hamburger_content = (props) => {
  const { getindex, indexCount, hamburger_visible } = props

  const handleMenuClick = (value) => {
    getindex(value)
    hamburger_visible(false)
  }

  return (
    <div style={{ padding: '20px 12px 20px 2px' }}>
      <div className="bottom-navigation-menu-content" onClick={() => { handleMenuClick(2) }}>
        <Circle color={indexCount === 2 ? '#1d615a' : '#FFFFFF'} borderColor={'#1d615a'} />
        <div className="bottom-navigation-menu-content-label">Classes</div>
      </div>
      <div className="bottom-navigation-menu-content" onClick={() => { handleMenuClick(4) }}>
        <Circle color={indexCount === 4 ? '#1d615a' : '#FFFFFF'} borderColor={'#1d615a'} />
        <div className="bottom-navigation-menu-content-label" >Performance</div>
      </div>
      <div className="bottom-navigation-menu-content">
        <Circle borderColor={'#A8A8A8'} />
        <div className="bottom-navigation-menu-content-label" style={{ color: '#A8A8A8' }}>Feedback</div>
      </div>
      <div className="bottom-navigation-menu-content" >
        <Circle borderColor={'#A8A8A8'} />
        <div className="bottom-navigation-menu-content-label" style={{ color: '#A8A8A8' }}>Resource</div>
      </div>
      <div className="bottom-navigation-menu-content" onClick={() => {
        handleMenuClick(7)
      }}
      >
        <Circle color={indexCount === 7 ? '#1d615a' : '#FFFFFF'} borderColor={'#1d615a'} />
        <div className="bottom-navigation-menu-content-label" style={{
          //color:"#A8A8A8"
        }}>Calendar</div>
      </div>
    </div>
  );

}

const BottomNav = (props) => {

  const { indexCount, indexVal } = props;
  const [visible, setVisible] = useState(false)

  const handleVisible = (visibleFlag) => {
    document.body.classList.toggle('no-scroll');
    setVisible(visibleFlag)
  }
  const handleGetIndex = (getindex) => {
    indexVal(getindex)
  }


  const onVisibleChange = (visible) => {
    setVisible(visible)
    document.body.classList.toggle('no-scroll');
  }

  const handleMenuClick = (value) => {
    indexVal(value);
    setVisible(false);
  }

  return (
    <>
      <div className="bottom-navigation" >
        <div className="bottom-navigation-content"
        >
          <Circle color={indexCount === "" ? '#1d615a' : '#FFFFFF'} borderColor={indexCount === "" ? '#1d615a' : '#A8A8A8'} />
          <div className="bottom-navigation-content-label" style={{ color: '#A8A8A8' }}>Home</div>
        </div>
        <div className="bottom-navigation-content" onClick={() => {
          handleMenuClick(1)
        }}
        >
          <Circle color={indexCount === 1 ? '#1d615a' : '#FFFFFF'} borderColor={'#1d615a'} style={{ marginTop: 9 }} />
          <div className="bottom-navigation-content-label">Coaching</div>
        </div>
        {window.innerWidth > 767 ?
          //mobile
          <div className="bottom-navigation-content"
            onClick={() => { handleMenuClick(2) }}
          >
            <Circle color={indexCount === 2 ? '#1d615a' : '#FFFFFF'} borderColor={'#1d615a'} style={{ marginTop: 9 }} />
            <div className="bottom-navigation-content-label" >Classes</div>
          </div>
          :

          <Popover overlayClassName={'transparent-popup'} placement="top" color={'transparent'} content={content} trigger="click">
            <div className="plus-div-bottom" >
              <Plus className={"plus-div-bottom-plus"} />
            </div>
          </Popover>
        }
        <div className="bottom-navigation-content"
          onClick={() => { handleMenuClick(3) }}
        >
          <Circle color={indexCount === 3 ? '#1d615a' : '#FFFFFF'} borderColor={'#1d615a'} style={{ marginTop: 9 }} />
          <div className="bottom-navigation-content-label" >Earning</div>
        </div>
        {window.innerWidth > 767 ?
          //ipad
          <>
            <div className="bottom-navigation-content" onClick={() => { handleMenuClick(4) }}>
              <Circle color={indexCount === 4 ? '#1d615a' : '#FFFFFF'} borderColor={'#1d615a'} />
              <div className="bottom-navigation-content-label" >Performance</div>
            </div>
            <div className="bottom-navigation-content">
              <Circle borderColor={indexCount === "" ? '#1d615a' : '#A8A8A8'} />
              <div className="bottom-navigation-content-label" style={{ color: '#A8A8A8' }}>Feedback</div>
            </div>
            <div className="bottom-navigation-content">
              <Circle borderColor={indexCount === "" ? '#1d615a' : '#A8A8A8'} />
              <div className="bottom-navigation-content-label" style={{ color: '#A8A8A8' }}>Resource</div>
            </div>
            <div className="bottom-navigation-content" onClick={() => {
              handleMenuClick(7)
            }}
            >
              <Circle color={indexCount === 7 ? '#1d615a' : '#FFFFFF'} borderColor={'#1d615a'} />
              <div className="bottom-navigation-content-label" style={{
                //color:"#A8A8A8"
              }}>Calendar</div>
            </div>
          </>
          :
          <div className="bottom-navigation-content" >
            <Popover getPopupContainer={triggerNode => triggerNode.parentNode}
              overlayClassName="bottom-navigation-menu-popover" onVisibleChange={onVisibleChange} visible={visible} placement="top" onClick={() => { setVisible(!visible) }} content={<Hamburger_content getindex={handleGetIndex} indexCount={indexCount} hamburger_visible={handleVisible} />} trigger="click">

              <div style={{ display: 'flex', alignItems: 'center' }}>{visible ?
                <> <Close className="close-icon" /> <div className={''}></div> </> : <Menuicon className="menuIcon" />}
              </div>
              <span className="menu-span">Menu</span>
            </Popover>
          </div>
        }
      </div>
    </>
  );
}

export default BottomNav;