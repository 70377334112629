import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, Input } from "antd";

const DemoOTSClass = (props) => {
    const { handleChangeModal, pending, handleChangeEmail } = props;
    const [firstEmail, setFirstEmail] = useState(null);
    const [secondEmail, setSecondEmail] = useState(null);
    const [thirdEmail, setThirdEmail] = useState(null);
    const [fourthEmail, setFourthEmail] = useState(null);

    const wrapperStyles = {
        display: 'flex',
        position: 'absolute', /* Stay in place */
        zIndex: 1, /* Sit on top */
        left: 0,
        top: 0,
        width: '100vw', /* Full width */
        height: '100vh', /* Full height */
        overflow: 'auto', /* Enable scroll if needed */
        backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
    }
    const participantStyle = {
        position: "relative",
        width: window.innerWidth > 766 ? 608 : 344,
        height: window.innerWidth > 766 ? 264 : 290,
        margin: 'auto',
        borderRadius: 5,
        backgroundColor: "white"
    };

    const onSubmit = () => {
        const collectData = [firstEmail, secondEmail, thirdEmail, fourthEmail];
        let data = [];
        collectData.map((value) => {
            if (value) {
                data.push(value);
            }
        });
        handleChangeEmail(data);
    };

    const onChangeFirstEmail = (e) => {
        setFirstEmail(e.target.value);
    };

    const onChangeSecondEmail = (e) => {
        setThirdEmail(e.target.value);
    };

    const onChangeThirdEmail = (e) => {
        setSecondEmail(e.target.value);
    };

    const onChangeFourthEmail = (e) => {
        setFourthEmail(e.target.value);
    };


    return (
        <div onClick={() => { handleChangeModal() }} style={wrapperStyles} >
            <div className='modal' style={participantStyle} onClick={(e) => { e.stopPropagation(); }}>
                <span className='modal-heading' >
                    Add Students
                </span>
                <div className='demo-email-section'>
                    <div className="demo-input">
                        <div className='demo-input-wrapper'>
                            <Input style={{ height: 40 }} onChange={onChangeFirstEmail} placeholder="Enter Email Address" />

                        </div>
                        <div className='email-input'>
                            <Input style={{ height: 40 }} onChange={onChangeSecondEmail} placeholder="Enter Email Address" />
                        </div>
                    </div>
                    <div className="demo-input">
                        <div className='demo-input-wrapper'>
                            <Input style={{ height: 40 }} onChange={onChangeThirdEmail} placeholder="Enter Email Address" />

                        </div>
                        <div className='email-input'>
                            <Input style={{ height: 40 }} onChange={onChangeFourthEmail} placeholder="Enter Email Address" />
                        </div>
                    </div>
                </div>
                <div className='modal-select'>
                    <Button className="modal-button" onClick={() => { handleChangeModal() }} >
                        Close
                    </Button>
                    <Button loading={pending} className="modal-button-cancel" onClick={() => { onSubmit() }}>Submit</Button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        pending: state.pending,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null)
)(DemoOTSClass);