import React, { useState, useEffect, useLayoutEffect } from "react";
import { ReactComponent as MicOnIcon } from '../../assets/img/icons/mic_on.svg';
import { ReactComponent as VolumeIcon } from '../../assets/img/icons/volume.svg';
import { ReactComponent as BackArrow } from '../../assets/img/icons/arrow_back.svg';
import { ReactComponent as DoneIcon } from '../../assets/img/icons/icon-tick.svg';
import { ReactComponent as FailedIcon } from '../../assets/img/icons/icon-close.svg';
import { ReactComponent as ReloadIcon } from '../../assets/img/icons/icon-reload.svg';
import MusicCanvas from "../musicCanvas/musicCanvas";
import { ReactMic } from 'react-mic';

const TestAudioSpeaker = (props) => {
    const { handleStepNumber } = props;
    const [audioStatus, setAudioStatus] = useState(false);
    const [micStatus, setMicStatus] = useState(false);
    const [speakerConfirm, setSpeakerConfirm] = useState();
    const [audioConfirm, setAudioConfirm] = useState();

    const handleBackArrow = () => {
        handleStepNumber(0)
    }

    const settingState = () => {
        return new Promise((resolve, reject) => {
            setMicStatus(false)
            resolve(true)
        })
    }

    const handleAudioStatus = (val) => {
        setAudioStatus(val)
    }

    const handleSpeakerConfirm = () => {
        setSpeakerConfirm(true)
        setAudioStatus(!audioStatus)
    }

    const handleSpeakerDenied = () => {
        setSpeakerConfirm(false)
        setAudioStatus(!audioStatus)
    }

    const handleAudioConfirm = () => {
        setAudioConfirm(true)
        setMicStatus(!micStatus)
    }

    const handleAudioDenied = () => {
        setAudioConfirm(false)
        setMicStatus(!micStatus)
    }

    const handleSpeakerReload = () => {
        setSpeakerConfirm(null)
        setAudioStatus(!audioStatus)
    }

    const handleAudioReload = () => {
        setAudioConfirm(null)
        setMicStatus(!micStatus)
    }
    return (
        <>
            <span className="audio-screen-back-arrow-span" onClick={() => { settingState().then(() => { handleBackArrow() }) }}>
                <BackArrow className="audio-screen-back-arrow" />
            </span>
            <div className="test-audio-screen-container">
                <div className="test-audio-screen-title">
                    Test your audio
                </div>
                <div className="test-audio-screen-main">
                    <div className="test-audio-screen-box">
                        <div className="test-audio-screen-box-talk">
                            {!audioStatus ?
                                <span >
                                    <VolumeIcon className="talk-icon" />
                                </span>
                                :
                                <MusicCanvas handleAudioStatus={handleAudioStatus} />
                            }
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className="test-audio-screen-box-talk-heading">Speaker</span>
                            {speakerConfirm ?
                                <div className="test-audio-screen-box-success">
                                    <span className="test-audio-screen-box-success-title" >Test Successful</span>
                                    <DoneIcon style={{ margin: '24px auto' }} />
                                </div>
                                : speakerConfirm === false ?
                                    <div className="test-audio-screen-box-success">
                                        <span className="test-audio-screen-box-success-title" >Test Failed</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', margin: '24px auto' }}>
                                            <FailedIcon style={{ cursor: 'pointer' }} />
                                            <ReloadIcon style={{ marginLeft: 20, cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleSpeakerReload()
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    !audioStatus ?
                                        <>
                                            <span className="test-audio-screen-box-talk-description" style={{ marginTop: 20 }}>Test your speaker is working.</span>
                                            <span className="test-audio-screen-box-talk-test" onClick={() => {
                                                setAudioStatus(!audioStatus)
                                            }}>Test
                                            </span>
                                        </>
                                        :
                                        <div className="test-audio-screen-box-talk-confirm">
                                            <span className="test-audio-screen-box-talk-confirm-title">Do you hear a ring tone?</span>
                                            <div className="test-audio-screen-box-talk-confirm-button">
                                                <span className="test-audio-screen-box-talk-confirm-button-yes" onClick={() => {
                                                    handleSpeakerConfirm()
                                                }} >Yes</span>
                                                <span className="test-audio-screen-box-talk-confirm-button-no" onClick={() => {
                                                    handleSpeakerDenied()
                                                }}>No</span>
                                            </div>
                                        </div>
                            }
                        </div>
                    </div>
                    <div className="test-audio-screen-box" style={{ marginLeft: 20 }}>
                        <div className="test-audio-screen-box-converse">
                            <>
                                {!micStatus &&
                                    <span style={{
                                        position: 'absolute'
                                    }}>
                                        <MicOnIcon className="converse-icon" />
                                    </span>

                                }
                                <ReactMic
                                    record={micStatus}
                                    className="sound-wave"
                                    visualSetting="frequencyBars"
                                    strokeColor="#1d615a"
                                    backgroundColor={'#262626'}
                                    width="80"
                                    style={{ margin: "auto", display: "flex" }}
                                />
                            </>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className="test-audio-screen-box-talk-heading">Microphone</span>
                            {audioConfirm ?
                                <div className="test-audio-screen-box-success">
                                    <span className="test-audio-screen-box-success-title" >Test Successful</span>
                                    <DoneIcon style={{ margin: '24px auto' }} />
                                </div>
                                : audioConfirm === false ?
                                    <div className="test-audio-screen-box-success">
                                        <span className="test-audio-screen-box-success-title" >Test Failed</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', margin: '24px auto' }}>
                                            <FailedIcon style={{ cursor: 'pointer' }} />
                                            <ReloadIcon style={{ marginLeft: 20, cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleAudioReload()
                                                }}
                                            />
                                        </div>
                                    </div>

                                    : !micStatus ?
                                        <>
                                            <span className="test-audio-screen-box-talk-description" style={{ marginTop: 20 }}>Test your microphone is working.</span>
                                            <span className="test-audio-screen-box-talk-test" onClick={() => {
                                                setMicStatus(!micStatus)
                                            }}>Test
                                            </span>
                                        </>
                                        :
                                        <div className="test-audio-screen-box-talk-confirm">
                                            <span className="test-audio-screen-box-talk-confirm-title">Speak, do you see the bars moving?</span>
                                            <div className="test-audio-screen-box-talk-confirm-button">
                                                <span className="test-audio-screen-box-talk-confirm-button-yes" onClick={() => {
                                                    handleAudioConfirm()
                                                }} >Yes</span>
                                                <span className="test-audio-screen-box-talk-confirm-button-no" onClick={() => { handleAudioDenied() }}>No</span>
                                            </div>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="test-audio-screen-button">
                    <span className="test-audio-screen-button-span" onClick={() => { settingState().then(() => handleBackArrow()) }}>Done</span>
                </div>
            </div>
        </>
    );
}

export default TestAudioSpeaker;