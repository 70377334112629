import React, { useEffect, useState } from "react";
import VerticalProgress from "./verticalProgressbar";

const PopularClassLength = (props) => {

  const { data } = props;

  const barsData = [15, 20, 30, 45, 60, 90];
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let totalData = 0;
    Object.keys(data.all).map(function (key, index) {
      totalData += data.all[key];
    });
    setTotal(totalData);
  }, []);

  return (
    <div style={{ display: "flex" }}>
      {Object.keys(data.all).map((key, index) => (
        <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
          <div style={{display:'flex', justifyContent:"center"}}>
            <VerticalProgress
              progress={parseInt((data.all[key]) / total * 100)}
              isLastBar={index === barsData.length - 1 ? "#d2dfde" : "#1d615a"}
              verticalBar={true}
              horizontalBar={false}
            />
          </div>
          <span className="vertical-graph-label"
            style={{
              marginTop: 18, color: index === barsData.length - 1 ?
                "rgba(38, 38, 38, 0.2)" : "#262626"
            }} >
            {key}
          </span>
          <span className="vertical-graph-label" style={{
            color: index === barsData.length - 1 ?
              "rgba(38, 38, 38, 0.2)" : "#262626"
          }} >
            min
          </span>
        </div>
      ))}
    </div>
  );
};

export default PopularClassLength;
