import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from "./reducers/reducers";
import createSagaMiddleware from "redux-saga";
import storage from 'redux-persist/lib/storage';
import { rootSaga } from "./sagas/sagas";
import logger from "redux-logger";

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ["loginData", "otsRoomData", "rememberMe", "otsClassVideoUrl"] // which reducer want to store
  };
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

    const enhancer = composeEnhancers(
      applyMiddleware(logger, sagaMiddleware)
      // other store enhancers if any
    );
const store = createStore(persistedReducer, enhancer);
sagaMiddleware.run(rootSaga);
const persistor = persistStore(store)

export {persistor, store};
