import React, { useState, useLayoutEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button } from 'antd';
import useWindowSize from "../../Hooks/windowSize";


const AddSpotModal = (props) => {

    const { handleChangeParticipantsModal, totalParticipants, updateOtsParticipants, pending } = props;
    const [count, setCount] = useState(totalParticipants);
    const [width, height] = useWindowSize();

    const addParticipant = () => {
        if (count !== 16) {
            setCount(count + 1);
        }
    };
    const subParticipant = () => {
        if (count !== 0) {
            setCount(count - 1);
        }
    };

    const wrapperStyles = {
        display: 'flex',
        position: 'absolute', /* Stay in place */
        zIndex: 1, /* Sit on top */
        left: 0,
        top: 0,
        width: '100vw', /* Full width */
        height: '100vh', /* Full height */
        overflow: 'auto', /* Enable scroll if needed */
        backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
    };
    const participantStyle = {
        position: "relative",
        width: width > 766 ? 608 : 344,
        height: width > 766 && width < 813 ? 386 : width > 814 ? 319 : 418,
        margin: 'auto',
        borderRadius: 5,
        backgroundColor: "white"
    };

    return (
        <div onClick={() => { handleChangeParticipantsModal() }} style={wrapperStyles} >
            <div className='modal' style={participantStyle} onClick={(e) => { e.stopPropagation(); }}>
                <span className='modal-heading' >
                    Add more spots
                </span>
                <span className='modal-description'>
                    You may increase the number of spots to accomodate also those on the waitlist. maximum number of students per class is 16.
                </span>
                <div className="modal-spots">
                    <span
                        style={{ color: count === 0 ? '#929292' : '#262626' }}
                        className={totalParticipants === 0 ? "modal-signs modal-signs-disable" : "modal-signs"}
                        onClick={() => subParticipant()}
                    >-</span>
                    <span className="modal-signs-amount">{count}</span>
                    <span
                        style={{ color: count === 16 ? '#929292' : '#262626' }}
                        className={totalParticipants === 16 ? "modal-signs modal-signs-disable" : "modal-signs"}
                        onClick={() => addParticipant()}
                    >+</span>
                </div>
                <div className='modal-select'>
                    <Button className="modal-button" onClick={() => { handleChangeParticipantsModal() }} >
                        Close
                    </Button>
                    <Button className="modal-button-cancel" loading={pending} onClick={() => updateOtsParticipants(count)}>Submit</Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        pending: state.pending,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null)
)(AddSpotModal);