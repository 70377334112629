import React, { useEffect, useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import moment from 'moment';
import { compose } from "redux";
import { notification } from 'antd';
import { connect } from "react-redux";
import useWindowSize from '../../components/Hooks/windowSize';

import { createOTSCLass } from "../../actions/actions";
import Logo from '../../assets/img/bml-logomark-white.svg';
import DirectCoachHeading from '../../components/Coaching/CreateDirectCoaching/directCoachHeading';
import DirectClassList from '../../components/Coaching/CreateDirectCoaching/directClassList';
import DirectCoachProgressBar from '../../components/Coaching/CreateDirectCoaching/directCoachProgressBar';
import DirectCoachBottomBar from '../../components/Coaching/CreateDirectCoaching/directCoachBottomBar';
import DirectCoachDescription from '../../components/Coaching/CreateDirectCoaching/directCoachDescription';
import DirectCoachStudent from '../../components/Coaching/CreateDirectCoaching/directCoachStudent';
import DirectCoachCost from '../../components/Coaching/CreateDirectCoaching/directCoachCost';
import DirectCoachConfrim from '../../components/Coaching/CreateDirectCoaching/directCoachConfirm';
import DirectCoachDateTime from '../../components/Coaching/CreateDirectCoaching/directCoachDateTime';

let concatenate;

const CreateDirectCoachClass = (props) => {
    const { loginData, otsClassStatus } = props;
    const [stepNumber, setStepNumber] = useState(0);
    const [progressVal, setProgressVal] = useState(20);
    const [studentCount, setStudentCount] = useState(0);
    const [costCount, setCostCount] = useState(0);
    const [classData, setClassData] = useState(undefined);
    const [buttonEnable, setButtonEnable] = useState(true);
    const [startTimeValue, setStartTimeValue] = useState(moment().add(10, 'm'));
    const [endTimeValue, setEndTimeValue] = useState(moment().add(40, 'm'));
    const [selectedDate, setSelectedDate] = useState(moment());
    // const [startTimeMeridiem, setStartTimeMeridiem] = useState(moment(moment()).isSameOrAfter(moment('11:50', 'hh:mm a'), 'minutes'));
    // const [endTimeMeridiem, setEndTimeMeridiem] = useState(moment(moment()).isSameOrAfter(moment('11:20', 'hh:mm a'), 'minutes'));
    const [screenWidth] = useWindowSize();

    const handleProgressNextVal = () => {
        if (stepNumber === 1) {
            if (startTimeValue.isBefore(moment()) || startTimeValue.isSameOrAfter(endTimeValue)) {
                notification['error']({
                    message: 'Error',
                    description:
                        'Please select correct Start and End time. Make sure the start time is after the current time and before the class end time.',
                });
                return;
            }

            setProgressVal(progressVal + 20);
            setStepNumber(stepNumber + 1);
        }
        else if (progressVal !== 100 && stepNumber < 4) {
            setProgressVal(progressVal + 20);
            setStepNumber(stepNumber + 1);
        }
    }

    const handleProgressPrevVal = () => {
        if (progressVal !== 20 && stepNumber !== 0) {
            setProgressVal(progressVal - 20)
            setStepNumber(stepNumber - 1)
        }
    }

    const handleStudentCount = (val, condition) => {
        if (condition === 'plus') {
            if (val) {
                setStudentCount(val)
            }
            else {
                setStudentCount(studentCount + 1)
            }
        }
        else {
            if (val) {
                setStudentCount(val)
            }
            else {
                setStudentCount(studentCount - 1)
            }
        }
    }

    const handleStudentCost = (val, condition) => {
        if (condition === 'plus') {
            if (val) {
                setCostCount(val)
            }
            else {
                setCostCount(costCount + 1)
            }
        }
        else {
            if (val) {
                setCostCount(val)
            }
            else {
                setCostCount(costCount - 1)
            }
        }
    }

    const handleClassData = (val) => {
        setClassData(val)
    }

    const handleSubmitClick = () => {

        var moment = require('moment-timezone');
        const startTimeMoment = moment(startTimeValue, 'hh:mm a');
        const endTimeMoment = moment(endTimeValue, 'hh:mm a');
        const time = moment(startTimeMoment).format("HH:mm");
        const eTime = moment(endTimeMoment).format("HH:mm");
        const date = moment(selectedDate).format("YYYY-MM-DD");
        const newOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZoneFormatted = newOffset && moment().tz(newOffset) ?
            moment().tz(newOffset).format('Z') : moment().format('Z');

        const addTime = startTimeValue.format('YYYY-MM-DD[T]HH:mm:00Z');// `${date}T${time}:00${timeZoneFormatted}`;
        const endTime = endTimeValue.format('YYYY-MM-DD[T]HH:mm:00Z');//`${date}T${eTime}:00${timeZoneFormatted}`;

        const formData = {
            name: classData.class.name,
            classID: classData.class.id,
            startDateTime: addTime,
            endDateTime: endTime,
            duration: endTimeMoment.diff(startTimeMoment, 'seconds') + 1,
            timeZone: timeZoneFormatted,
            maxParticipants: studentCount,
            IsWaitlistAvailable: true,
            currency: "aud",
            price: costCount,
            token: loginData.access,
            otsId: null,
            email: [],
            endpoint: 'new',
        };
        props.createOTSCLass(formData);
    }
    useEffect(() => {
        if (classData) {
            setButtonEnable(false)
        }
        else {
            setButtonEnable(true)
        }

    }, [classData]);

    useEffect(() => {
        if (studentCount > 0) {
            setButtonEnable(false)
        }
        else {
            setButtonEnable(true)
        }
    }, [studentCount]);

    useEffect(() => {
        if (otsClassStatus === true) {
            props.history.push("/main");
        }
    }, [otsClassStatus]);

    useEffect(() => {
        if (stepNumber === 1 || stepNumber === 3 || stepNumber === 4) {
            setButtonEnable(false)
        }
        else if (stepNumber === 0 && classData) {
            setButtonEnable(false)
        }
        else if (stepNumber === 2 && studentCount > 0) {
            setButtonEnable(false)
        }
        else {
            setButtonEnable(true)
        }
    }, [stepNumber]);

    const handleStartTimeValue = (value) => {
        setStartTimeValue(moment(selectedDate.format('YYYY-MM-DD') + value.format('hh:mm a'), 'YYYY-MM-DDhh:mm a'));
    };

    const handleEndTimeValue = (value) => {
        setEndTimeValue(moment(selectedDate.format('YYYY-MM-DD') + value.format('hh:mm a'), 'YYYY-MM-DDhh:mm a'))
    };

    const handleSelectedDate = (value) => {
        setSelectedDate(value);
        setEndTimeValue(moment(value.format('YYYY-MM-DD') + endTimeValue.format('hh:mm a'), 'YYYY-MM-DDhh:mm a'));
        screenWidth >= 767 && setStartTimeValue(moment(value.format('YYYY-MM-DD') + startTimeValue.format('hh:mm a'), 'YYYY-MM-DDhh:mm a'));
    };

    // const handleStartTimeMeridiem = (value) => {
    //     setStartTimeMeridiem(value);
    // };

    // const handleEndTimeMeridiem = (value) => {
    //     setEndTimeMeridiem(value);
    // };

    return (
        <div className="create-direct-coach">
            <div className="create-direct-coach-left">
                <div className="create-direct-coach-left-logo">
                    <Link to="/"
                    //className="logo-link"
                    >
                        <div
                            className="create-direct-coach-logo"
                        >
                            <img
                                className=""
                                width="38px"
                                height="38px"
                                src={Logo}
                                alt="direct-coach-logo"
                            />
                        </div>
                    </Link>
                </div>
                {
                    stepNumber === 0 ?
                        <div
                            className="create-direct-coach-left-heading"
                        >
                            <DirectCoachHeading
                                //className="create-direct-coach-left-heading-component"
                                text={"Which class would you like to Direct Coach?"} />

                        </div>
                        : stepNumber === 1 ?
                            <div
                                className="create-direct-coach-left-heading"
                            >
                                <DirectCoachHeading
                                    text={"Set a date and time for your class"} />

                            </div>

                            : stepNumber === 2 ?
                                <div
                                    className="create-direct-coach-left-heading"
                                >
                                    <DirectCoachHeading
                                        text={"How many students can participate?"} />
                                    <DirectCoachDescription
                                        text={"Make sure you are able to provide individual feedback to every student that attends."}
                                    />

                                </div>

                                : stepNumber === 3 ?
                                    <div
                                        className="create-direct-coach-left-heading"
                                    >
                                        <DirectCoachHeading
                                            text={"Set a cost for your class."} />

                                    </div>

                                    : stepNumber === 4 ?
                                        <div
                                            className="create-direct-coach-left-heading"
                                        >
                                            <DirectCoachHeading
                                                text={"Confirm your class"} />
                                            <DirectCoachDescription
                                                text={"After publishing your class will be visible to students to book. You can still make some changes to the class afterwards."}
                                            />
                                        </div>
                                        :
                                        null
                }
            </div>
            <div className="create-direct-coach-right"
            //style={window.innerWidth < 767 ? {overflowY : 'auto',height :  window.innerHeight - 163 } : {}}
            >
                {stepNumber === 0 ?
                    <DirectClassList handleClassData={(value) => { handleClassData(value) }}
                        classData={classData}
                    />
                    :
                    stepNumber === 1 ?
                        <DirectCoachDateTime
                            selectedDate={selectedDate}
                            endTimeValue={endTimeValue}
                            startTimeValue={startTimeValue}
                            handleSelectedDate={handleSelectedDate}
                            handleEndTimeValue={handleEndTimeValue}
                            handleStartTimeValue={handleStartTimeValue}
                        />
                        :
                        stepNumber === 2 ?
                            <DirectCoachStudent handleStudentCount={handleStudentCount} studentCount={studentCount} />
                            :
                            stepNumber === 3 ?
                                <DirectCoachCost studentCount={studentCount} handleStudentCost={handleStudentCost} costCount={costCount} />
                                :
                                stepNumber === 4 ?
                                    <DirectCoachConfrim studentCount={studentCount} costCount={costCount} classData={classData} startTimeValue={startTimeValue} concatenate={concatenate} />
                                    :
                                    null
                }
                <div className="create-direct-coach-right-bottom" style={{}}>
                    <DirectCoachProgressBar progressVal={progressVal} />
                    <DirectCoachBottomBar handleProgressNextVal={handleProgressNextVal}
                        handleProgressPrevVal={handleProgressPrevVal}
                        stepNumber={stepNumber}
                        handleSubmitClick={handleSubmitClick}
                        buttonEnable={buttonEnable}
                    />
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        createOTSCLass: (data) => dispatch(createOTSCLass(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        loginData: state.loginData,
        otsClassStatus: state.otsClassStatus,
    };
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CreateDirectCoachClass);