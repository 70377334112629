import React from 'react';
import { Progress } from 'antd';
import { isIPhone13,isAndroid, isIPad13 } from 'react-device-detect';

const DirectCoachProgressBar = (props) => {
    const { progressVal } = props;
    return ( 
        <div className='dc-progress-bar'>
            <Progress percent={progressVal}
            style={{['--demo_margin'] : isIPhone13 ? '20px' : isIPad13 ? '20px' : '18px'}}
            />
        </div>
     );
}
 
export default DirectCoachProgressBar;