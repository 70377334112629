import React from 'react';

const DirectCoachDescription = (props) => {
     const { text } = props;
     return (
          <div className='direct-coach-description-component'>
               {text}
          </div>
     );
}

export default DirectCoachDescription;