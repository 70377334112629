import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Login from "../pages/Login";
import Main from "../components/Main/Main";
import Home from "../pages/Home";
import Header from '../components/Header/header';
import ProfileAndAccount from '../pages/ProfileAndAccount/ProfileAndAccount';
import CreateOTSClass from '../pages/CreateOTSClass/index';
import TestHomeScreen from '../pages/TestHomeScreen';
import CreateDirectCoachClass from '../pages/CreateDirectCoachClass';
import CreateDemoDirectCoachClass from '../pages/CreateDemoDirectCoachClass';
import OTSClasses from '../pages/OTSClasses';


const scrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
};


const routes = (props) => {
    const { tokenExpire } = props;

    return (
        <BrowserRouter>
            <Route component={scrollToTop} >
                <Switch>
                    <React.Fragment>

                        {tokenExpire ? (
                            <Route path="*" component={Login} />
                        ) : (
                            <Route path="/" exact component={Login} />
                        )}
                        {!tokenExpire &&
                            <>
                                <Route path="/home" exact component={Home} />
                                {/* <Route path="/header" component={Header} /> */}
                                <Route path="/otsHome" component={OTSClasses} />
                                <Route path="/profileandaccount" component={ProfileAndAccount} />
                                <Route path="/otsClass/:classId" component={CreateOTSClass} />
                                <Route path="/testHomeScreen" component={TestHomeScreen} />
                                <Route path="/main" component={Main} />
                                <Route path="/createDirectCoach" component={CreateDirectCoachClass} />
                                <Route path="/createDemoDirectCoach" component={CreateDemoDirectCoachClass} />
                            </>
                        }
                    </React.Fragment>
                </Switch>
            </Route>
        </BrowserRouter>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        //changeLoginState: (data) => dispatch(changeLoginState(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        tokenExpire: state.tokenExpire,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(routes);