import * as Actions from "../actions/actionTypes";

const initialState = {
  loginData: "",
  isLogin: false,
  token: "",
  isRoomCreated: false,
  isPlayVideo: false,
  createdClass: null,
  isRoomEnded: false,
  loginSuccessful: false,
  roomFailure: false,
  pending: false,
  studentIds: [],
  roomToken: "",
  isPlayerMuted: false,
  isTeacherMuted: false,
  isVideoPLayed: false,
  totalParticipant: 0,
  isStartClass: false,
  exitRoom: false,
  isSyncVideo: false,
  studentRules: "",
  muteStudents: false,
  roomJoined: false,
  localParticipantSid: null,
  roomData: null,
  twoWayCommunication: false,
  otsClasses: [],
  teacherProfileDetails: [],
  classes: [],
  otsClass: null,
  loginStatusCode: false,
  otsClassStatus: false,
  otsRoomData: [],
  participantIds: [],
  rememberMe: false,
  tokenExpire: false,
  isLogout: false,
  teacherProfilePasswordUpdate: [],
  teacherBioUpdate: [],
  teacherImageUpdate: [],
  teacherImageURLUpdate: [],
  teacherPerformanceData: [],
  audioTestCompleted: false,
  networkTestCompleted: false,
  isZoomModal: false,
  showLoader: false,
  teacherEarningsData: [],
  simpleClasses: [],
  showHeader: false,
  otsClassCanceled: false,
  selectedClass: null,
  navIndex: 4,
  otsClassVideoUrl: {},
  otsRoomStatus: false,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.POST_LOGINDATA_REQUEST:
      return { ...state, pending: true };
    case Actions.POST_LOGINDATA_SUCCESS:
      return {
        ...state,
        loginData: action.payload,
        isLogin: true,
        token: action.payload.access,
        loginSuccessful: true,
        tokenExpire: false,
        pending: false,
      };
    case Actions.POST_LOGINDATA_FAILURE:
      let statusCode = state.loginStatusCode;
      statusCode = !statusCode;
      return { ...state, loginStatusCode: statusCode, pending: false };

    case Actions.POST_CREATECLASS_REQUEST:
      return { ...state, pending: true };
    case Actions.POST_CREATECLASS_SUCCESS:
      return {
        ...state,
        createdClass: action.payload,
        isRoomCreated: true,
        isRoomEnded: false,
        exitRoom: false,
        isStartClass: false,
        roomFailure: false,
        pending: false,
      };
    case Actions.POST_CREATECLASS_FAILURE:
      return {
        ...state,
        error: action.payload,
        roomFailure: true,
        pending: false,
      };

    case Actions.POST_ENDCLASS_REQUEST:
      return state;
    case Actions.POST_ENDCLASS_SUCCESS:
      return {
        ...state,
        endClass: action.payload,
        isRoomEnded: true,
        isRoomCreated: false,
        roomJoined: false,
        createdClass: "",
        speakToAllEnabled: false,
        muteStudents: false,
      };
    case Actions.POST_ENDCLASS_FAILURE:
      return { ...state, error: action.payload };

    case Actions.CHANGE_STUDENT_RULE_REQUEST:
      return state;
    case Actions.CHANGE_STUDENT_RULE_SUCCESS:
      return {
        ...state,
        studentRules: action.payload,
      };
    case Actions.CHANGE_STUDENT_RULE_FAILURE:
      return { ...state, error: action.payload };

    case Actions.ENABLE_SPEAKALL_REQUEST:
      return state;
    case Actions.ENABLE_SPEAKALL_SUCCESS:
      return {
        ...state,
        totalStudents: action.payload,
      };
    case Actions.ENABLE_SPEAKALL_FAILURE:
      return { ...state, error: action.payload };

    case Actions.GET_ALL_OTS_CLASSES_REQUEST:
      return { ...state, showLoader: true };
    case Actions.GET_ALL_OTS_CLASSES_SUCCESS:
      return {
        ...state,
        otsClasses: action.payload,
        audioTestCompleted: false,
        networkTestCompleted: false,
        showLoader: false
      };
    case Actions.GET_ALL_OTS_CLASSES_FAILURE:
      return { ...state, error: action.payload, showLoader: false };

    case Actions.GET_ALL_CLASSES_REQUEST:
      return state;
    case Actions.GET_ALL_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
      };
    case Actions.GET_ALL_CLASSES_FAILURE:
      return { ...state, error: action.payload };

    case Actions.CREATE_OTS_REQUEST:
      return { ...state, pending: true };
    case Actions.CREATE_OTS_SUCCESS:
      return {
        ...state,
        // otsClass: action.payload,
        pending: false,
        otsClassStatus: true,
        isRoomEnded: false,
        otsClasses: [],
      };
    case Actions.CREATE_OTS_FAILURE:
      return { ...state, error: action.payload };

    case Actions.UPDATE_OTS_REQUEST:
      return { ...state, pending: true, otsClass: null };
    case Actions.UPDATE_OTS_SUCCESS:
      return {
        ...state,
        otsClass: action.payload,
        pending: false,
        otsClassStatus: false,
        isRoomEnded: false,
        otsClasses: [],
      };
    case Actions.UPDATE_OTS_FAILURE:
      return { ...state, error: action.payload, pending: false, };

    case Actions.GET_OTS_CLASS_REQUEST:
      return { ...state, otsClass: null };
    case Actions.GET_OTS_CLASS_SUCCESS:
      return {
        ...state,
        otsClass: action.payload,
      };
    case Actions.GET_OTS_CLASS_FAILURE:
      return { ...state, error: action.payload };

    case Actions.CREATE_OTSROOM_REQUEST:
      return { ...state, pending: true };
    case Actions.CREATE_OTSROOM_SUCCESS:
      return {
        ...state,
        otsRoomData: action.payload,
        otsRoomStatus: true,
        pending: false,
        isRoomEnded: false,
        exitRoom: false,
        // isStartClass: false,
      };
    case Actions.CREATE_OTSROOM_FAILURE:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };

    case Actions.END_OTSROOM_REQUEST:
      return { ...state, pending: true };
    case Actions.END_OTSROOM_SUCCESS:
      return {
        ...state,
        isRoomEnded: true,
        otsRoomStatus: false,
        otsClassStatus: true,
        roomJoined: false,
        pending: false,
        newParticipant: 0,
        otsClassVideoUrl: null,
      };
    case Actions.END_OTSROOM_FAILURE:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };

    case Actions.OTS_CLASS_STATUS:
      return { ...state, otsClassStatus: action.payload };

    case Actions.GET_PLAYVIDEO_ACCESS:
      return { ...state, isPlayVideo: action.payload };

    case Actions.CHANGE_LOGIN_STATE:
      return { ...state, loginSuccessful: action.payload };

    case Actions.ADD_PARTICIPANT:
      let studentIds = state.studentIds;
      studentIds.push(action.payload);
      return { ...state, studentIds: studentIds };

    case Actions.REMOVE_PARTICIPANT:
      let removedStudentIds = state.studentIds;
      removedStudentIds = removedStudentIds.filter(
        (item) => item !== action.payload
      );
      return { ...state, studentIds: removedStudentIds };

    case Actions.ADD_ID:
      let participantIds = state.participantIds;
      participantIds.push(action.payload);
      return { ...state, participantIds: participantIds };

    case Actions.REMOVE_ID:
      let removedParticipantIds = state.participantIds;
      removedParticipantIds = removedParticipantIds.filter(
        (item) => item !== action.payload
      );
      return { ...state, participantIds: removedParticipantIds };

    case Actions.STORE_ROOM_TOKEN:
      return { ...state, roomToken: action.payload };

    case Actions.MUTE_UNMUTE_VIDEOPLAYER:
      return { ...state, isPlayerMuted: action.payload };

    case Actions.MUTE_UNMUTE_TEACHERMIC:
      return { ...state, isTeacherMuted: action.payload };

    case Actions.VIDEO_PLAYED:
      return { ...state, isVideoPLayed: action.payload };

    case Actions.NEW_PARTICIPANT_CONNECTED:
      return { ...state, newParticipant: action.payload };

    case Actions.TOTAL_PARTICIPANT:
      return { ...state, totalParticipant: action.payload };

    case Actions.START_CLASS:
      return { ...state, isStartClass: action.payload };

    case Actions.EXIT_ROOM:
      return { ...state, exitRoom: action.payload };

    case Actions.SYNC_VIDEO:
      return { ...state, isSyncVideo: action.payload };

    case Actions.MUTE_ALL_STUDENTS:
      return { ...state, muteStudents: action.payload };

    case Actions.ROOM_JOINED:
      return { ...state, roomJoined: action.payload };

    case Actions.LOADING:
      return { ...state, pending: action.payload };

    case Actions.ADD_LOCAL_PARTICIPANT_ID:
      return { ...state, localParticipantSid: action.payload };

    case Actions.STORE_ROOM_DATA:
      return { ...state, roomData: action.payload };

    case Actions.SPEAKTOALL_STATUS:
      return { ...state, speakToAllEnabled: action.payload };

    case Actions.COMMUNICATION_WAY:
      return { ...state, twoWayCommunication: action.payload };

    case Actions.REDIRECT_ROUTE:
      return { ...state, isRoomEnded: action.payload, otsRoomStatus: false, };

    case Actions.REFRESH_TOKEN:
      return { ...state, loginData: action.payload };

    case Actions.KEEPME_LOGGEDIN:
      return { ...state, rememberMe: action.payload };

    case Actions.REDIRECT_TO_LOGIN:
      return {
        ...state, tokenExpire: action.payload,
        loginData: null
      };

    case Actions.LOGOUT_USER:
      return {
        ...state, loginData: null, tokenExpire: true,
        teacherPerformanceData: [],
        teacherEarningsData: [],
        simpleClasses: [],
        otsClasses: [],
        navIndex: 4,
      };

    case Actions.FETCH_TEACHER_PROFILE_DETAILS_REQUEST:
      return state;
    case Actions.FETCH_TEACHER_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        teacherProfileDetails: action.payload,
      };
    case Actions.FETCH_TEACHER_PROFILE_DETAILS_FAILURE:
      return { ...state, error: action.payload };

    case Actions.UPDATE_TEACHER_PASSWORD_REQUEST:
      return state;
    case Actions.UPDATE_TEACHER_PASSWORD_SUCCESS:
      return {
        ...state,
        teacherProfilePasswordUpdate: action.payload,
      };
    case Actions.UPDATE_TEACHER_PASSWORD_FAILURE:
      return { ...state, error: action.payload };


    case Actions.TEACHER_PROFILE_BIO_UPDATE_REQUEST:
      return state;
    case Actions.TEACHER_PROFILE_BIO_UPDATE_SUCCESS:
      return {
        ...state,
        teacherBioUpdate: action.payload,
      };
    case Actions.TEACHER_PROFILE_BIO_UPDATE_FAILURE:
      return { ...state, error: action.payload };


    case Actions.TEACHER_PROFILE_UPDATE_REQUEST:
      return state;
    case Actions.TEACHER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        teacherProfileDetails: action.payload,
      };
    case Actions.TEACHER_PROFILE_UPDATE_FAILURE:
      return { ...state, error: action.payload };

    case Actions.TEACHER_PROFILE_IMAGE_UPDATE_REQUEST:
      return state;
    case Actions.TEACHER_PROFILE_IMAGE_UPDATE_SUCCESS:
      return {
        ...state,
        teacherProfileDetails: action.payload,
      };
    case Actions.TEACHER_PROFILE_IMAGE_UPDATE_FAILURE:
      return { ...state, error: action.payload };

    case Actions.AUDIO_TEST:
      return {
        ...state,
        audioTestCompleted: action.payload.audio,
        networkTestCompleted: action.payload.network
      };

    case Actions.OPEN_ZOOM_MODAL:
      return { ...state, isZoomModal: action.payload };

    case Actions.CHANGE_HEADER:
      return { ...state, showHeader: action.payload };

    case Actions.SET_SELECTED_CLASS:
      return { ...state, selectedClass: action.payload };

    case Actions.CANCEL_OTS_CLASS_MODAL:
      return { ...state, otsClassCanceled: action.payload };

    case Actions.CHANGE_OTS_CLASS_STATUS:
      return { ...state, otsClassStatus: action.payload };

    case Actions.REDIRECT_TO_MAIN_ROUTE:
      return { ...state, otsRoomStatus: action.payload };

    case Actions.SET_NAV_INDEX:
      return { ...state, navIndex: action.payload };


    case Actions.GET_TEACHER_PERFORMANCE_REQUEST:
      return state;
    case Actions.GET_TEACHER_PERFORMANCE_SUCCESS:
      return {
        ...state,
        teacherPerformanceData: action.payload,
      };
    case Actions.GET_TEACHER_PERFORMANCE_FAILURE:
      return { ...state, error: action.payload };


    case Actions.GET_TEACHER_EARNINGS_REQUEST:
      return state;
    case Actions.GET_TEACHER_EARNINGS_SUCCESS:
      return {
        ...state,
        teacherEarningsData: action.payload,
      };
    case Actions.GET_TEACHER_EARNINGS_FAILURE:
      return { ...state, error: action.payload };

    case Actions.GET_ALL_SIMPLE_CLASSES_REQUEST:
      return state;
    case Actions.GET_ALL_SIMPLE_CLASSES_SUCCESS:
      return {
        ...state,
        simpleClasses: action.payload,
      };
    case Actions.GET_ALL_SIMPLE_CLASSES_FAILURE:
      return { ...state, error: action.payload };

    case Actions.CANCEL_OTS_CLASS_REQUEST:
      return { ...state, pending: true };
    case Actions.CANCEL_OTS_CLASS_SUCCESS:
      return {
        ...state,
        pending: false,
        otsClassCanceled: true,
        otsClasses: [],
      };
    case Actions.CANCEL_OTS_CLASS_FAILURE:
      return { ...state, error: action.payload };

    case Actions.GET_OTS_CLASS_VIDEO_REQUEST:
      return { ...state, otsClassVideoUrl: null };
    case Actions.GET_OTS_CLASS_VIDEO_SUCCESS:
      return {
        ...state,
        otsClassVideoUrl: action.payload,
      };
    case Actions.GET_OTS_CLASS_VIDEO_FAILURE:
      return { ...state, error: action.payload };


    default:
      return state;
  }
};

export default rootReducer;
