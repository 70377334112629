import React from 'react';
import { Link } from "react-router-dom";
import { Button } from 'antd';

const Title = (props) => {

  const { text, index } = props;
  return (
    <div className="page-inner-title-main">
      <div className='page-inner-title'>
        <span>{text}</span>
      </div>
      {
        index === 3 ?
          <Button className='classes-button'>
            <span className='classes-button-span'>
              Add New
            </span>
          </Button>
          :
          index === 1 ?
          <div className='coaching-buttons'>
            <Link to="/createDirectCoach"> 
            {/* /createDirectCoach /otsClass/new*/}
              <div className='classes-button' style={{ width: 153 }}>
                <span className='classes-button-span'>
                  Coach a class
                </span>
              </div>
            </Link>
            <Link to="/createDemoDirectCoach">
              <div className='classes-button' style={{ width: 153 }}>
                <span className='classes-button-span'>
                  Demo class
                </span>
              </div>
            </Link>
          </div>
            :
            null
      }
    </div>
  );
}

export default Title;
