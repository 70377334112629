import React, { useState } from 'react';
import TestAudioSpeaker from '../../components/TestScreen/testAudioSpeaker';
import TestHome from '../../components/TestScreen/testHomeScreen';

const TestHomeScreen = (props) => {
    const [stepNumber, setStepNumber] = useState(0);
    const handleStepNumber = (val) => (
        setStepNumber(val)
    );

    return (
        <div className="test-home-screen">
            {stepNumber === 0 ?
                <TestHome handleStepNumber={handleStepNumber} />
                :
                <TestAudioSpeaker handleStepNumber={handleStepNumber}/>
            }

        </div>
    );
}

export default TestHomeScreen;