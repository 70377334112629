import React, { useState, useLayoutEffect, useEffect } from "react";
import { Button, Divider, Tooltip } from "antd";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from 'moment';

import AddSpotModal from "./addSpotModal";
import EditCostModal from "./editCostModal";
import CancelClassModal from "../cancelClassModal";
import EditDateTimeModal from "./editDateTimeModal";
import { ReactComponent as CostIcon } from "../../../assets/img/icons/cost.svg";
import { ReactComponent as DurationIcon } from "../../../assets/img/icons/duration.svg";
import { ReactComponent as EarningsIcon } from "../../../assets/img/icons/earnings.svg";
import { ReactComponent as ParticipantsIcon } from "../../../assets/img/icons/participants_new.svg";
import { ReactComponent as TotalCost } from "../../../assets/img/icons/total_cost.svg";
import { ReactComponent as TotalDuration } from "../../../assets/img/icons/time_watched.svg";
import { ReactComponent as TotalEarnings } from "../../../assets/img/icons/total_earnings.svg";
import { ReactComponent as TotalParticipants } from "../../../assets/img/icons/total_participants.svg";
import { updateOTSCLass } from "../../../actions/actions";
import useWindowSize from "../../Hooks/windowSize";


const EditStatsTitle = (props) => {
    const { selectedClass, handleClassChange, otsClass, loginData, isUpcoming } = props;
    const [width, height] = useWindowSize();

    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openDateModal, setOpenDateModal] = useState(false);
    const [openCostModal, setOpenCostModal] = useState(false);
    const [openParticipantsModal, setOpenParticipantsModal] = useState(false);
    const [editedDate, setEditedDate] = useState(null);
    const [editedStartTime, setEditedStartTime] = useState(null);
    const [editedEndTime, setEditedEndTime] = useState(null);
    const [totalPrice, setTotalPrice] = useState(selectedClass.classData.OTS.price);
    const [totalParticipants, setTotalParticipants] = useState(selectedClass.classData.OTS.maxParticipants);
    const [startTime, setStartTime] = useState(selectedClass.classData.OTS.startDateTime);
    const [endTime, setEndTime] = useState(selectedClass.classData.OTS.endDateTime);
    const [enableTimeModal, setEnableTimeModal] = useState(
        selectedClass.classData.OTS.status === 'canceled' ? false : (moment(selectedClass.classData.OTS.startDateTime).subtract(48, 'hours')).isAfter(moment()))

    useEffect(() => {
        if (otsClass && openCostModal) {
            setOpenCostModal(false);
        }
        else if (otsClass && openParticipantsModal) {
            setOpenParticipantsModal(false);
        }
        else if (otsClass && openDateModal) {
            setOpenDateModal(false);
        }
    }, [otsClass]);


    const updateOtsCost = (value) => {
        let dataBody = {
            price: value,
        }
        let dataParams = {
            classID: selectedClass.classData.OTS.id,
            token: loginData.access,
        }
        props.updateOTSCLass(dataBody, dataParams);
        setTotalPrice(value);
    };

    const updateOtsParticipants = (value) => {
        let dataBody = {
            maxParticipants: value,
        }
        let dataParams = {
            classID: selectedClass.classData.OTS.id,
            token: loginData.access,
        }
        props.updateOTSCLass(dataBody, dataParams);
        setTotalParticipants(value);
    };

    const updateOtsDateTime = (startValue, endValue) => {
        let dataBody = {
            startDateTime: startValue,
            endDateTime: endValue,
        }
        let dataParams = {
            classID: selectedClass.classData.OTS.id,
            token: loginData.access,
        }
        props.updateOTSCLass(dataBody, dataParams);
        setStartTime(startValue);
        setEndTime(endValue);
        setEnableTimeModal((moment(startValue).subtract(48, 'hours')).isAfter(moment()))
    };

    const handleChangeCancelModal = () => {
        setOpenCancelModal(!openCancelModal);
    };

    const handleChangeDateModal = () => {
        setOpenDateModal(!openDateModal);
    };

    const handleChangeCostModal = () => {
        setOpenCostModal(!openCostModal);
    };

    const handleChangeParticipantsModal = () => {
        setOpenParticipantsModal(!openParticipantsModal);
    };

    const handleEditValue = (date, startTime, endTime) => {
        setEditedDate(date)
        setEditedStartTime(startTime)
        setEditedEndTime(endTime)
    };

    const openEmailWindow = () => {
        window.open('mailto:support@bodymindlife.online');
    }

    return (
        <div className="edit-stats">
            <div className="edit-stats-heading">
                Direct Coach
            </div>
            <div className='edit-ots-main'>
                <div className='edit-ots'>
                    <div className='edit-ots-icon'>
                        {width < 768 ?
                            <EarningsIcon /> :
                            <TotalEarnings />
                        }
                    </div>
                    {width < 768 ?
                        <span className='edit-ots-icon-heading' style={{ width: '55%', marginLeft: 5 }}>
                            Estimated earnings
                        </span>
                        :
                        <span className='edit-ots-icon-heading'>
                            Est. earnings
                        </span>
                    }
                    <span className='edit-ots-icon-value'>
                        ${
                            (totalParticipants * totalPrice).toFixed(2) -
                            ([(totalParticipants * totalPrice) / 100] * 20).toFixed(2)
                        }
                    </span>
                </div>
                {width < 768 &&
                    <Divider className='edit-ots-divider' />
                }

                <div className='edit-ots'>
                    <div className='edit-ots-icon'>
                        {width < 768 ?
                            <ParticipantsIcon /> :
                            <TotalParticipants />
                        }
                    </div>
                    <span className='edit-ots-icon-heading'>
                        Participants
                    </span>
                    <span className='edit-ots-icon-value'>
                        {selectedClass.classData.Booked}/{totalParticipants}
                    </span>
                </div>
                {width < 768 &&
                    <Divider className='edit-ots-divider' />
                }

                <div className='edit-ots'>
                    <div className='edit-ots-icon'>
                        {width < 768 ?
                            <CostIcon /> :
                            <TotalCost />
                        }
                    </div>
                    <span className='edit-ots-icon-heading'>
                        Cost
                    </span>
                    <span className='edit-ots-icon-value'>
                        ${totalPrice}
                    </span>
                </div>
                {width < 768 &&
                    <Divider className='edit-ots-divider' />
                }

                <div className='edit-ots'>
                    <div className='edit-ots-icon'>
                        {width < 768 ?
                            <DurationIcon /> :
                            <TotalDuration />
                        }
                    </div>
                    <span className='edit-ots-icon-heading'>
                        Duration
                    </span>
                    <span className='edit-ots-icon-value'>
                        {`${parseInt((selectedClass.classData.OTS.videoDuration ?? 0) / 60)} min`}
                    </span>
                </div>

            </div>
            <Divider style={{ margin: width < 768 ? '10px 0px' : '24px 0px' }} />
            <div className='edit-detail'>
                <div className='edit-detail-container'>
                    <span className='edit-detail-heading'>
                        Settings
                    </span>
                    <div className='edit-detail-timing'>
                        <div className='edit-detail-timeline'>
                            <span className='edit-detail-date'>
                                Date & time
                            </span>
                            <span className='edit-detail-timebutton'
                                onClick={(e) => {
                                    enableTimeModal ?
                                        handleChangeDateModal()
                                        : e.preventDefault()
                                }}
                                style={{
                                    color: enableTimeModal ? '#262626' : '#929292',
                                    cursor: enableTimeModal ? 'pointer' : 'default',
                                    // display: 'flex'
                                }}
                            >
                                Edit
                            </span>
                        </div>
                        <span className='edit-detail-time' style={{ display: 'flex' }}>
                            {moment(startTime).format('dddd, DD MMM ')}at
                            {moment(startTime).format(' hh:mm a ')}
                            {moment(endTime).format('- hh:mm a')}
                        </span>
                    </div>
                    {isUpcoming &&
                        <span style={{ color: '#929292' }} >
                            You can change the date and time upto 48 hour prior to the class
                        </span>
                    }
                    <Divider style={{ margin: width < 768 ? '12px 0px' : '24px 0px' }} />
                    <div className='edit-detail-timing'>
                        <div className='edit-detail-timeline'>
                            <span className='edit-detail-date'>
                                Students
                            </span>
                            <span className='edit-detail-timebutton'
                                onClick={(e) => {
                                    isUpcoming ?
                                        handleChangeParticipantsModal()
                                        : e.preventDefault()
                                }}
                                style={{
                                    color: isUpcoming ? '#262626' : '#929292',
                                    cursor: isUpcoming ? 'pointer' : 'default'
                                }}
                            >
                                Edit
                            </span>
                        </div>
                        <span className='edit-detail-time'>
                            {selectedClass.classData.Booked}/{totalParticipants}
                        </span>
                    </div>
                    <Divider style={{ margin: width < 768 ? '12px 0px' : '24px 0px' }} />
                    <div className='edit-detail-timing'>
                        <div className='edit-detail-timeline'>
                            <span className='edit-detail-date'>
                                Cost
                            </span>
                            <span className='edit-detail-timebutton'
                                onClick={(e) => {
                                    isUpcoming ?
                                        handleChangeCostModal()
                                        : e.preventDefault()
                                }}
                                style={{
                                    color: isUpcoming ? '#262626' : '#929292',
                                    cursor: isUpcoming ? 'pointer' : 'default'
                                }}
                            >
                                Edit
                            </span>
                        </div>
                        <span className='edit-detail-time'>
                            ${totalPrice}
                        </span>
                    </div>
                    <div className='edit-detail-earnings'>
                        <span className='edit-detail-earnings-heading'>
                            Estimated earnings
                        </span>
                        <div className='edit-detail-earnings-container' style={{ paddingTop: 20 }}>
                            <span className='edit-detail-earnings-name'>
                                Students
                            </span>
                            <span className='edit-detail-earnings-name'>
                                {totalParticipants}
                            </span>
                        </div>
                        <div className='edit-detail-earnings-container'>
                            <span className='edit-detail-earnings-name'>
                                Cost
                            </span>
                            <span className='edit-detail-earnings-name'>
                                ${(totalParticipants * totalPrice).toFixed(2)}
                            </span>
                        </div>
                        <div className='edit-detail-earnings-container'>
                            <span className='edit-detail-earnings-name'>
                                Platform Fee -20%
                            </span>
                            <span className='edit-detail-earnings-name'>
                                ${([(totalParticipants * totalPrice) / 100] * 20).toFixed(2)}
                            </span>
                        </div>
                        <div className='edit-detail-earnings-container'>
                            <span className='edit-detail-earnings-name' style={{ fontWeight: 600 }}>
                                Total
                            </span>
                            <span className='edit-detail-earnings-name' style={{ fontWeight: 600 }}>
                                ${
                                    (totalParticipants * totalPrice).toFixed(2) -
                                    ([(totalParticipants * totalPrice) / 100] * 20).toFixed(2)
                                }
                            </span>
                        </div>
                    </div>
                </div>
                {width < 768 &&
                    <Divider />
                }
                <div className='edit-detail-message'>
                    <div className='edit-detail-buttons'>
                        <div className='edit-detail-button-box' >
                            <Button className='edit-detail-support-button'
                                onClick={() => { openEmailWindow() }}
                            >
                                Contact support
                            </Button>
                        </div>
                        <div className='edit-detail-button-box' onClick={() => { handleChangeCancelModal() }} >

                            <Button disabled={!isUpcoming} className='edit-detail-support-button'>
                                Cancel class
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {openCancelModal && (
                <CancelClassModal
                    handleClassChange={handleClassChange}
                    selectedClass={selectedClass}
                    handleChangeCancelModal={handleChangeCancelModal}
                    handleChangeDateModal={handleChangeDateModal}
                />
            )
            }

            {openCostModal && (
                <EditCostModal
                    totalPrice={totalPrice}
                    updateOtsCost={updateOtsCost}
                    openCostModal={openCostModal}
                    handleChangeCostModal={handleChangeCostModal}
                />
            )
            }

            {openParticipantsModal && (
                <AddSpotModal
                    totalParticipants={totalParticipants}
                    updateOtsParticipants={updateOtsParticipants}
                    handleChangeParticipantsModal={handleChangeParticipantsModal}
                />
            )
            }

            {openDateModal && (
                <EditDateTimeModal
                    data={selectedClass}
                    startTime={startTime}
                    endTime={endTime}
                    handleEditValue={handleEditValue}
                    updateOtsDateTime={updateOtsDateTime}
                    handleChangeDateModal={handleChangeDateModal}
                />
            )
            }
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOTSCLass: (data, data2) => dispatch(updateOTSCLass(data, data2)),
    };
};

const mapStateToProps = (state) => {
    return {
        otsClass: state.otsClass,
        loginData: state.loginData,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EditStatsTitle);