import React, { useEffect, useState } from "react";
import VerticalProgress from "./verticalProgressbar";

const MostActiveHour = (props) => {

    const { data } = props;

    const [activeHours, setActiveHours] = useState();
    const [totalHours, setTotalHours] = useState();

    useEffect(() => {
        let totalMorningHours = 0;
        let totalAfternoonHours = 0;
        let totalEveningHours = 0;

        Object.keys(data.all).map(key => {
            let hour = parseInt(key);
            let offset = new Date().getTimezoneOffset() / 60;
            let localHour = hour - offset;
            if (localHour > 24) {
                localHour -= 24;
            } else if (localHour < 0) {
                localHour += 24;
            }
            if (localHour <= 11) {
                totalMorningHours += data.all[key];
            }
            else if (localHour > 11 && localHour <= 17) {
                totalAfternoonHours += data.all[key];
            } else {
                totalEveningHours += data.all[key];
            }
        });
        setActiveHours([
            {
                session: 'Morning',
                duration: '<11AM',
                hours: totalMorningHours
            },
            {
                session: 'Afternoon',
                duration: '11-5PM',
                hours: totalAfternoonHours
            },
            {
                session: 'Evening',
                duration: '>5PM',
                hours: totalEveningHours
            }
        ]);
        setTotalHours(totalMorningHours + totalAfternoonHours + totalEveningHours);
    }, []);

    return (
        <div>
            {activeHours && Object.keys(activeHours).map((key, index) => (
                <div
                    style={{ display: "flex", flexDirection: "row", margin: "25px 0px" }}
                >
                    <div
                        style={{ display: "flex", flexDirection: "column", width: "30%" }}

                    >
                        <span className="vertical-graph-label"
                            style={{ marginBottom: 3 }}
                        >
                            {activeHours[key].session}
                        </span>
                        <span className="vertical-graph-label"
                            style={{ fontWeight: 400, marginTop: 3 }}
                        >
                            {activeHours[key].duration}
                        </span>
                    </div>
                    <VerticalProgress
                        progress={parseInt(activeHours[key].hours / totalHours * 100)}
                        verticalBar={true}
                        horizontalBar={true}
                    />
                </div>
            ))}
        </div>
    );
};

export default MostActiveHour;
