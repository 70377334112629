import React, { useEffect, useState, useRef } from "react";
import TestAudio from "../../assets/audio/demoAudio.mp3";

const MusicCanvas = (props) => {

    const { handleAudioStatus } = props;
    const [isVisualozationInitialized, setIsVisualozationInitialized] = useState(false);
    const [isVisualozationPaused, setIsVisualozationPaused] = useState(true);
    const audioRefNew = useRef();
    const canvasRefNew = useRef();

    const onAudioEnded = () => {
        setIsVisualozationPaused(true);
        handleAudioStatus(false);
    }

    useEffect(() => {
        createVisualization();
    }, []);

    const createVisualization = () => {
        let ctx = new (window.AudioContext || window.webkitAudioContext)();
        if (!isVisualozationInitialized) {
            let audioRef = audioRefNew.current;
            let canvasRef = canvasRefNew.current;
            let analyser = ctx.createAnalyser();
            let audioSrc = ctx.createMediaElementSource(audioRef);
            audioSrc.connect(analyser);
            analyser.connect(ctx.destination);
            let cwidth = canvasRef.width;
            let cheight = canvasRef.height - 0;
            let meterWidth = 8; //width of the meters in the spectrum
            let gap = 2; //gap between meters
            let capHeight = 1;
            let capStyle = '#fff';
            let meterNum = 500 / (10 + 3); //count of the meters
            let capYPositionArray = []; ////store the vertical position of hte caps for the preivous frame
            ctx = canvasRef.getContext('2d');
            let gradient = ctx.createLinearGradient(0, 0, 0, 300);
            gradient.addColorStop(1, '#1d615a');
            gradient.addColorStop(0.5, '#1d615a');
            gradient.addColorStop(0, '#1d615a');
            // loop
            function renderFrame() {
                let array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                let step = Math.round(array.length / meterNum); //sample limited data from the total array
                ctx.clearRect(0, 0, cwidth, cheight);
                for (let i = 0; i < meterNum; i++) {
                    let value = array[i * step];
                    if (capYPositionArray.length < Math.round(meterNum)) {
                        capYPositionArray.push(value);
                    };
                    ctx.fillStyle = capStyle;
                    //draw the cap, with transition effect
                    if (value < capYPositionArray[i]) {
                        ctx.fillRect(i * 12, cheight - (--capYPositionArray[i]), meterWidth, capHeight);
                    } else {
                        ctx.fillRect(i * 12, cheight - value, meterWidth, capHeight);
                        capYPositionArray[i] = value;
                    };
                    ctx.fillStyle = gradient; //set the filllStyle to gradient for a better look
                    ctx.fillRect(i * 11 /*meterWidth+gap*/, cheight - value + capHeight, meterWidth, cheight); //the meter
                }
                requestAnimationFrame(renderFrame);
            }
            renderFrame();
            audioRef.muted = false;
            audioRef.play();
            setIsVisualozationPaused(false);
            setIsVisualozationInitialized(true);
        } else if (isVisualozationPaused) {
            let audioRef = audioRefNew.current;
            audioRef.play();
            setIsVisualozationPaused(false)
        } else {
            ctx.suspend();
            audioRefNew.current.pause();
            setIsVisualozationPaused(true);
        }
    };
    return (

        <div id="mp3_player" style={{ width: 80, height: 24 }}>
            <canvas
                ref={canvasRefNew}
                width="150" height="100%"
                className="speaker-canvas"
                style={{ height: 40, marginLeft: 10, marginTop: -12 }}
            >

            </canvas>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    height: '100%'
                }}
            >
                <audio
                    style={{ width: "100%" }}
                    src={TestAudio}
                    ref={audioRefNew}
                    onEnded={onAudioEnded}
                    muted="muted"
                ></audio>
            </div>
            <div
                className="footer-button"
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
            </div>
        </div>


    );
}

export default MusicCanvas;