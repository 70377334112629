import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Calendar, Divider } from 'antd';
import TimeField from 'react-simple-timefield';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/img/icons/minus.svg';
import useWindowSize from '../../Hooks/windowSize';


const DirectCoachDateTime = (props) => {
    const {
        startTimeValue, endTimeValue,
        handleStartTimeValue, handleEndTimeValue, handleSelectedDate, selectedDate
    } = props;

    const [userEndTime, setUserEndTime] = useState(false)

    let startTimeValueLocal = startTimeValue;
    useEffect(() => {

        startTimeValueLocal = startTimeValue;

    }, [startTimeValue])


    const [width] = useWindowSize();
    const [selectedMonth, setSelectedMonth] = useState(false);
    const [timeData, setTimeData] = useState([])

    moment.updateLocale('en', {
        weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"]
    });

    const handleForLoop = () => {
        const timeDataArr = [];
        const currentHour = moment().format('H')
        {
            for (var i = selectedDate.format('YYYY MM DD') === moment().format('YYYY MM DD') ? currentHour : 0; i < 24; i++) {
                var minute = '00';
                const mod = i % 12;
                for (var j = 0; j < 4; j++) {
                    if (currentHour === i) {
                        if (parseInt(minute) > moment().format('mm'))
                            timeDataArr.push(`${mod == 0 ? 12 : mod < 10 ? `0${mod}` : mod} : ${minute} ${i > 11 ? 'pm' : 'am'}`)
                    } else {
                        timeDataArr.push(`${mod == 0 ? 12 : mod < 10 ? `0${mod}` : mod} : ${minute} ${i > 11 ? 'pm' : 'am'}`)
                    }
                    minute = parseInt(minute) + 15

                }
            }
            setTimeData(timeDataArr);
            handleStartTimeValue(moment(timeDataArr[0] + startTimeValue.format(' a'), 'hh:mm a'));
        }
    }
    const onDateChange = (value) => {
        handleSelectedDate(value);
        if (moment().isSame(value, 'month')) {
            setSelectedMonth(false)
        }
        else {
            setSelectedMonth(true)
        }
    }

    useEffect(() => {

        width < 767 && width !==0 && handleForLoop()

    }, [selectedDate])



    const dateFullCellRender = (value) => {
        const handleKeyPress = (event) => {
            event.stopPropagation();
        }
        return (
            <div onClick={() => { onDateChange(value) }} tabIndex="1"
                className='create-direct-coach-calendar-cell'
                onKeyDown={handleKeyPress}>
                {!moment().isAfter(value, 'month') && moment(value).isBefore(moment().add(1, "month").endOf("month")) &&
                    <div className='date-fullcell-directcoach'>
                        <span className="date-fullcell-directcoach-span"
                        //   ref={moment(value).isSame(date, 'day') ? currentDateRef : null}
                        >
                            {moment(value).format('DD')}
                        </span>
                    </div>
                }
            </div>
        )
    };


    const startTimeChangeMobile = (value) => {
        handleStartTimeValue(moment(value + startTimeValue.format(' a'), 'hh:mm a'));
        if (!userEndTime) {
            handleEndTimeAutoUpdate(moment(value + startTimeValue.format(' a'), 'hh:mm a'));
        }
    }

    //TODO: start time desktop only
    const startTimeOnChange = (event) => {
        handleStartTimeValue(moment(event.target.value + startTimeValue.format(' a'), 'hh:mm a'));
        if (!userEndTime) {
            handleEndTimeAutoUpdate(moment(event.target.value + startTimeValue.format(' a'), 'hh:mm a'));
        }
    };

    const handleStartTimeAddMinutes = () => {
        handleStartTimeValue(startTimeValue.add(15, 'minutes'));
        if (!userEndTime)
            handleEndTimeAutoUpdate();
    };

    const handleStartTimeSubtractMinutes = () => {
        handleStartTimeValue(startTimeValue.subtract(15, 'minutes'));
        if (!userEndTime)
            handleEndTimeAutoUpdate();
    };

    const handleClickStartTimeMeridiem = () => {
        let updatedStartTime = `${startTimeValue.format('YYYYMMDD hh:mm ') + { 'am': 'pm', 'pm': 'am' }[startTimeValue.format('a')]}`;
        let updatedMoment = moment(updatedStartTime, 'YYYYMMDD hh:mm a');
        handleStartTimeValue(updatedMoment);
    };

    //ENDTODO: start time desktop only


    //TODO: End time desktop only
    const endTimeOnChange = (event) => {
        handleEndTimeValue(moment(event.target.value + endTimeValue.format(' a'), 'hh:mm a'));
        if (!userEndTime)
            setUserEndTime(true)
    };

    const handleEndTimeAddMinutes = () => {
        handleEndTimeValue(endTimeValue.add(15, 'minutes'));
        if (!userEndTime)
            setUserEndTime(true)
    };

    const handleEndTimeSubtractMinutes = () => {
        handleEndTimeValue(endTimeValue.subtract(15, 'minutes'));
        if (!userEndTime)
            setUserEndTime(true)
    };

    const handleEndTimeAutoUpdate = (value) => {
        if (value)
            handleEndTimeValue(value.add(30, 'minutes'));
        else
            handleEndTimeValue(startTimeValue.add(30, 'minutes'));
    }

    const handleClickEndTimeMeridiem = () => {
        let updatedStartTime = `${endTimeValue.format('YYYYMMDD hh:mm ') + { 'am': 'pm', 'pm': 'am' }[endTimeValue.format('a')]}`;
        let updatedMoment = moment(updatedStartTime, 'YYYYMMDD hh:mm a');
        handleEndTimeValue(updatedMoment);
    };

    //ENDTODO: end time desktop only

    return (
        <div className='direct-coach-date-time'>
            <Calendar
                className='direct-coach-calendar'
                dateFullCellRender={dateFullCellRender}
                disabledDate={(date) => {
                    if (!moment().isAfter(date, 'month') && moment(date).isBefore(moment().add(1, "month").endOf("month")) && !moment(date).isBefore(moment(), 'day')) {
                        return false;
                    }
                    return true;
                }}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                    const current = value.clone();
                    const localeData = value.localeData();
                    const months = [];
                    for (let i = 0; i < 12; i++) {
                        current.month(i);
                        months.push(localeData.months(current));
                    }

                    const handleCurrentMonthClick = () => {
                        if (!moment().isSame(value, 'month')) {
                            const newValue = value.clone();
                            newValue.subtract(1, 'months');
                            onChange(newValue);
                            setSelectedMonth(false);
                        }
                    }

                    const handleNextMonthClick = () => {
                        if (moment().isSame(value, 'month')) {
                            const newValue = value.clone();
                            newValue.add(1, 'months');
                            onChange(newValue);
                            setSelectedMonth(true);
                        }
                    }

                    return (
                        <div className="directcoach-calendar-header">
                            <span
                                className="directcoach-calendar-month-title"
                                onClick={() => handleCurrentMonthClick()}
                                style={{
                                    color: selectedMonth ? '#262626' : '#fff',
                                    backgroundColor: selectedMonth ? '#fff' : '#1d615a'
                                }}
                            >
                                {moment().format('MMM')}
                            </span>
                            <span
                                className="directcoach-calendar-month-title"
                                onClick={() => handleNextMonthClick()}
                                style={{
                                    color: !selectedMonth ? '#262626' : '#fff',
                                    backgroundColor: !selectedMonth ? '#fff' : '#1d615a'
                                }}
                            >
                                {moment().add(1, "month").format('MMM')}
                            </span>
                        </div>
                    );
                }
                }
            />
            <Divider className='directcoach-divider' />
            <div className='directcoach-time-section'>
                <span className='directcoach-time-input-title'>
                    Start time
                </span>
                <div className='directcoach-time-section-input'>
                    {width < 767 ?
                        <div className='directcoach-horizontal-time'>
                            {timeData.map((val, index) => (
                                <>
                                    <div className={`directcoach-horizontal-time-container ${startTimeValue.format('hh : mm a') == val ? "selected" : ""} ${index === 0 ? 'first-index' : ''}`} onClick={() => {
                                        startTimeChangeMobile(val)
                                    }}>
                                        <span className='directcoach-horizontal-time-input'>
                                            {val}
                                        </span>
                                    </div>
                                </>
                            ))}
                        </div>
                        :
                        <>
                            <MinusIcon
                                className='directcoach-minus-icon'
                                style={{ backgroundColor: '#1d615a' , cursor:'pointer'}}
                                onClick={() => handleStartTimeSubtractMinutes()}
                            />
                            <div className='directcoach-input-container'>
                                <TimeField
                                    className='directcoach-time-input'
                                    style={{ width: 'auto' }}
                                    value={startTimeValue.format('hh:mm')}
                                    onChange={startTimeOnChange}
                                />
                                <span
                                    key={startTimeValue.format('a')}
                                    className='directcoach-meridiem'
                                    onClick={() => handleClickStartTimeMeridiem()}
                                >
                                    {startTimeValue.format('a')}
                                </span>
                            </div>
                            <div className='directcoach-plus-icon-container'
                                onClick={() => handleStartTimeAddMinutes()}
                                style={{ backgroundColor: '#1d615a', zIndex:9999, cursor:'pointer' }}
                            >
                                <PlusIcon
                                    className='directcoach-plus-icon'
                                // onClick={() => handleStartTimeAddMinutes()}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>

            <Divider className='directcoach-divider' />
            <div className='directcoach-time-section'>
                <span className='directcoach-time-input-title'>
                    End time
                </span>
                <div className='directcoach-time-section-input'>
                    <MinusIcon
                        className='directcoach-minus-icon'
                        style={{ backgroundColor: '#1d615a',cursor:'pointer' }}
                        onClick={() => handleEndTimeSubtractMinutes()}
                    />
                    <div className='directcoach-input-container'>
                        <TimeField
                            className='directcoach-time-input'
                            style={{ width: 'auto' }}
                            value={moment(endTimeValue, 'hh:mm').format('hh:mm')}
                            onChange={endTimeOnChange}
                        />
                        <span
                            className='directcoach-meridiem'
                            onClick={() => handleClickEndTimeMeridiem()}
                        >
                            {endTimeValue.format('a')}
                        </span>
                    </div>
                    <div className='directcoach-plus-icon-container'
                        onClick={() => handleEndTimeAddMinutes()}
                        style={{ backgroundColor: '#1d615a', zIndex: 99999, cursor:'pointer' }}
                    >
                        <PlusIcon
                            className='directcoach-plus-icon'
                        //onClick={() => handleEndTimeAddMinutes()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DirectCoachDateTime;