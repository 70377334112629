import React from 'react';
import Countdown from 'react-countdown';
import moment from 'moment'

const ClassCountDown = (props) => {

    const { classData } = props;
    const startDate = React.useRef(Date.now());
    
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render the time in negative
            return <span className="countdown-overtime">{` - `}{` 0${hours}`}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>;
        } else {
            // Render a countdown
            return <span>{` 0${hours}`}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>;
        }
    }

    return (
        <Countdown
            date={
                // moment({}).seconds(classData.duration)
                startDate.current + (classData.duration * 1000)
            }
            renderer={renderer}
            overtime={true}
        />
    );
}

export default ClassCountDown;