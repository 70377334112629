import React, { useEffect } from "react";
import { connect } from "react-redux";

import JoinRoom from "../../components/JoinRoom";
import StartClassFooter from "../../components/Footer/startClassFooter";


const Home = (props) => {
  const { tokenExpire } = props;

  const userName = props.loginData ? props.loginData.userName : "";

  useEffect(() => {
    if (tokenExpire) {
      props.history.push("/")
    }
  }, [tokenExpire]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    props.history.push("/main");
  }

  useEffect(() => {
    //handling back button browser functionality so that it goes directly to main page
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  return (
    <div className="bg-home">
      <div className="row">
        <div>
          <div className="student-videos" style={{ textAlign: "center" }}>
            <JoinRoom
              userName={userName} />
          </div>
          <StartClassFooter />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tokenExpire: state.tokenExpire,
  };
};

export default connect(mapStateToProps, null)(Home);
