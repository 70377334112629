import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { useLocation } from 'react-router';
import { usePubNub } from "pubnub-react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button } from 'antd';

import { ReactComponent as TalkIcon } from '../../assets/img/icons/talk.svg';
import { ReactComponent as BackArrow } from "../../assets/img/icons/arrow_back.svg";
import { ReactComponent as ConverseIcon } from '../../assets/img/icons/converse.svg';
import { createOTSRoom, getOTSClassVideo, loading, startClass } from "../../actions/actions";


const TestHome = (props) => {
    const { handleStepNumber, loginData, otsRoomStatus, pending } = props;
    const location = useLocation();
    const value = location.state ? location.state.data : 
    props.history.push('/'); //if token expire then redirect to login
    const channelName = value ? `OTS_TWL_${value.classData.OTS.roomID}` : null;
    const pubnub = usePubNub();

    useEffect(() => {
        pubnub.subscribe({ channelName });
    }, [pubnub]);

    const startOtsClass = (value) => {
        const roomData = {
            token: loginData.access,
            otsId: value.id
        }
        const classData = {
            token: loginData.access,
            classId: value.classID
        }
        props.getOTSClassVideo(classData);
        props.createOTSRoom(roomData);
        props.loading(true);
        props.startClass(true);
    };

    useEffect(() => {
        if (otsRoomStatus) {
            props.history.push("/home");
            pubnub.publish({
                channel: channelName,
                message: {
                  statusCode: 301,
                },
                //status code 301 to show teacher is online and room is successfully created so that
                //student can join class automatically
            });
        }
    }, [otsRoomStatus]);

    return (
        <>
            <div className="test-home-screen-arrow" onClick={() => { props.history.push("/main"); }}>
                <BackArrow className="test-home-screen-arrow-icon" />
            </div>
            <div className="test-home-screen-container">
                <div className="test-home-screen-title">
                    {`Start ${value ? value.classData.OTS.name : null}`}
                </div>
                <div className="test-home-screen-main">
                    <div className="test-home-screen-box">
                        <div className="test-home-screen-box-talk">
                            <span >
                                <TalkIcon className="talk-icon" />
                            </span>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className="test-home-screen-box-talk-heading">Talking</span>
                            <span className="test-home-screen-box-talk-description" style={{ marginTop: 20 }}>Press this button to give group feedback during the workout.</span>
                            <span className="test-home-screen-box-talk-description" style={{ margin: '14px 0px', textDecoration: 'underline', cursor: 'not-allowed' }}>Learn more</span>
                        </div>
                    </div>
                    <div className="test-home-screen-box" style={{ marginLeft: 20 }}>
                        <div className="test-home-screen-box-converse">
                            <span>
                                <ConverseIcon className="converse-icon" style={{ marginTop: 4 }} />
                            </span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className="test-home-screen-box-talk-heading">Conversing</span>
                            <span className="test-home-screen-box-talk-description" style={{ marginTop: 20 }}>Press this button to allow everyone to chat.</span>
                            <span className="test-home-screen-box-talk-description" style={{ margin: '14px 0px', textDecoration: 'underline', cursor: 'not-allowed' }}>Learn more</span>
                        </div>
                    </div>
                </div>
                <div className="test-home-screen-test-audio" onClick={() => {
                    handleStepNumber(1)
                }}>
                    Test your audio
                </div>
                <div className="test-home-screen-button">
                    <Button
                        loading={pending}
                        className='test-home-screen-button-span'
                        onClick={() => { startOtsClass(value.classData.OTS) }}
                    >
                        <span style={{margin:'auto'}}>
                            Start the class
                        </span>
                    </Button>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        loading: (data) => dispatch(loading(data)),
        startClass: (data) => dispatch(startClass(data)),
        createOTSRoom: (data) => dispatch(createOTSRoom(data)),
        getOTSClassVideo: (data) => dispatch(getOTSClassVideo(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        pending: state.pending,
        loginData: state.loginData,
        otsRoomStatus: state.otsRoomStatus,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(TestHome);
