import React from 'react';
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button } from 'antd';

const DirectCoachBottomBar = (props) => {
    const { handleProgressNextVal, handleProgressPrevVal, stepNumber, handleSubmitClick, buttonEnable, pending, demo } = props;
    const handlePrevClick = () => {
        handleProgressPrevVal();
    }
    const handleNextClick = () => {
        handleProgressNextVal();
    }

    const handleSubmitButtonClick = () => {
        handleSubmitClick();
    }

    return (
        <div className='dc-bottom-bar'>
            <div className='dc-bottom-bar-wrapper'>
                <div className='dc-bottom-bar-wrapper-prev'>
                    {stepNumber === 0 ?
                        null
                        :
                        <Button className='dc-bottom-bar-wrapper-prev-button' onClick={() => { handlePrevClick() }}>Previous</Button>
                    }
                </div>
                <div className='dc-bottom-bar-wrapper-next'>
                    <Button
                        loading={pending}
                        className='dc-bottom-bar-wrapper-next-button'
                        disabled={buttonEnable}
                        onClick={() => {
                            stepNumber === 4 || stepNumber === 1 && demo ? handleSubmitButtonClick() : handleNextClick()
                        }}
                    >
                        {stepNumber === 4 || stepNumber === 1 && demo ? 'Submit' : 'Next'}
                    </Button>
                </div>
            </div>
        </div>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
    };
};

const mapStateToProps = (state) => {
    return {
        pending: state.pending,
    };
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(DirectCoachBottomBar);
