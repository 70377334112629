import React, { useState } from "react";
import { connect } from "react-redux";
import { Layout, Button, message } from "antd";
import { usePubNub } from "pubnub-react";
import { Rnd } from "react-rnd";

import CustomPlayer from "../CustomPlayer/customPlayer";
import ClassCountDown from "../ClassCountDown/classCountDown";
import { ReactComponent as TalkIcon } from '../../assets/img/icons/talk.svg';
import { ReactComponent as ConverseIcon } from '../../assets/img/icons/converse.svg';
import { ReactComponent as MessageIcon } from '../../assets/img/icons/message.svg';
import { ReactComponent as ParticipantIcon } from "../../assets/img/icons/participants.svg";
import {
  enableSpeakAll,
  exitRoom,
  loading,
  muteAllStudents,
  muteUnmuteTeacherMic,
  muteUnmuteVideoPlayer,
  playVideo,
  speakToAllStatus,
  startClass,
  syncVideo,
} from "../../actions/actions";
import useWindowSize from "../Hooks/windowSize";
import MessageModal from "../JoinRoom/MessageModal";

const StartClassFooter = (props) => {
  const {
    studentIds,
    localParticipantSid,
    otsRoomData,
    isPlayVideo,
    muteStudents,
    pending,
    roomJoined,
    exitRoom,
    newParticipant,
    otsRoomStatus
  } = props;

  const { Footer } = Layout;
  const [width] = useWindowSize();
  const [speakAll, setSpeakAll] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const pubnub = usePubNub();
  const channelName = `OTS_TWL_${otsRoomData.OTS.roomID}`;

  const startClass = () => {
    props.loading(true);
    props.startClass(true);
  };

  const endClass = () => {
    props.setExitRoom(!exitRoom);
  };

  const handleMessageModal = () =>{
    setOpenMessageModal(!openMessageModal);
  };

  const submitMessage = (value) => {
    pubnub.publish({
      channel: channelName,
      message: {
        statusCode: 601,
        teacherMessage: value
      },
    }, (status, response) => {
      // handle status, response
      if(status.statusCode === 200){
        message.success({
          content: "Message sent successfully",
        });
      } else {
        message.error({
          content: "Network Error! Please send message again",
        });
      }
    });
  };

  const speakToAll = () => {
    if (speakAll) {
      const data = {
        participants: studentIds,
        roomId: otsRoomData.OTS.roomID,
        teacherId: localParticipantSid,
        type: "exclude",
      };
      props.enableSpeakAll(data);
      pubnub.publish({
        channel: channelName,
        message: {
          statusCode: 100,
        },
      });
      props.muteUnmuteTeacherMic(false);
      setSpeakAll(false);
      props.speakToAllStatus(false);
    } else {
      const data = {
        participants: studentIds,
        roomId: otsRoomData.OTS.roomID,
        teacherId: localParticipantSid,
        type: "include",
      };
      props.enableSpeakAll(data);
      pubnub.publish({
        channel: channelName,
        message: {
          statusCode: 101,
        },
      });
      props.muteUnmuteTeacherMic(true);
      setSpeakAll(true);
      props.speakToAllStatus(true);
      props.muteAllStudents(false);
    }
  };

  const playPauseVideo = (event) => {
    if (event.detail) {
      props.playVideo(!isPlayVideo);
    }
  };

  const unMuteAllStudents = () => {
    if (muteStudents) {
      props.muteUnmuteTeacherMic(false);
      const data = {
        participants: studentIds,
        roomId: otsRoomData.OTS.roomID,
        teacherId: localParticipantSid,
        type: "exclude",
      };
      props.enableSpeakAll(data);
      props.playVideo(true);
      props.muteUnmuteVideoPlayer(false);
      props.muteAllStudents(!muteStudents);
      pubnub.publish({
        channel: channelName,
        message: {
          statusCode: 104,
        },
      });
    } else {
      props.muteUnmuteTeacherMic(true);
      const data = {
        participants: studentIds,
        roomId: otsRoomData.OTS.roomID,
        teacherId: localParticipantSid,
        type: "include",
      };
      props.enableSpeakAll(data);
      props.playVideo(false);
      props.muteUnmuteVideoPlayer(true);
      props.muteAllStudents(!muteStudents);
      props.speakToAllStatus(false);
      setSpeakAll(false);
      pubnub.publish({
        channel: channelName,
        message: {
          statusCode: 103,
        },
      });
    }
  };

  const format = (seconds) => {
    if (isNaN(seconds)) {
      return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours().toString().padStart(2, "0");
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${hh}:${mm}:${ss}`;
  };

  return (

    <div className="waiting-screen">
      <div className="waiting-screen-bottom">
        <div className="waiting-screen-bottom-left">
          <CustomPlayer />
        </div>
        <div className="waiting-screen-bottom-middle">
          <div className="waiting-screen-bottom-middle-wrap left-icon" style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              className="waiting-screen-bottom-middle-icons"
              onClick={(e) => { newParticipant ? speakToAll() : e.preventDefault() }}
              style={{
                backgroundColor: speakAll ? '#1ab3b5' : '#fff'
              }}
            >
              <span>
                <TalkIcon
                  className={
                    speakAll ? "waiting-screen-talk-icon-white" :
                      "waiting-screen-talk-icon-black"
                  }
                />
              </span>
            </div>
            <span className="waiting-screen-bottom-middle-text">Talk</span>
          </div>

          <div className="waiting-screen-bottom-middle-wrap middle-icon">
            <div
              className="waiting-screen-bottom-middle-icons"
              onClick={(e) => { newParticipant ? unMuteAllStudents() : e.preventDefault() }}
              style={{
                backgroundColor: muteStudents && newParticipant > 0 ? '#f57f17' : '#fff'
              }}
            >
              <span >
                <ConverseIcon
                  className={
                    muteStudents && newParticipant > 0 ? "waiting-screen-converse-icon-white" :
                      "waiting-screen-converse-icon-black"} />
              </span>
            </div>
            <span className="waiting-screen-bottom-middle-text">Converse</span>
          </div>
          <div className="waiting-screen-bottom-middle-wrap">
            <div className="waiting-screen-bottom-middle-icons" 
              onClick={()=>handleMessageModal()}
            >
              <span>
                <MessageIcon className="waiting-screen-message-icon" />
              </span>
            </div>
            <span className="waiting-screen-bottom-middle-text">Message</span>
          </div>
          {width > 320 && width < 767 ?

            <div className="waiting-screen-bottom-middle-mobile">
              <span className="waiting-screen-bottom-right-section-participant-line"> |</span>
              <div className="waiting-screen-bottom-right-section-duration-cell">
                <span className="waiting-screen-bottom-right-section-duration">
                  {otsRoomStatus ?
                    <ClassCountDown classData={otsRoomData.OTS} />
                    :
                    <span>
                      {format(otsRoomData.OTS.duration)}
                    </span>
                  }
                </span>
              </div>
              <Button className="waiting-screen-bottom-middle-mobile-button"
                style={{
                  backgroundColor: roomJoined || otsRoomStatus ? '#d32f2f' : '#1d615a',
                  borderColor: roomJoined || otsRoomStatus ? '#d32f2f' : '#1d615a'
                }}
                onClick={() => { roomJoined || otsRoomStatus ? endClass() : startClass() }}
                loading={pending}
              >
                {roomJoined || otsRoomStatus ?
                  <span style={{ marginTop: 3 }}>End</span> :
                  <span style={{ marginTop: 3 }}>Start</span>
                }
              </Button>
            </div>
            : null
          }
        </div>
        <div className="waiting-screen-bottom-right">
          <div className="waiting-screen-bottom-right-section">
            <span className="waiting-screen-bottom-right-section-participant"><ParticipantIcon className="waiting-screen-participant-icon" /></span>
            <span className="waiting-screen-bottom-right-section-participant-count">{newParticipant ? newParticipant : 0}/{otsRoomData.OTS.maxParticipants}</span>
          </div>
          <span className="waiting-screen-bottom-right-section-participant-line"> |</span>
          <div className="waiting-screen-bottom-right-section-duration-cell">
            <span className="waiting-screen-bottom-right-section-duration">
              {otsRoomStatus ?
                <ClassCountDown classData={otsRoomData.OTS} /> :
                <span>
                  {format(otsRoomData.OTS.duration)}
                </span>
              }
            </span>
          </div>
          <Button
            className="waiting-screen-bottom-right-section-button"
            style={{
              backgroundColor: roomJoined || otsRoomStatus ? '#d32f2f' : '#1d615a',
              borderColor: roomJoined || otsRoomStatus ? '#d32f2f' : '#1d615a'
            }}
            onClick={() => { roomJoined || otsRoomStatus ? endClass() : startClass() }}
            loading={pending}
          >
            {roomJoined || otsRoomStatus ?
              <span style={{ marginTop: 3 }}>End Class</span> :
              <span style={{ marginTop: 3 }}>Start Class</span>
            }
          </Button>
        </div>
      </div>
      { openMessageModal && 
        <MessageModal 
          submitMessage={submitMessage}
          handleMessageModal={handleMessageModal} 
        />
      }
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (data) => dispatch(loading(data)),
    syncVideo: (data) => dispatch(syncVideo(data)),
    playVideo: (data) => dispatch(playVideo(data)),
    startClass: (data) => dispatch(startClass(data)),
    setExitRoom: (data) => dispatch(exitRoom(data)),
    enableSpeakAll: (data) => dispatch(enableSpeakAll(data)),
    muteAllStudents: (data) => dispatch(muteAllStudents(data)),
    speakToAllStatus: (data) => dispatch(speakToAllStatus(data)),
    muteUnmuteTeacherMic: (data) => dispatch(muteUnmuteTeacherMic(data)),
    muteUnmuteVideoPlayer: (data) => dispatch(muteUnmuteVideoPlayer(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    pending: state.pending,
    exitRoom: state.exitRoom,
    roomJoined: state.roomJoined,
    studentIds: state.studentIds,
    isSyncVideo: state.isSyncVideo,
    isPlayVideo: state.isPlayVideo,
    otsRoomData: state.otsRoomData,
    muteStudents: state.muteStudents,
    otsRoomStatus: state.otsRoomStatus,
    isVideoPLayed: state.isVideoPLayed,
    newParticipant: state.newParticipant,
    isTeacherMuted: state.isTeacherMuted,
    localParticipantSid: state.localParticipantSid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartClassFooter);
