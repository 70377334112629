import React, { useState, useRef, useLayoutEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, DatePicker, Form, TimePicker, Row, Col, notification } from "antd";
import moment from "moment";
import _ from "underscore";
import useWindowSize from "../../Hooks/windowSize";


const EditDateTimeModal = (props) => {
  const {
    handleChangeDateModal,
    data,
    pending,
    updateOtsDateTime,
    startTime,
    endTime,
  } = props;
  const [width, height] = useWindowSize();
  const tzCurrent = moment.tz.guess();

  const item = data;
  const [offset, setOffset] = useState("");
  const currentDate = offset ? moment().tz(offset) : moment();
  const [startTimeMoment, setStartTimeMoment] = useState(moment(startTime));
  const [endTimeMoment, setEndTimeMoment] = useState(moment(endTime));
  const selectedDateMomentRef = useRef(null);
  const [assignDate, setAssignDate] = useState(moment(startTime));
  const [editedDate, setEditedDate] = useState(startTime);
  const [editedStartTime, setEditedStartTime] = useState(moment(startTime));
  const [editedEndTime, setEditedEndTime] = useState(moment(endTime));
  let selectedHourEndTime = null;
  let selectedHourStartTime = null;
  let selectedDate = null;
  let currentDateNow = offset ? moment().tz(offset) : moment();
  const onOpenChangeStartTime = (isOpen) => {
    isOpen === false && (selectedHourStartTime = null);
  };

  const selectStartTime = (value) => {
    //Check selected date if its today then this is needed
    selectedHourStartTime = value;
    //setEditedStartTime(value);
  };
  const onOpenChangeEndTime = (isOpen) => {
    isOpen === false && (selectedHourEndTime = null);
    //setEditedEndDate(value);
  };

  const selectEndTime = (value) => {
    selectedHourEndTime = value;
    //setEditedEndDate(value);
  };

  const endTimeOnChange = (time) => {
    if (
      time &&
      time.isBefore(moment()) &&
      selectedDateMomentRef.current &&
      selectedDateMomentRef.current.format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
    ) {
      setEndTimeMoment(null);
      // form.setFieldsValue({
      //     endTIme: '',
      // });
      return;
    }
    if (startTimeMoment && time) {
      const newTime = moment(time).format("HH:mm");
      const newStartTime = moment(startTimeMoment).format("HH:mm");
      if (!moment(newTime, "HH:mm").isAfter(moment(newStartTime, "HH:mm"))) {
        // form.setFieldsValue({
        //     endTIme: '',
        // });
        return;
      } else {
        //console.log("else is same or before");
      }
    }
    setEndTimeMoment(time);
    // setEditedEndTime(time);
  };

  const startTimeOnChange = async (time) => {
    if (
      time &&
      time.isBefore(moment()) &&
      selectedDateMomentRef.current &&
      selectedDateMomentRef.current.format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
    ) {
      setStartTimeMoment(null);
      //   form.setFieldsValue({
      //     startTime: '',
      //   });
      return;
    }
    if (endTimeMoment && time) {
      const newTime = moment(time).format("HH:mm");
      const newEndTime = moment(endTimeMoment).format("HH:mm");
      if (!moment(newTime, "HH:mm").isBefore(moment(newEndTime, "HH:mm"))) {
        // form.setFieldsValue({
        //   startTime: '',
        // });
        return;
      }
    }
    setStartTimeMoment(time);
  };

  const getDisabledHoursEndTime = () => {
    if (
      (!selectedDateMomentRef.current ||
        selectedDateMomentRef.current.format("YYYY-MM-DD") !=
          currentDateNow.format("YYYY-MM-DD")) &&
      !startTimeMoment
    )
      return [];

    let disabledHoursStartTimeSpecific = [],
      disabledHoursSameDateSpecific = [];
    if (startTimeMoment) {
      let startingHours = startTimeMoment.format("HH");
      if (startingHours) {
        //+1 if same hour class 10:10 - 10:50
        disabledHoursStartTimeSpecific = _.range(Number(startingHours));
      }
    }

    if (
      selectedDateMomentRef.current &&
      selectedDateMomentRef.current.format("YYYY-MM-DD") ===
        currentDateNow.format("YYYY-MM-DD")
    ) {
      let currentHours = currentDateNow.format("HH");
      disabledHoursSameDateSpecific = _.range(currentHours);
    }
    return [
      ...new Set([
        ...disabledHoursSameDateSpecific,
        ...disabledHoursStartTimeSpecific,
      ]),
    ];
  };

  const getDisabledHoursStartTime = () => {
    if (
      (!selectedDateMomentRef.current ||
        selectedDateMomentRef.current.format("YYYY-MM-DD") !=
          currentDateNow.format("YYYY-MM-DD")) &&
      !endTimeMoment
    )
      return [];

    let disabledHoursEndingTimeSpecific = [],
      disabledHoursSameDateSpecific = [];
    if (endTimeMoment) {
      let endingHours = endTimeMoment.format("HH");
      if (endingHours) {
        //+1 if same hour class 10:10 - 10:50
        disabledHoursEndingTimeSpecific = _.range(Number(endingHours) + 1, 24);
      }
    }

    if (
      selectedDateMomentRef.current &&
      selectedDateMomentRef.current.format("YYYY-MM-DD") ===
        currentDateNow.format("YYYY-MM-DD")
    ) {
      let currentHours = currentDateNow.format("HH");
      disabledHoursSameDateSpecific = _.range(currentHours);
    }
    return [
      ...new Set([
        ...disabledHoursSameDateSpecific,
        ...disabledHoursEndingTimeSpecific,
      ]),
    ];
  };

  const getDisabledMinutesStartTime = () => {
    if (
      (!selectedDateMomentRef.current ||
        selectedDateMomentRef.current.format("YYYY-MM-DD") !=
          currentDateNow.format("YYYY-MM-DD") ||
        (selectedHourStartTime &&
          selectedHourStartTime.format("HH") != currentDateNow.format("HH"))) &&
      !endTimeMoment
    )
      return [];

    let disabledMinutesEndingTimeSpecific = [],
      disabledMinutesSameHourSpecific = [];

    if (
      endTimeMoment &&
      selectedHourStartTime &&
      selectedHourStartTime.format("HH") === endTimeMoment.format("HH")
    ) {
      let endingMinutes = endTimeMoment.format("mm");
      disabledMinutesEndingTimeSpecific = _.range(
        Number(endingMinutes) + 1,
        60
      );
    }
    if (
      selectedDateMomentRef.current &&
      selectedDateMomentRef.current.format("YYYY-MM-DD") ===
        currentDateNow.format("YYYY-MM-DD") &&
      selectedHourStartTime &&
      currentDateNow.format("HH") === selectedHourStartTime.format("HH")
    ) {
      let currentMinutes = currentDateNow.format("mm");
      disabledMinutesSameHourSpecific = _.range(Number(currentMinutes));
    }

    return [
      ...new Set([
        ...disabledMinutesEndingTimeSpecific,
        ...disabledMinutesSameHourSpecific,
      ]),
    ];
  };

  const getDisabledMinutesEndTime = () => {
    if (
      (!selectedDateMomentRef.current ||
        selectedDateMomentRef.current.format("YYYY-MM-DD") !=
          currentDateNow.format("YYYY-MM-DD")) &&
      !selectedHourStartTime &&
      !startTimeMoment
    )
      return [];

    let disabledMinutesStartingTimeSpecific = [],
      disabledMinutesSameHourSpecific = [];

    if (
      startTimeMoment &&
      selectedHourEndTime &&
      selectedHourEndTime.format("HH") === startTimeMoment.format("HH")
    ) {
      let startingMinutes = startTimeMoment.format("mm");
      disabledMinutesStartingTimeSpecific = _.range(
        Number(startingMinutes) + 1
      );
    }

    if (
      selectedDateMomentRef.current &&
      selectedDateMomentRef.current.format("YYYY-MM-DD") ===
        currentDateNow.format("YYYY-MM-DD") &&
      selectedHourEndTime &&
      currentDateNow.format("HH") === selectedHourEndTime.format("HH")
    ) {
      let currentMinutes = currentDateNow.format("mm");
      disabledMinutesSameHourSpecific = _.range(Number(currentMinutes));
    }

    return [
      ...new Set([
        ...disabledMinutesStartingTimeSpecific,
        ...disabledMinutesSameHourSpecific,
      ]),
    ];
  };

  const onClassDateChange = (current) => {
    selectedDateMomentRef.current = current;
    //Reset start Time and End time if current date
    selectedDate = current && {
      date: current.toDate().getDate(),
      month: current.toDate().getMonth(),
      year: current.toDate().getFullYear(),
    };
    setAssignDate(current);
  };

  const handleSubmitClick = () => {
    const time = moment(startTimeMoment).format("HH:mm");
    const eTime = moment(endTimeMoment).format("HH:mm");
    const date = moment(assignDate).format("YYYY-MM-DD");
    const timeZone = moment().format("Z");
    const startTime = `${date}T${time}:00${timeZone}`;
    const endTime = `${date}T${eTime}:00${timeZone}`;

    if (
      moment(startTime).isBefore(moment()) ||
      // assignDate.isSameOrBefore(moment()) ||
      moment(endTime).isBefore(moment(startTime))
    ) {
      notification.error({
        message: "Error!",
        description: "Please select the correct time",
      });
    } else {
      updateOtsDateTime(startTime, endTime);
    }
  };

  const wrapperStyles = {
    display: "flex",
    position: "absolute" /* Stay in place */,
    zIndex: 1 /* Sit on top */,
    left: 0,
    top: 0,
    width: "100vw" /* Full width */,
    height: "100vh" /* Full height */,
    overflow: "auto" /* Enable scroll if needed */,
    backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
  };
  const participantStyle = {
    position: "relative",
    width: width > 766 ? 608 : 344,
    height: width > 766 && width < 813 ? 500 : width > 814 ? 450 : 675,
    margin: "auto",
    borderRadius: 5,
    backgroundColor: "white",
  };

  return (
    <div
      onClick={() => {
        handleChangeDateModal();
      }}
      style={wrapperStyles}
    >
      <div
        className="modal"
        style={participantStyle}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span className="modal-heading">Propose new date and/or time</span>
        <span className="modal-description">
          We'll send a new date and/or time requeust to students. They may no
          longer be able to attend the class and will have an option to cancel.
        </span>
        <span className="modal-description" style={{ justifyContent: "unset" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="modal-description-heading">New date and time</span>
            <span className="modal-description-datetime">
              {moment(assignDate).format("dddd, DD MMM ")}at
              {moment(startTimeMoment).format(" hh:mm a")}
              {moment(endTimeMoment).format(" - hh:mm a")}
            </span>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
              >
                <DatePicker
                  style={{
                    height: 50,
                    border: "none",
                    width: "100%",
                    boxShadow: " 0 2px 10px 0 rgba(0, 0, 0, 0.05)",
                    border: "solid 1px #e8efee",
                  }}
                //   placeholder={"Select Date"}
                  inputReadOnly={true}
                  clearIcon={false}
                  defaultValue={assignDate}
                  className="ots-datetimepicker"
                  disabledDate={(current) => {
                    if (!item) {
                      if (
                        current.diff(currentDate, "minutes") >= 0 &&
                        current.diff(currentDate, "days") < 7
                      )
                        return false;
                      return true;
                    } else {
                      if (
                        current.diff(currentDate, "days") >= 0 &&
                        current.diff(currentDate, "days") < 6
                      )
                        return false;
                      return true;
                    }
                  }}
                  onChange={onClassDateChange}
                />
              </div>
              <Row className="start-end-time">
                <Col xs={24} md={12}>
                  <div className={"form-input-item"}>
                    <Form.Item
                      name="startTime"
                      className="start-end-time-input"
                      rules={[
                        {
                          required: true,
                          message: "Please add Start Time!",
                          type: "object",
                        },
                      ]}
                      // initialValue={startTimeMoment}
                    >
                      <TimePicker
                        style={{
                          height: 50,
                          boxShadow: " 0 2px 10px 0 rgba(0, 0, 0, 0.05)",
                          border: "solid 1px #e8efee",
                          width: "100%",
                        }}
                        placeholder={"Start Time"}
                        inputReadOnly={true}
                        onOpenChange={onOpenChangeStartTime}
                        onSelect={selectStartTime}
                        showNow={false}
                        className="ots-datetimepicker"
                        format="HH:mm"
                        clearIcon={false}
                        // defaultValue={startTimeMoment}
                        onChange={startTimeOnChange}
                        disabledHours={getDisabledHoursStartTime}
                        disabledMinutes={getDisabledMinutesStartTime}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className={"form-input-item"}>
                    <Form.Item
                      name="endTIme"
                      className="start-end-time-input"
                      rules={[
                        {
                          required: true,
                          message: "Please add an End Time!",
                          type: "object",
                        },
                      ]}
                      initialValue={endTimeMoment}
                    >
                      <TimePicker
                        style={{
                          height: 50,
                          border: "none",
                          width: "100%",
                          boxShadow: " 0 2px 10px 0 rgba(0, 0, 0, 0.05)",
                          border: "solid 1px #e8efee",
                        }}
                        placeholder={"End Time"}
                        inputReadOnly={true}
                        onOpenChange={onOpenChangeEndTime}
                        onSelect={selectEndTime}
                        showNow={false}
                        className="ots-datetimepicker"
                        // defaultValue={endTimeMoment}
                        format="HH:mm"
                        onChange={endTimeOnChange}
                        clearIcon={false}
                        disabledHours={getDisabledHoursEndTime}
                        disabledMinutes={getDisabledMinutesEndTime}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            
          </div>
        </span>
        <div className="modal-select">
          <Button
            className="modal-button"
            onClick={() => {
              handleChangeDateModal();
            }}
          >
            Close
          </Button>
          <Button
            className="modal-button-cancel"
            loading={pending}
            onClick={() => {
              handleSubmitClick();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pending: state.pending,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(EditDateTimeModal);
