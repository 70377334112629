import React, { useEffect, useState, useLayoutEffect } from "react";
import useDynamicRefs from "use-dynamic-refs";
import { Col, Row } from "antd";
import moment from "moment";

import StatusBar from "../SimpleClasses/statusBar";
import { ReactComponent as DirectCoachIcon } from "../../assets/img/icons/video_on.svg";
import useWindowSize from "../Hooks/windowSize";


const DateTimeline = (props) => {
    let timerRef = null;
    const [width, height] = useWindowSize();
    const [getRef, setRef] = useDynamicRefs();
    const { selectedDate, classesParsed } = props;
    const [marginBottom, setMarginBottom] = useState(0);

    const validateRefAvailabilty = () => {
        timerRef = setTimeout(() => {
            const classRef = getRef(selectedDate);
            if (!classRef || !classRef.current)
                validateRefAvailabilty();
            else {
                const timelineParentRef = getRef('calendar-scroll-classes-wrapper'); //getRef('timeline-parent')
                timelineParentRef.current.scroll({
                    top: classRef.current.offsetTop - timelineParentRef.current.offsetTop,
                    behavior: "smooth"
                });
                if (classRef && classRef.current) {
                    classRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
            }
        }, 300)
    }

    useEffect(() => {
        // Get ref for specific ID
        const classRef = getRef(selectedDate);
        const timelineParentRef = getRef('calendar-scroll-classes-wrapper');
        if (classRef && classRef.current) {
            timelineParentRef.current.scroll({
                top: classRef.current.offsetTop - timelineParentRef.current.offsetTop,
                behavior: "smooth"
            });
        } else {
            timerRef = setTimeout(() => {
                validateRefAvailabilty();
            }, 300)
            return () => clearTimeout(timerRef);
        }
    }, [selectedDate]);

    useEffect(() => {
        let parentRef = getRef('calendar-scroll-classes-wrapper');
        if (parentRef && parentRef.current) {
            let height = parentRef.current.offsetHeight - 150;
            setMarginBottom(height)
        }
    }, [getRef('calendar-scroll-classes-wrapper'), width]);

    let classedDates = Object.keys(classesParsed);
    classedDates = classedDates.sort((a, b) => b - a)

    let currentDateHeading = null;

    let getMonthHeader = (date) => {
        let dateMoment = moment(date, 'YYYYMMDD');
        let stringName = dateMoment.format('MMMM YYYY')
        let monthReturned = null;
        if (currentDateHeading !== stringName) {
            currentDateHeading = stringName;
            monthReturned = currentDateHeading;
        }
        return (
            <>
                {monthReturned ? (<div className="calendar-scroll-classes-card-header">{currentDateHeading}</div>) : null}
                <div className="calendar-scroll-classes-card-header-date">
                    {
                        <div
                            ref={setRef(moment(dateMoment).format('DD-MM-YYYY'))}
                        >
                            {dateMoment.format("DD MMM YYYY")}
                        </div>
                    }
                </div>
            </>
        )
    }

    return (
        <div
            ref={setRef("calendar-scroll-classes-wrapper")}
            className="calendar-scroll-classes"
            style={{ overflowY: 'auto' }}
        >
            {
                classedDates.map((val, dateIndex) => (
                    <>
                        {getMonthHeader(classedDates[dateIndex])}
                        {
                            classesParsed[val].map((value, parsedIndex) => (

                                <Row
                                    className="calendar-scroll-classes-card"
                                    style={{
                                        marginTop: 10,
                                        marginBottom:
                                            classedDates.length - 1 === dateIndex &&
                                                classesParsed[val].length - 1 === parsedIndex ? marginBottom : 12
                                    }}
                                >
                                    <Col
                                        span={24}
                                        style={{ backgroundColor: "#fff", borderRadius: 8 }}
                                    >

                                        <div className="calendar-scroll-classes-card-content">
                                            <div className="calendar-scroll-classes-card-content-title">
                                                {value.content}
                                            </div>
                                            <div className="calendar-scroll-classes-card-content-description">

                                                {value.classData.category === " " || value.classData.category === "" ? `${parseInt((value.classData.videoDuration ?? 0) / 60)} min` : `${value.classData.category} · ${parseInt((value.classData.videoDuration ?? 0) / 60)} min`}
                                            </div>
                                            <div>
                                                {value.classData.category === "Direct Coach" ? (
                                                    <div style={{ display: "flex" }}>
                                                        <div className="direct-coach-icon">
                                                            <DirectCoachIcon style={{}} />
                                                        </div>
                                                        <span
                                                            style={{
                                                                fontFamily: "Gilroy",
                                                                fontSize: 16,
                                                                color: "#262626",
                                                            }}
                                                        >
                                                            9:00 am - 9:30 am
                                                        </span>
                                                    </div>
                                                ) : (
                                                    < span className="calendar-scroll-classes-card-content-status">
                                                        {value.classData.status ?
                                                            <StatusBar
                                                                status={value.classData.status}
                                                                calendar={true}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </span>

                                                )}
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            )
                            )
                        }
                    </>
                )
                )
            }
        </div>
    );
};

export default DateTimeline;
