import React from "react";


const DirectCoachHeading = (props) => {
    const {text} = props
    return ( 
        <div className="direct-coach-heading-component">
           {text}
        </div>
     );
}
 
export default DirectCoachHeading;