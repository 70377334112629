import React from 'react';
import { ReactComponent as MinusIcon } from "../../../assets/img/icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/img/icons/plus.svg";


const data = [
    {
        val: 4
    },
    {
        val: 8
    },
    {
        val: 12
    },
    {
        val: 15
    }
]
const DirectCoachStudent = (props) => {
    const { handleStudentCount, studentCount } = props;

    const handleAdditionIcon = () => {
        if (studentCount < 15) {
            handleStudentCount("", "plus")
        }
    }

    const handleSubtractionIcon = () => {
        if (studentCount !== 0) {
            handleStudentCount("", "minus")
        }
    }


    return (
        <div className='direct-coach-student'>
            <div className='direct-coach-student-count'>
                <MinusIcon className={studentCount === 0 ? 'direct-coach-student-count-minus-disabled' : 'direct-coach-student-count-minus'} onClick={() => { handleSubtractionIcon() }} />
                <span className='direct-coach-student-count-span'>{studentCount}</span>
                <PlusIcon className={studentCount === 15 ? 'direct-coach-student-count-minus-disabled' : 'direct-coach-student-count-plus'} onClick={() => { handleAdditionIcon() }} />
            </div>
            <div className='direct-coach-student-values'>
                {data.map((val, key) => {
                    return (
                        <div className={`direct-coach-student-values-square ${studentCount === val.val ? "selected" : ""}`} onClick={() => { handleStudentCount(val.val) }}
                            style={{ marginRight: key === 3 ? 16 : null }}
                        >
                            <span className='direct-coach-student-values-square-span'>{val.val}</span>
                        </div>
                    )
                })
                }
            </div>
        </div>
    );
}

export default DirectCoachStudent;