import React, { useState } from 'react';
import { Input, Col, Form } from 'antd';
import useWindowSize from '../../Hooks/windowSize';

const { Search } = Input;

const DemoDirectCoach = (props) => {

  const [width, height] = useWindowSize();
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const onChangeFirstEmail = (e) => {
    props.handleFirstEmail(e.target.value);
  };

  const onChangeSecondEmail = (e) => {
    props.handleSecondEmail(e.target.value);
  };

  const onChangeThirdEmail = (e) => {
    props.handleThirdEmail(e.target.value);
  };

  const onChangeFourthEmail = (e) => {
    props.handleFourthEmail(e.target.value);
  };

  return (
    <div className='demo-direct-coach'>

      <Col>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="email1"
            className='demo-direct-coach-form'
            rules={[
              {
                required: true,
                message: "Please input a valid email!",
                type: "email",
              },
            ]}
            style={{ marginTop: width > 767 && width < 897 ? 40 : "" }}
          >
            <Input
              type="email"
              className='demo-direct-coach-input'
              onChange={onChangeFirstEmail}
              placeholder="Enter Email Address 1"
            />
          </Form.Item>
          <Form.Item
            name="email2"
            className='demo-direct-coach-form'
            rules={[
              {
                required: true,
                message: "Please input a valid email!",
                type: "email",
              },
            ]}
          >
            <Input
              type="email"
              className='demo-direct-coach-input'
              onChange={onChangeSecondEmail}
              placeholder="Enter Email Address 2"
            />
          </Form.Item>
          <Form.Item
            name="email3"
            className='demo-direct-coach-form'
            rules={[
              {
                required: true,
                message: "Please input a valid email!",
                type: "email",
              },
            ]}
          >
            <Input
              type="email"
              className='demo-direct-coach-input'
              onChange={onChangeThirdEmail}
              placeholder="Enter Email Address 3"
            />
          </Form.Item>
          <Form.Item
            name="email4"
            className='demo-direct-coach-form'
            rules={[
              {
                required: true,
                message: "Please input a valid email!",
                type: "email",
              },
            ]}
          >
            <Input
              type="email"
              className='demo-direct-coach-input'
              onChange={onChangeFourthEmail}
              placeholder="Enter Email Address 4"
            />
          </Form.Item>

        </Form>
      </Col>
    </div>
  );

}

export default DemoDirectCoach;