import React, { useEffect, useRef, useState } from 'react';
import { Button } from "antd";
import moment from 'moment';

import TotalEarnings from './totalEarnings';
import VerticalProgress from '../../components/Audience/verticalProgressbar';
import { ReactComponent as BackArrow } from '../../assets/img/icons/arrow-back.svg';
import { ReactComponent as ForwardArrow } from '../../assets/img/icons/arrow-next.svg';

const EarningsGraph = (props) => {
    const { earningsByTime, totalEarnings, name } = props;
    const { weekly, monthly } = earningsByTime;
    const ref = useRef(null);
    const maxScrollLeftRef = useRef(0);
    const [activeBar, setActiveBar] = useState(0);
    const [monthlyGraph, setMonthlyGraph] = useState(false);
    const [earningsData, setEarningsData] = useState(
        {
            start: weekly.length != 0 ? weekly[0].start : 0,
            end: weekly.length != 0 ? weekly[0].end : 0,
            total: weekly.length != 0 ? weekly[0].earnings.total : 0,
            classes: weekly.length != 0 ? weekly[0].earnings.classes : 0,
            referrals: weekly.length != 0 ? weekly[0].earnings.referrals : 0,
        }
    );

    var scrollX = 0;

    const [monthlyData, setMonthlyData] = useState([]);
    const [leftScrollValue, setLeftScrollValue] = useState(0);

    useEffect(() => {
        if (Object.keys(monthly).length && !monthlyData.length) {
            Object.keys(monthly).map((key) => {
                monthlyData.unshift({
                    key: key,
                    value: monthly[key]
                })
            });
        }
        setMonthlyData(monthlyData);
        ref.current.addEventListener("scroll", (event) => {
            if (ref.current.scrollLeft < scrollX - 100 ||
                ref.current.scrollLeft === 0 ||
                ref.current.scrollLeft < - maxScrollLeftRef.current) {
                scrollX = ref.current.scrollLeft;
                setLeftScrollValue(ref.current.scrollLeft);
            }
        });
        maxScrollLeftRef.current = - (ref.current.scrollWidth - ref.current.clientWidth - 10);
    }, []);



    const toggleGraphViewWeekly = () => {

        setEarningsData(
            {
                start: weekly.length != 0 ? weekly[0].start : 0,
                end: weekly.length != 0 ? weekly[0].end : 0,
                total: weekly.length != 0 ? weekly[0].earnings.total : 0,
                classes: weekly.length != 0 ? weekly[0].earnings.classes : 0,
                referrals: weekly.length != 0 ? weekly[0].earnings.referrals : 0,
            }
        );

        setMonthlyGraph(false);
        setActiveBar(0);
    }

    const toggleGraphViewMonthly = () => {
        setEarningsData(
            {
                start: monthlyData.length ? monthlyData[0].key : 0,
                end: '',
                total: monthlyData.length ? monthlyData[0].value.total : 0,
                classes: monthlyData.length ? monthlyData[0].value.classes : 0,
                referrals: monthlyData.length ? monthlyData[0].value.referrals : 0,
            }
        );
        setMonthlyGraph(true);
        setActiveBar(0);
    }

    const onBarChange = (index, value) => {
        const earningData = {
            start: monthlyGraph ? value.key : value.start,
            end: monthlyGraph ? '' : value.end,
            total: monthlyGraph ? value.value.total : value.earnings.total,
            classes: monthlyGraph ? value.value.classes : value.earnings.classes,
            referrals: monthlyGraph ? value.value.referrals : value.earnings.referrals,
        }
        if (monthlyGraph) {
            setEarningsData(earningData);
        } else {
            setEarningsData(earningData);
        }
        setActiveBar(index);
    }

    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    return (
        <div className="earnings-graph">
            <div className="earning-graph-buttons">
                <div className="view-button">
                    <Button
                        style={{
                            color: monthlyGraph ? "#262626" : "#ffffff",
                            fontSize: 16,
                            backgroundColor: monthlyGraph ? "transparent" : "#1d615a",
                            borderColor: monthlyGraph ? "transparent" : "#1d615a",
                            marginRight: 10
                        }}
                        type="primary"
                        htmlType="submit"
                        className="view-button-inside"
                        onClick={() => { toggleGraphViewWeekly() }}
                    >
                        Weekly
                    </Button>
                </div>
                <div className="view-button" style={{ marginLeft: 9 }}>
                    <Button
                        style={{
                            color: monthlyGraph ? "#ffffff" : "#262626",
                            fontSize: 16,
                            backgroundColor: monthlyGraph ? "#1d615a" : "transparent",
                            borderColor: monthlyGraph ? "#1d615a" : "transparent"
                        }}
                        type="primary"
                        htmlType="submit"
                        className="view-button-inside"
                        onClick={() => { toggleGraphViewMonthly() }}
                    >
                        Monthly
                    </Button>
                </div>
            </div>
            <div className="earnig-graph-wrapper" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div
                    //className="earning-graph-arrow"
                    style={{
                        marginTop: "auto",
                        marginBottom: 'auto',
                        cursor: 'pointer',
                    }}
                >
                    {monthlyGraph ? (
                        <BackArrow className={leftScrollValue >= maxScrollLeftRef.current &&
                            Object.keys(monthly).length > 10 ? "back-arrow" : "back-arrow-disabled"} onClick={() => scroll(-390)} />
                    ) : (
                        <BackArrow className={leftScrollValue >= maxScrollLeftRef.current &&
                            weekly.length > 10 ? "back-arrow" : "back-arrow-disabled"} onClick={() => scroll(-390)} />
                    )

                    }
                </div>

                <div
                    className='earning-graph'
                    style={{
                        display: "flex",
                        flexDirection: 'row-reverse',
                        justifyContent: monthlyGraph &&
                            Object.keys(monthly).length < 10 || weekly.length === 0 ? 'center' : 'normal',
                        overflow: 'auto',
                        scrollBehavior: 'smooth',
                        height: 259,
                        width: "85%"
                        //changeHere
                    }}
                    ref={ref}
                >
                    {monthlyGraph ? (
                        <>

                            {monthlyData.length ? (monthlyData.map((key, index) => (
                                <div className="earnings-weekly-graph" style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    flex: '0 0 auto'
                                }}
                                    onClick={() => { onBarChange(index, key) }}
                                >
                                    <div>
                                        <VerticalProgress
                                            progress={Math.ceil(key.value.total / totalEarnings * 100) === 0 ? 0 :
                                                Math.ceil(key.value.total / totalEarnings * 100) + 10}
                                            isLastBar={
                                                index === activeBar ? "#1d615a" : "#d2dfde"
                                            }
                                            earningGraph={true}
                                            verticalBar={true}
                                            horizontalBar={false}
                                        />
                                        <div>
                                            <span className="earning-graph-label"
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    color: index === activeBar ?
                                                        "#1d615a"
                                                        : "#d2dfde"
                                                }}
                                            >
                                                {index === 0 ? "This Month" : moment(key.key).format("MMM")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))) : (
                                <span class='perfomance-item-title'
                                    style={{ justifyContent: 'center', color: '#929292' }}>
                                    Waiting for data
                                </span>
                            )
                            }

                        </>
                    ) : (
                        <>
                            {weekly.length ? (weekly.map((val, index) => (

                                <div
                                    className="earnings-weekly-graph"
                                    style={{
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        flex: '0 0 auto'
                                    }}

                                    onClick={() => { onBarChange(index, val) }}
                                >
                                    <div>
                                        <VerticalProgress
                                            progress={Math.ceil(val.earnings.total / totalEarnings * 100) === 0 ? 0 :
                                                Math.ceil(val.earnings.total / totalEarnings * 100) + 10}
                                            isLastBar={index === activeBar ? "#1d615a" : "#d2dfde"}
                                            earningGraph={true}
                                            verticalBar={true}
                                            horizontalBar={false}
                                        />
                                        <div>
                                            <span className="earning-graph-label"
                                                style={{
                                                    color:
                                                        index === activeBar ?
                                                            "#1d615a"
                                                            : "#d2dfde"
                                                }}
                                            >
                                                {
                                                    index === 0 ? 'This week' : moment(val.start).format("DD MMM")
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))) : (
                                <span class='perfomance-item-title'
                                    style={{ justifyContent: 'center', color: '#929292' }}>
                                    Waiting for data
                                </span>
                            )
                            }
                        </>
                    )}
                </div>
                <div
                    style={{
                        marginTop: "auto",
                        marginBottom: 'auto',
                        cursor: 'pointer'
                    }}
                >
                    <ForwardArrow className={leftScrollValue < 0 ? "back-arrow" : "back-arrow-disabled"} onClick={() => scroll(+390)} />
                </div>
            </div>
            <div style={{ marginTop: weekly.length || Object.keys(monthlyData).length ? 72 : 0 }}>
                <TotalEarnings
                    totalEarnings={earningsData}
                    monthlyGraph={monthlyGraph}
                    activeBar={activeBar}
                />
            </div>
        </div>
    );
};

export default EarningsGraph;