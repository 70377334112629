import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { Row, Col, Input } from 'antd';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/search.svg'
import { getAllSimpleClasses } from "../../../actions/actions";
import LoadingAnimation from '../../Loader/LoadingAnimation';
import ThumbnailImage from '../../../assets/img/no-thumbnail-image.jpg';



const { Search } = Input;

const prefix = (
    <SearchIcon style={{ margin: '0px 10px' }} />
)

const DirectClassList = (props) => {
    const { loginData, simpleClasses, handleClassData, classData } = props;
    const data = simpleClasses.published;
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const data = {
            token: loginData ? loginData.access : null,
            teacherId: loginData ? loginData.userID : null,
        };
        !simpleClasses.published && props.getAllSimpleClasses(data);
    }, []);


    return (
        <div className="dc-class-home">
            <div className="dc-class-home-input">
                <Search
                    onChange={(event) => {
                        setSearchTerm(event.target.value)
                    }}
                    placeholder='Search classes'
                    prefix={prefix}
                />
            </div>
            <div className="dc-class-home-card-container">
                {data && data.length <= 0 ?
                    <div className="dc-class-home-no-class">
                        <span className="dc-class-home-no-class-span">Waiting for data</span>
                    </div>
                    :
                    data ? data && data.filter((val) => {
                        if (searchTerm === "") {
                            return val
                        }
                        else if (val.class.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return val
                        }


                    }).map((val, key) => {
                        return (
                            <div className={`dc-class-home-card ${classData === val ? 'selected' : ''}`} onClick={() => { handleClassData(val) }}>
                                <Row
                                    className="dc-class-home-card-row">
                                    <Col className="dc-class-home-card-col">
                                        <div className="dc-class-home-card-col-wrapper">
                                            <div>
                                                <img alt=" "
                                                    style={{ height: 63, width: 116, borderRadius: '8px 0px 0px 8px' }}
                                                    src={val.class.thumbNailURL ? val.class.thumbNailURL : ThumbnailImage}
                                                />
                                            </div>
                                            <div className="dc-class-home-card-col-info">
                                                <div className='dc-class-home-card-col-title'>{val.class.name}</div>
                                                <div className='dc-class-home-card-col-description'>
                                                    {val ? val.class.category === " " || val.class.category === "" ? `${parseInt((val.class.videoDuration ?? 0) / 60)} min` : `${val.class.category} · ${parseInt((val.class.videoDuration ?? 0) / 60)} min` : null}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        );
                    })
                        :
                        <LoadingAnimation />
                }
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSimpleClasses: (data) => dispatch(getAllSimpleClasses(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        loginData: state.loginData,
        simpleClasses: state.simpleClasses
    };
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(DirectClassList);