import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from 'moment';
import { Tabs } from 'antd';

import Title from "../../components/Title/Title";
import CoachingCard from "../../components/Coaching/coachingCard";
import NothingScreen from "../../components/Coaching/nothingScreen";
import { changeHeader, getAllOtsClasses, setSelectedClass, createOTSRoom, changeOTSClassStatus } from "../../actions/actions";
import LoadingAnimation from '../../components/Loader/LoadingAnimation';
import EditCoachClass from "../../components/Coaching/EditCoaching/editCoachClass";

const { TabPane } = Tabs;

const Coaching = (props) => {

    const { otsClasses, loginData, showLoader, handleCollapsable, selectedClass, otsClassStatus } = props

    useEffect(() => {
        const data = {
            token: loginData ? loginData.access : null,
            teacherId: loginData ? loginData.userID : null,
        };
        !otsClasses.length && props.getAllOtsClasses(data);
    }, [otsClasses]);

    useEffect(() => {
        if (otsClassStatus) {
            props.changeOTSClassStatus(false);
        }
    }, []);

    let classesParsedOTSExisting = [],
        classesParsedOTSNew = [],
        classesParsedOTSPast = [],
        classesParsedOTSUpcoming = [];
    let activeOTSClasses = otsClasses ?? [];
    activeOTSClasses.map(item => {
        let time = moment(item.OTS.endDateTime);
        const key = time.format('YYYYMMDD');

        if (time.isBefore(moment()) || item.OTS.status === "canceled") {
            if (!classesParsedOTSExisting.hasOwnProperty(`${key}`)) {
                //if key doesnot exist
                classesParsedOTSPast.push(key);
                classesParsedOTSExisting[`${key}`] = [
                    { classData: item }
                ];
            }
            else {
                //if key already exist
                classesParsedOTSExisting[`${key}`].push({ classData: item })
                classesParsedOTSExisting[`${key}`] = classesParsedOTSExisting[`${key}`]
                    .sort(function (a, b) {
                        a = new Date(a.classData.OTS.startDateTime);
                        b = new Date(b.classData.OTS.startDateTime);
                        return b > a ? -1 : b < a ? 1 : 0;
                    })
            }
        } else {
            if (!classesParsedOTSNew.hasOwnProperty(`${key}`)) {
                //if key doesnot exist
                classesParsedOTSUpcoming.push(key);
                classesParsedOTSNew[`${key}`] = [
                    { classData: item }
                ];
            }
            else {
                //if key already exist
                classesParsedOTSNew[`${key}`].push({ classData: item });
                classesParsedOTSNew[`${key}`] = classesParsedOTSNew[`${key}`]
                    .sort(function (a, b) {
                        a = new Date(a.classData.OTS.startDateTime);
                        b = new Date(b.classData.OTS.startDateTime);
                        return b > a ? -1 : b < a ? 1 : 0;
                    })
            }
        }
    });

    classesParsedOTSUpcoming = classesParsedOTSUpcoming.sort((a, b) => a - b);
    classesParsedOTSPast = classesParsedOTSPast.sort((a, b) => b - a);


    const handleChange = (value) => {
        props.setSelectedClass(value);
    };

    const handleClassChange = (value) => {
        props.setSelectedClass(value);
        props.changeHeader(false);
        handleCollapsable(false);
    };

    return (
        <>
            {selectedClass ? (
                <EditCoachClass selectedClass={selectedClass} handleClassChange={handleClassChange} />
            ) : (
                <div className="main-page-container">
                    <Title text={'Coaching'} index={1} />

                    {!showLoader ?

                        <div className="coaching-tab">
                            <Tabs defaultActiveKey="1" >
                                <TabPane tab="UPCOMING" key="1">
                                    {
                                        Object.keys(classesParsedOTSNew).length ?
                                            <CoachingCard
                                                handleChange={handleChange}
                                                OTSData={classesParsedOTSNew}
                                                sortedDates={classesParsedOTSUpcoming}
                                                handleCollapsable={handleCollapsable}
                                                loginData={loginData} />
                                            :
                                            <NothingScreen />
                                    }
                                </TabPane>
                                <TabPane tab="PAST" key="2">
                                    {
                                        Object.keys(classesParsedOTSExisting).length ?
                                            <CoachingCard
                                                handleChange={handleChange}
                                                OTSData={classesParsedOTSExisting}
                                                sortedDates={classesParsedOTSPast}
                                                handleCollapsable={handleCollapsable}
                                            />
                                            :
                                            <NothingScreen />
                                    }
                                </TabPane>
                            </Tabs>
                        </div>

                        :
                        <LoadingAnimation />
                    }
                </div>
            )}
        </>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllOtsClasses: (data) => dispatch(getAllOtsClasses(data)),
        changeHeader: (data) => dispatch(changeHeader(data)),
        setSelectedClass: (data) => dispatch(setSelectedClass(data)),
        createOTSRoom: (data) => dispatch(createOTSRoom(data)),
        changeOTSClassStatus: (data) => dispatch(changeOTSClassStatus(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        loginData: state.loginData,
        otsClasses: state.otsClasses,
        showLoader: state.showLoader,
        selectedClass: state.selectedClass,
        otsClassStatus: state.otsClassStatus,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Coaching);