import axios from "axios";
import { API_ENDPOINT, API_METRICS_ENDPOINT, API_UPLOAD_ENDPOINT } from "../constants";
import { withRouter } from "react-router-dom";
import { store } from "../store";
import * as Actions from "../actions/actionTypes";

export const getData = async (roomName, userName) => {
  try {
    return fetch(
      `${API_ENDPOINT}/v1/api/webrtc/room/${roomName}/access?identity=${userName}`
    ).then((response) => {
      return response;
    });
  } catch (err) {
    return {
      error: true,
      errorDetails: {
        content: "Invalid Room. Please join an existing room",
        className: "custom-class",
      },
    };
  }
};
const request = axios.create();
request.interceptors.response.use(res => {
  const state = store.getState();
  if (res.status === 401) {
    if (state.rememberMe === false) {
    } else {
      const originalReq = res.config;
      request.get(
        `${API_ENDPOINT}/v1/api/user/refresh?refresh=${state.loginData.refresh}`
      )
        .then((res) => {
          store.dispatch({ type: Actions.REFRESH_TOKEN, payload: res.data });
          originalReq.headers['Authorization'] = `Bearer ${res.data.refresh}`;
          return request(originalReq);
        })
        .catch((error) => {
          return { error };
        });
    }
  }
  return res
}, err => {
  if (err.response.status === 401) {
    store.dispatch({ type: Actions.REDIRECT_TO_LOGIN, payload: true });
  }
})

const apis = {
  postLoginData: (payload) => {
    return request
      .post(
        `${API_ENDPOINT}/v1/api/user/login`,
        {
          email: payload.email,
          password: payload.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          data: res ? res.data : null,
        };
      })
      .catch((error) => {
        return { statusCode: error.response.status };
      });
  },

  createClass: (payload) => {
    return request
      .get(`${API_ENDPOINT}/v1/api/webrtc/room/${payload.room}/create`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${payload.token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to create class!" };
      });
  },

  endClass: (payload) => {
    return request
      .get(`${API_ENDPOINT}/v1/api/webrtc/room/${payload.room}/end`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${payload.token}`,
        },
      })
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to end class!" };
      });
  },

  changeRules: (payload) => {
    return request
      .post(
        `${API_ENDPOINT}/v1/api/webrtc/room/${payload.roomId}/participant/${payload.studentId}/rules`,
        [
          {
            type: payload.type,
            publisher: payload.teacherId,
          },
        ],
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to change rules!" };
      });
  },

  updateRules: (payload) => {
    const studentId = payload.payload.payload;
    const roomId = payload.roomData.roomId;
    const localParticipantId = payload.roomData.roomLocalParticipantId;
    const type = "include";
    return request
      .post(
        `${API_ENDPOINT}/v1/api/webrtc/room/${roomId}/participant/${studentId}/rules`,
        [
          {
            type: type,
            publisher: localParticipantId,
          },
        ],
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to update rules!" };
      });
  },

  speakAll: (payload) => {
    let allStudents = [];
    allStudents = payload.participants.map((element) => {
      return {
        participant: element,
        rules: [
          {
            type: payload.type,
            publisher: payload.teacherId,
            // all: true,
          },
        ],
      };
    });
    return request
      .post(
        `${API_ENDPOINT}/v1/api/webrtc/room/${payload.roomId}/participant/rules`,
        allStudents,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to change rules!" };
      });
  },

  allOTSClasses: (payload) => {
    return request
      .get(
        `${API_ENDPOINT}/v1/api/ots/${payload.teacherId}/TeacherID?bookings=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data.reverse(),
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  getOtsClass: (payload) => {
    return request
      .get(
        `${API_ENDPOINT}/v1/api/ots/${payload.otsId}/ID`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  fetchTeacherDetails: (payload) => {
    return request
      .get(
        `${API_ENDPOINT}/v1/api/teacher/${payload.teacherId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  allClasses: (payload) => {
    return request
      .get(
        `${API_ENDPOINT}/v1/api/class?field=teacherID&fieldvalue=${payload.teacherId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  createOTSClass: (payload) => {
    return request
      .post(
        `${API_ENDPOINT}/v1/api/ots/${payload.endpoint}`,
        {
          classID: payload.classID,
          startDateTime: payload.startDateTime,
          duration: payload.duration,
          timeZone: `${payload.timeZone}`,
          maxParticipants: payload.maxParticipants,
          IsWaitlistAvailable: payload.IsWaitlistAvailable,
          currency: payload.currency,
          price: payload.price,
          email: payload.email
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  updateOTSClass: (payload) => {
    return request
      .post(
        `${API_ENDPOINT}/v1/api/ots/${payload.payloadToken.classID}`,
        payload.payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.payloadToken.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  createOTSRoom: (payload) => {
    return request
      .get(
        `${API_ENDPOINT}/v1/api/ots/${payload.otsId}/createroom`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  endOTSRoom: (payload) => {
    return request
      .get(
        `${API_ENDPOINT}/v1/api/ots/${payload.otsId}/endroom`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  updateTeacherPassword: (payload) => {
    return request
      .post(
        `${API_ENDPOINT}/v1/api/users/current/updatepassword `,
        {
          old_password: payload.currentPassword,
          password: payload.newPassword,
          confirm_password: payload.newPasswordConfirm,
          userID: payload.teacherId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  teacherProfileBioUpdate: (payload) => {
    return request
      .post(
        `${API_ENDPOINT}/v1/api/teacher/${payload.teacherID}/bio `,
        {
          name: payload.name,
          firstName: payload.firstName,
          lastName: payload.lastName,
          bio: payload.bio,
          imageURL: payload.imageURL

        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  teacherProfileUpdate: (payload) => {
    return request
      .post(
        `${API_ENDPOINT}/v1/api/teacher/${payload.teacherID} `,
        {
          name: payload.name,
          firstName: payload.firstName,
          lastName: payload.lastName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },


  teacherImageUpdate: async (payload) => {
    let formData = new FormData()

    if (payload.Imagee == "heroImage") {
      formData.append("file", payload.heroImage)
    }
    else if (payload.Imagee == "headShotImage") {
      formData.append("file", payload.headShotImage)

    }
    else if (payload.Imagee == "image43Image") {
      formData.append("file", payload.image43)
    }

    const result = await request

      .post(
        `${API_UPLOAD_ENDPOINT}/v1/api/media/uploadimage?field=${payload.field}&clientId=${payload.teacherID} `,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };

      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
    //result Call next  API here
    return request.post(
      `${API_ENDPOINT}/v1/api/teacher/${payload.teacherID}/bio `,
      // headers: {
      //   Accept: "application/json",
      //   Authorization: `Bearer ${loginInfo.access}`,
      // },
      //body: JSON.stringify({ [field]: resjson.link }),
      {
        [`${payload.field}`]: `${result.data.link}`
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${payload.token}`,
        },
      }
    )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      })
  },

  teacherPerformance: (payload) => {
    return request
      .get(
        `${API_METRICS_ENDPOINT}/data/views/teacher/${payload.teacherId}/performance`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  getAllSimpleClasses: (payload) => {
    return request
      .get(
        `${API_METRICS_ENDPOINT}/data/views/teacher/${payload.teacherId}/classes`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },



  teacherEarnings: (payload) => {
    return request
      .get(
        `${API_METRICS_ENDPOINT}/data/views/teacher/${payload.teacherId}/earnings`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  cancelOTSClass: (payload) => {
    return request
      .get(
        `${API_ENDPOINT}/v1/api/ots/${payload.otsId}/cancel`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

  getOtsClassVideoUrl: (payload) => {
    return request
      .get(
        `${API_ENDPOINT}/v1/api/class/${payload.classId}/video`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${payload.token}`,
          },
        }
      )
      .then((res) => {
        return {
          data: res.data,
        };
      })
      .catch((error) => {
        return { errorMessage: "Unable to fetch data!" };
      });
  },

};

export default withRouter(apis);
