import React from 'react';
import { Card } from 'antd';
import moment from 'moment';
import ThumbnailImage from '../../../assets/img/no-thumbnail-image.jpg';

const DirectCoachConfrim = (props) => {
    const { costCount, studentCount, classData, concatenate, startTimeValue } = props;

    return (
        <div className='direct-coach-confirm'>
            <div className='direct-coach-confirm-card'>
                <Card
                    className='direct-coach-confirm-card-component'
                    cover={
                        <img src={classData.class.thumbNailURL ? classData.class.thumbNailURL : ThumbnailImage} height='216px' width='395px' alt=" "
                            style={{ borderRadius: 8, objectFit: 'cover', }}
                        />}
                >
                </Card>
                <div className='direct-coach-confirm-card-overlay' />
                <div className='direct-coach-confirm-card-desc'>
                    <span className='direct-coach-confirm-card-desc-span-title'>{classData ? classData.class.name : null}</span>
                    <span className='direct-coach-confirm-card-desc-span-desc' style={{ marginTop: 6 }}>
                        {classData ? classData.class.category === " " || classData.class.category === "" ? `${parseInt((classData.class.videoDuration ?? 0) / 60)} min` : `${classData.class.category} · ${parseInt((classData.class.videoDuration ?? 0) / 60)} min` : null}
                    </span>
                </div>
            </div>
            <div className='direct-coach-confirm-detail'>
                <div className='direct-coach-confirm-detail-datetime'>
                    <span className='direct-coach-confirm-detail-datetime-span'>{startTimeValue.format("dddd, D MMMM YYYY [at] h:mm a")}</span>
                </div>
                <div className='direct-coach-confirm-detail-divider' />
                <div className='direct-coach-confirm-detail-wrapper'>
                    <span className='direct-coach-confirm-detail-wrapper-span'>Students</span>
                    <span className='direct-coach-confirm-detail-wrapper-span'>Upto {studentCount}</span>
                </div>
                <div className='direct-coach-confirm-detail-divider' />
                <div className='direct-coach-confirm-detail-wrapper'>
                    <span className='direct-coach-confirm-detail-wrapper-span'>Cost per student</span>
                    <span className='direct-coach-confirm-detail-wrapper-span'>${costCount}</span>
                </div>
            </div>

        </div>
    );
}

export default DirectCoachConfrim;