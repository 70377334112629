import React, { useEffect, useState } from "react";
import { Card } from "antd";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { changeHeader, createOTSRoom, getOTSClassVideo } from "../../actions/actions";
import ThumbnailImage from "../../assets/img/no-thumbnail-image.jpg";
import { ReactComponent as ParticipantIcon } from "../../assets/img/icons/participants.svg";
import Countdown from "react-countdown";

const CoachingCard = (props) => {
    const { sortedDates, OTSData, handleChange, handleCollapsable, } = props;
    const [redirect, setRedirect] = useState(false)
    const [classData, setClassData] = useState(null)

    const handleCoachCardClick = (value) => {
        handleChange(value);
        handleCollapsable(true);
        props.changeHeader(true);
    }

    let todayDate = moment().format('DD MMMM YYYY')
    let currentDateTitle = null
    let getCoachDate = (dateVal) => {
        let dateMoment = moment(dateVal, 'YYYYMMDD')
        let stringDate = dateMoment.format('DD MMMM YYYY')
        let titleReturn = null

        if (currentDateTitle !== stringDate) {
            currentDateTitle = stringDate;
            titleReturn = currentDateTitle;
        }

        return (
            <>
                {todayDate === currentDateTitle ?
                    <>
                        {titleReturn ?
                            <div className="coaching-card-date">
                                Today
                            </div>
                            : null
                        }
                    </>
                    :
                    <>
                        {titleReturn ?
                            <div className="coaching-card-date">
                                {moment(currentDateTitle).format('dddd, D MMM')}
                            </div>
                            : null
                        }
                    </>
                }
            </>
        )
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>{` `}</span>;
        } else {
            // Render a countdown
            return <span>{` 0${hours}`}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>;
        }
    }

    const handleStartClick = (val) => {
        setClassData(val)
        setRedirect(true)
    }


    return (
        <>
            {redirect ?
                <Redirect
                    to={{
                        pathname: "/testHomeScreen",
                        state: { data: classData }
                    }}
                />

                :
                <div className="coaching-card-container">
                    {
                        sortedDates.map((dateVal, dateIndex) => (
                            <>
                                {getCoachDate(sortedDates[dateIndex])}
                                <div className="flex-wrapper-coaching" style={{}}>
                                    {
                                        OTSData[dateVal].map((val) => (

                                            <div className="flex-wrapper-coaching-item-50 coaching-card">
                                                <div className="coaching-card-time">
                                                    {moment(val.classData.OTS.startDateTime).format('hh:mm a ')}
                                                    {moment(val.classData.OTS.endDateTime).format('- hh:mm a')}
                                                </div>

                                                <div className="" >
                                                    <div className="coaching-card-image" onClick={() => { handleCoachCardClick(val) }}>
                                                        <Card
                                                            className="coaching-card-component"
                                                            cover={
                                                                <img
                                                                    alt="coaching-card-image"
                                                                    src={
                                                                        val.classData.OTS.thumbNailURL === "" ? ThumbnailImage : val.classData.OTS.thumbNailURL
                                                                    }
                                                                    height="216px"
                                                                    width="386"
                                                                    style={{
                                                                        objectFit: "cover", borderRadius: ((moment(moment().add(1, 'hours')).isSameOrAfter(moment(val.classData.OTS.startDateTime))) &&
                                                                            (moment(moment()).isSameOrBefore(moment(val.classData.OTS.endDateTime)))) &&
                                                                            val.classData.OTS.status != "canceled"
                                                                            ? "8px 8px 0px 0px" : 8
                                                                    }}
                                                                />

                                                            }

                                                        >
                                                        </Card>
                                                        <div className="card-cover-overlay-coaching"
                                                            style={{
                                                                borderRadius: ((moment(moment().add(1, 'hours')).isSameOrAfter(moment(val.classData.OTS.startDateTime))) &&
                                                                    (moment(moment()).isSameOrBefore(moment(val.classData.OTS.endDateTime)))) &&
                                                                    val.classData.OTS.status != "canceled"
                                                                    ? "8px 8px 0px 0px" : 8
                                                            }}
                                                        >
                                                        </div>
                                                        <div className="coaching-card-image-upper">
                                                            <div className="coaching-card-image-upper-title">
                                                                {val.classData.OTS.name}
                                                            </div>
                                                            <div className="coaching-card-image-upper-category">
                                                                {val.classData.OTS.category === " " || val.classData.OTS.category === "" ? `${parseInt((val.classData.OTS.videoDuration ?? 0) / 60)} min` : `${val.classData.OTS.category} · ${parseInt((val.classData.OTS.videoDuration ?? 0) / 60)} min`}
                                                            </div>
                                                        </div>
                                                        <div className="coaching-card-image-lower" style={{
                                                            margin: ((moment(moment().add(1, 'hours')).isSameOrAfter(moment(val.classData.OTS.startDateTime))) &&
                                                                (moment(moment()).isSameOrBefore(moment(val.classData.OTS.endDateTime)))) &&
                                                                val.classData.OTS.status != "canceled"
                                                                ? "0px 0px 29px 24px" : "0px 0px 26px 24px"
                                                        }}>
                                                            <ParticipantIcon className="participant-icon" />
                                                            <span className="coaching-card-image-lower-span">
                                                                {val.classData.Booked}/{val.classData.Max}
                                                            </span>
                                                            <span className="coaching-card-image-lower-span-waitlist">
                                                                {
                                                                    val.classData.Waitlist != 0 ?
                                                                        `(+ ${val.classData.Waitlist} waitlist)`
                                                                        :
                                                                        null
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='coaching-card-timmer'
                                                    >
                                                        {
                                                            ((moment(moment().add(1, 'hours')).isSameOrAfter(moment(val.classData.OTS.startDateTime))) &&
                                                                (moment(moment()).isSameOrBefore(moment(val.classData.OTS.endDateTime)))) &&
                                                                val.classData.OTS.status != "canceled"
                                                                ?

                                                                (moment(moment().add(5, 'minutes')).isSameOrAfter(moment(val.classData.OTS.startDateTime))) &&
                                                                    (moment(moment()).isSameOrBefore(moment(val.classData.OTS.endDateTime)))
                                                                    ?
                                                                    <div
                                                                        style={{
                                                                            // marginLeft: 15,
                                                                            // boxShadow: "none"
                                                                        }}
                                                                        type="primary"
                                                                        htmlType="submit"
                                                                        className="coaching-card-component-timmer"
                                                                        style={{ borderRadius: "0px 0px 8px 8px" }}
                                                                        onClick={() =>
                                                                            //startOtsClass(val.classData.OTS)
                                                                            handleStartClick(val)
                                                                            //setRedirect(true)
                                                                        }
                                                                    >
                                                                        <span className="coaching-card-component-timmer-span" style={{ borderRadius: "0px 0px 8px 8px" }}>Start the Class</span>
                                                                    </div>
                                                                    :

                                                                    <div className="coaching-card-component-timmer" style={{ borderRadius: "0px 0px 8px 8px" }}

                                                                    >
                                                                        <span className="coaching-card-component-timmer-span">
                                                                            Start the class
                                                                            <Countdown
                                                                                // precision={3}
                                                                                key={val.classData.OTS.id}
                                                                                date={moment(val.classData.OTS.startDateTime).valueOf()}
                                                                                renderer={renderer}
                                                                                zeroPadTime={2}
                                                                            ></Countdown>
                                                                        </span>
                                                                    </div>

                                                                :
                                                                null
                                                        }
                                                    </div>

                                                </div>

                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        )
                        )
                    }
                </div>
            }
        </>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeHeader: (data) => dispatch(changeHeader(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        // otsRoomStatus: state.otsRoomStatus,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CoachingCard);