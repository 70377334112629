import React from "react";
import { withRouter } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import ImageCarousel from "../../assets/img/otshome.png";

const HomeCarousel = () => {
  return (
    <div>
      <Carousel className="ots-carousel" showThumbs={false} showStatus={false} autoPlay={false} infiniteLoop={true}>
        <div>
          <img src={ImageCarousel} />
          <div className="legend">
            <div style={{ display: "grid" }}>
              <span className="legend-heading-one">Online Education</span>
              <span className="legend-heading-two">for Everyone</span>
            </div>
          </div>
        </div>
        <div>
          <img src={ImageCarousel} />
          <div className="legend">
            <div style={{ display: "grid" }}>
              <span className="legend-heading-one">Online Education</span>
              <span className="legend-heading-two">for Everyone</span>
            </div>
          </div>
        </div>
        <div>
          <img src={ImageCarousel} />
          <div className="legend">
            <div style={{ display: "grid" }}>
              <span className="legend-heading-one">Online Education</span>
              <span className="legend-heading-two">for Everyone</span>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default withRouter(HomeCarousel);
