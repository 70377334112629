import React, { useEffect, useState } from "react";
import "./App.css";
import PubNub from "pubnub";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import { persistStore } from 'redux-persist';
import { PubNubProvider } from "pubnub-react";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { store } from "./store";
import { persistor } from "./store";
import * as Actions from "./actions/actionTypes";
import LoadingAnimation from "./components/Loader/LoadingAnimation";
import Routes from './routes/routes'

const pubnub = new PubNub({
  publishKey: "pub-c-301d685e-e18c-4523-8cb7-ac3492fcb472",
  subscribeKey: "sub-c-04e54efc-a402-11eb-86bf-e27ecfa4e4f1",
  uuid: "web",
});


const App = () => {
  const [rehydrated, setRehydrated] = useState(false);
  const getStore = store.getState();

  useEffect(() => {
    persistStore(store, {}, () => {
      const localStore = store.getState();
      const currentTime = new Date().getTime() / 1000;
      const decodedJwt = localStore.loginData ? jwt_decode(localStore.loginData.access) : 0;
      if (!localStore.loginData || currentTime > decodedJwt.exp) {
        store.dispatch({ type: Actions.REDIRECT_TO_LOGIN, payload: true });
      }
      setRehydrated(true);
    })
  }, []);

  return (
    <>
      {!rehydrated ? (
        <LoadingAnimation />
      ) : (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <PubNubProvider client={pubnub}>
              <Routes />
            </PubNubProvider>
          </PersistGate>
        </Provider>
      )}
    </>
  );
}

export default App;
