import React, { useEffect, useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Layout, Menu } from 'antd';
import { compose } from "redux";

import Circle from './Circle'
import Logo from "../../assets/img/logo.png";
import { changeHeader, setSelectedClass } from '../../actions/actions';
import { ReactComponent as Logosmall } from '../../assets/img/icons/bml-logomark-green.svg';


const { Sider } = Layout;

const SideNav = (props) => {
    const { collapsed, indexCount, collapsable, indexVal, showHeader } = props;
    const options = ["Home", "Coaching", "Classes", "Earnings", "Performance", "Feedback", "Resources", "Calendar"]


    const [key, setColor] = useState(3) //set to zero to set home default
    const handleIndexChange = (key) => {
        if (key === 7) {
            collapsable(true);
            if (showHeader) {
                props.changeHeader(false);
                props.setSelectedClass(false);
            }
        }
        else if (key === 1) {
            if (showHeader) {
                props.changeHeader(false);
                collapsable(false);
                props.setSelectedClass(false);
            }
        }
        else {
            if (showHeader) {
                props.changeHeader(false);
                props.setSelectedClass(false);
            }
        }
        setColor(key)
        indexVal(key)
    }

    useEffect(() => {
        if (props.tokenExpire) {
            props.history.push("/");
        }
    }, [props.tokenExpire]);

    return (
        <Sider
            width={262}
            collapsedWidth={72}
            className="sider"
            trigger={null} collapsible collapsed={collapsed}
        >
            <Link to="/" >
                {collapsed ?

                    <div className="logo-small">
                        <Logosmall />
                    </div> :
                    <div className="logo">
                        <img
                            className="logo-image"
                            width="auto"
                            height="auto"
                            src={Logo}
                            alt=""
                        />
                    </div>
                }

            </Link>
            <Menu className="sideNavBar" mode="inline" >
                {
                    options.map((val, index) => {
                        return (
                            <Menu.Item style={{ marginTop: 20 }}
                                className={collapsed ? 'menu-item collapsed' : 'menu-item'} key={`${index}`} disabled={index === 0 || index === 5 || index === 6 ? true : false}
                                style={{ color: index === 0 ? '#A8A8A8' : null }}
                                icon={<Circle color={index === indexCount ? '#1d615a' : '#FFFFFF'}
                                    borderColor={index === 0 || index === 5 || index === 6 ? '#A8A8A8' : '#1d615a'} />}
                                onClick={() => { handleIndexChange(index) }}>
                                {!collapsed ?
                                    <span className="sideNavli" style={{ color: index === indexCount ? '#1d615a' : index === 0 || index === 5 || index === 6 ? '#A8A8A8' : '#262626' }}
                                    >
                                        {val}</span> : null
                                }
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        </Sider>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeHeader: (data) => dispatch(changeHeader(data)),
        setSelectedClass: (data) => dispatch(setSelectedClass(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        tokenExpire: state.tokenExpire,
        showHeader: state.showHeader,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SideNav);
