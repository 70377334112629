import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { notification } from 'antd';
import { connect } from "react-redux";
import Logo from '../../assets/img/bml-logomark-white.svg';
import DirectCoachHeading from '../../components/Coaching/CreateDirectCoaching/directCoachHeading';
import DirectClassList from '../../components/Coaching/CreateDirectCoaching/directClassList';
import DirectCoachProgressBar from '../../components/Coaching/CreateDirectCoaching/directCoachProgressBar';
import DirectCoachBottomBar from '../../components/Coaching/CreateDirectCoaching/directCoachBottomBar';
import DemoDirectCoach from '../../components/Coaching/CreateDirectCoaching/demoDirectCoach';
import { createOTSCLass } from "../../actions/actions";

const CreateDemoDirectCoachClass = (props) => {
    const { loginData, otsClassStatus } = props;
    const [stepNumber, setStepNumber] = useState(0);
    const [progressVal, setProgressVal] = useState(50);
    const [classData, setClassData] = useState(undefined);
    const [buttonEnable, setButtonEnable] = useState(true);
    const [firstEmail, setFirstEmail] = useState(null);
    const [secondEmail, setSecondEmail] = useState(null);
    const [thirdEmail, setThirdEmail] = useState(null);
    const [fourthEmail, setFourthEmail] = useState(null);

    const handleClassData = (val) => {
        setClassData(val)
    }


    const handleEmailValidationCheck = (data) => {
        return new Promise((resolve, reject) => {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (data.length > 0) {
                data.map((val) => {
                    if (!regEmail.test(val)) {
                        reject(true)
                    }
                })
                resolve(true)
            }
            reject(true)

        })
    }

    const handleSubmitClick = async () => {

        const collectData = [firstEmail, secondEmail, thirdEmail, fourthEmail];
        let data = [];

        collectData.map((value) => {
            if (value !== null && value !== "") {
                data.push(value);
            }
        });

        await handleEmailValidationCheck(data).then((val) => {
            if (val) {
                const formData = {
                    classID: classData.class.id,
                    email: data,
                    token: loginData.access,
                    endpoint: 'demo',
                };
                props.createOTSCLass(formData);
            }

        }).catch(() => {
            notification['error']({
                message: 'Error',
                description:
                    'Enter valid email address.',
            });
        })
    }

    const handleProgressNextVal = () => {

        if (progressVal !== 100 && stepNumber < 2) {
            setProgressVal(progressVal + 50);
            setStepNumber(stepNumber + 1);
        }
    }

    const handleProgressPrevVal = () => {
        if (progressVal !== 50 && stepNumber !== 0) {
            setProgressVal(progressVal - 50)
            setStepNumber(stepNumber - 1)
        }
    }

    useEffect(() => {
        if (classData) {
            setButtonEnable(false)
        }
        else {
            setButtonEnable(true)
        }

    }, [classData]);

    const handleFirstEmail = (val) => {
        setFirstEmail(val)
    }

    const handleSecondEmail = (val) => {
        setSecondEmail(val)
    }

    const handleThirdEmail = (val) => {
        setThirdEmail(val)
    }

    const handleFourthEmail = (val) => {
        setFourthEmail(val)
    }

    useEffect(() => {
        if (otsClassStatus === true) {
            props.history.push("/main");
        }
    }, [otsClassStatus]);

    return (
        <div className="create-direct-coach">
            <div className="create-direct-coach-left">
                <div className="create-direct-coach-left-logo">
                    <Link to="/"
                    >
                        <div
                            className="create-direct-coach-logo"
                        >
                            <img
                                className=""
                                width="38px"
                                height="38px"
                                src={Logo}
                                alt="direct-coach-logo"
                            />
                        </div>
                    </Link>
                </div>
                {
                    stepNumber === 0 ?
                        <div
                            className="create-direct-coach-left-heading"
                        >
                            <DirectCoachHeading
                                text={"Which class would you like to Demonstrate as Direct Coach?"} />

                        </div>
                        :
                        <div
                            className="create-direct-coach-left-heading"
                        >
                            <DirectCoachHeading
                                text={"Enter student email addresses"} />

                        </div>
                }
            </div>
            <div className="create-direct-coach-right">
                {
                    stepNumber === 0 ?
                        <DirectClassList
                            handleClassData={(value) => { handleClassData(value) }}
                            classData={classData}
                        />
                        :
                        <DemoDirectCoach
                            handleFirstEmail={handleFirstEmail}
                            handleSecondEmail={handleSecondEmail}
                            handleThirdEmail={handleThirdEmail}
                            handleFourthEmail={handleFourthEmail}
                        />
                }
                <div className="create-direct-coach-right-bottom" style={{}}>
                    <DirectCoachProgressBar progressVal={progressVal} />
                    <DirectCoachBottomBar handleProgressNextVal={handleProgressNextVal}
                        handleProgressPrevVal={handleProgressPrevVal}
                        stepNumber={stepNumber}
                        handleSubmitClick={handleSubmitClick}
                        buttonEnable={buttonEnable}
                        demo={true}
                    />
                </div>
            </div>
        </div>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        createOTSCLass: (data) => dispatch(createOTSCLass(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        loginData: state.loginData,
        otsClassStatus: state.otsClassStatus,
    };
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CreateDemoDirectCoachClass);