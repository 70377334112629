import React, { useState, useEffect } from 'react';
import { ReactComponent as MinusIcon } from "../../../assets/img/icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/img/icons/plus.svg";
import useWindowSize from "../../Hooks/windowSize";

const data = [
    {
        val: 5
    },
    {
        val: 10
    },
    {
        val: 15
    },
    {
        val: 20
    },
    {
        val: 25
    },
    {
        val: 50
    }
]

const DirectCoachCost = (props) => {
    const { studentCount, handleStudentCost, costCount } = props;
    const [total, setTotal] = useState(0)
    const [percentage, setPercentage] = useState(0)
    const [width, height] = useWindowSize()

    let calcTotal = 0;
    let calcPercentage = 0;

    const handleAdditionIcon = () => {
        if (costCount < 100) {
            handleStudentCost('', 'plus')
        }
    }

    const handleSubtractionIcon = () => {
        if (costCount !== 0) {
            handleStudentCost('', 'minus')
        }
    }

    const handleValueClick = (val) => {
        handleStudentCost(val, '')
        setTotal(val * 10)
    }

    useEffect(() => {
        calcTotal = costCount * studentCount;
        calcPercentage = ((calcTotal) * 20) / 100
        calcTotal = calcTotal - calcPercentage
        setTotal(calcTotal)
        setPercentage(calcPercentage)
    }, [costCount]);

    return (
        <div className='direct-coach-cost' >
            <div className='direct-coach-student-count'
             style={{marginTop:width > 767 && width < 897 ? 40 : ""}}
            >
                <MinusIcon className={costCount === 0 ? 'direct-coach-student-count-minus-disabled' : 'direct-coach-student-count-minus'} onClick={() => { handleSubtractionIcon() }} />
                <span className='direct-coach-student-count-span'>${costCount.toFixed(2)}</span>
                <PlusIcon className={costCount === 100 ? 'direct-coach-student-count-plus-disabled' : 'direct-coach-student-count-plus'} onClick={() => { handleAdditionIcon() }} />
            </div>
            <div className='direct-coach-student-values'>
                {data.map((val, key) => {
                    return (
                        <div className={`direct-coach-student-values-square ${key === 2 || key === 5 ? 'square-small' : ''} ${costCount === val.val ? "selected" : ""}`} onClick={() => { handleValueClick(val.val) }}
                            style={{ marginRight: key === 5 ? 16 : null }}
                        >
                            <span className='direct-coach-student-values-square-span'>$ {val.val}</span>
                        </div>
                    )
                })
                }
            </div>
            {width < 676 ?
                <div className='direct-coach-cost-divider'></div>
                :
                null
            }
            <div className='direct-coach-cost-earnings'>
                <span className='direct-coach-cost-earnings-title'>Estimated earnings</span>
                <div className='direct-coach-cost-earnings-wrapper'>
                    <span className='direct-coach-cost-earnings-wrapper-span'>Students</span>
                    <span className='direct-coach-cost-earnings-wrapper-span'>{studentCount}</span>
                </div>
                <div className='direct-coach-cost-earnings-wrapper'>
                    <span className='direct-coach-cost-earnings-wrapper-span'>Cost</span>
                    <span className='direct-coach-cost-earnings-wrapper-span'>${costCount}</span>
                </div>
                <div className='direct-coach-cost-earnings-wrapper' style={{ marginBottom: width < 676 ? 24 : null }}>
                    <span className='direct-coach-cost-earnings-wrapper-span'>-20%</span>
                    <span className='direct-coach-cost-earnings-wrapper-span'>${percentage}</span>
                </div>
                <div className='direct-coach-cost-earnings-wrapper' >
                    <span className='direct-coach-cost-earnings-wrapper-span' style={{fontWeight: width < 676 ? 600 : null, 
                        marginTop: width < 676 ? null : 21 }}>Total</span>
                    <span className='direct-coach-cost-earnings-wrapper-span' style={{fontWeight: width < 676 ? 600 : null,
                    marginTop: width < 676 ? null : 21}}>${total}</span>
                </div>
            </div>
        </div>
    );
}

export default DirectCoachCost;