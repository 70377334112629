import React, { useState } from "react";
import { Button } from "antd";

import useWindowSize from "../Hooks/windowSize";


const MessageModal = (props) => {
    
    const [width] = useWindowSize();
    const { handleMessageModal, submitMessage } = props;
    const [messageContent, setMessageContent] = useState('');

    const handleMessageInput = (e) => {
        setMessageContent(e.target.value);
    };

    const onSubmit = () => {
        submitMessage(messageContent);
        setMessageContent('');
    };

    const wrapperStyles = {
        display: 'flex',
        position: 'absolute', /* Stay in place */
        zIndex: 1, /* Sit on top */
        left: 0,
        top: 0,
        width: '100vw', /* Full width */
        height: width > 766 ? 'calc(100vh - 71px)' : 'calc(100vh - 141px)', /* Full height */
        overflow: 'auto', /* Enable scroll if needed */
        backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
    }
    const participantStyle = {
        position: "relative",
        width: width > 766 ? 308 : 300,
        height: width > 766 ? 293 : 273,
        marginLeft: width > 766 ? '45%' : 'auto',
        marginTop: 'auto',
        marginRight: 'auto',
        borderRadius: 5,
        backgroundColor: "white"
    };


    return (
        <div onClick={() => { handleMessageModal() }} style={wrapperStyles} >
            <div className='modal' style={participantStyle} onClick={(e) => { e.stopPropagation(); }}>
                <span className='message-modal-heading' >
                    Message
                </span>
                <div className='message-textarea'>
                    <textarea className='message-container' placeholder="Send message to students" value={messageContent} onChange={handleMessageInput} >Some text...</textarea>
                </div>
                <div className='message-modal-button'>
                    <Button
                        style={{ width: '100%' }}
                        className="message-modal-button-send"
                        onClick={() => onSubmit()}
                    >
                        Send
                    </Button>
                </div>
            </div>
        </div>
    );
}


export default MessageModal;