import React from "react";


const SimpleClassesTitle = (props) => {

    const { title, count } = props

    return (
        <div className="classes-tab-heading-main">
            <span className='classes-tab-heading'>{title} ({count})</span>
        </div>
    );
}

export default SimpleClassesTitle;