import React from "react";
import { Link } from "react-router-dom";

const NothingScreen = () => {
    return (
        <div className="nothing-screen">
            <div className="nothing-screen-heading">
                Nothing here yet
            </div>
            <div className="nothing-screen-description">
                Direct Coach any of your existing classes and provide live feedback to students at an additional cost. <span className="nothing-screen-description-span" style={{}}>Learn more</span>
            </div>
            <div className="nothing-screen-button">
            <Link to="/createDirectCoach">
                <span className="nothing-screen-button-span">Coach a class</span>
                </Link>
            </div>
        </div>
    );
}

export default NothingScreen;