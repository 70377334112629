import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import SideNav from "../SideNav/SideNav"
import Header from "../Header/header"
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';

import Coaching from '../../pages/Coaching';
import Home from '../../pages/HomeNew/index';
import BottomNav from '../BottomNav/bottomNav';
import Classes from '../../pages/Classes/index';
import OTSClasses from "../../pages/OTSClasses";
import Earnings from '../../pages/Earning/index';
import CalendarView from '../../pages/Calendar/index';
import Performance from '../../pages/Performance/index';
import { redirectToMainRoute, setRoomJoined, setNavIndex, setSelectedClass } from '../../actions/actions';


const Content = Layout;

const Main = (props) => {

  const { showHeader, otsRoomStatus, navIndex, selectedClass, roomJoined } = props;
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    //redirect back to main screen after class ending
    if(props.match.path === '/main' && otsRoomStatus){
      props.redirectToMainRoute(false);
      props.history.push("/main");
    }
    if(roomJoined){
      props.setRoomJoined(false);
    }
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    props.history.push("/main");
  }

  useEffect(() => {
    //handling back button browser functionality so that it remains on main page
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const handleIndex = (indexValue) => {
    props.setNavIndex(indexValue);
    if (selectedClass) {
      props.setSelectedClass(false);
    }
  }

  const handleCollapsable = (val) => {
    setCollapsed(val)
  }
  let ComponentRender = navIndex
  switch (ComponentRender) {
    case 0:
      ComponentRender = <OTSClasses />;
      break;
    case 1:
      //ForCoaching
      ComponentRender = <Coaching handleCollapsable={handleCollapsable} />;
      // <OTSClasses />;
      break;
    case 2:
      ComponentRender = <Classes />;
      break;
    case 3:
      ComponentRender = <Earnings />;
      break;
    case 4:
      ComponentRender = <Performance />;
      break;
    case 5:
      //ComponentRender = <Classes />;
      //ForFeedback
      break;
    case 6:
      //ComponentRender = <Classes />;
      //ForResources
      break;
    case 7:
      ComponentRender = <CalendarView />;
      break;
    default:
      ComponentRender = <Performance />;
    // <Home />
  }

  return (
    <Layout style={{
      height: '100vh',
    }}>
      <SideNav collapsed={collapsed} indexCount={navIndex} indexVal={handleIndex} collapsable={handleCollapsable} />
      <Layout className="site-layout">

        {!showHeader &&
          <Header className="site-layout-background" style={{ padding: 0 }} toggleCollapseState={() => {
            setCollapsed(!collapsed)
          }}>

          </Header>
        }
        <Content
          className="site-layout-background"
          style={{
            minHeight: 280,
            backgroundColor: "white"
          }}
        >
          {ComponentRender}
        </Content>
      </Layout>
      <BottomNav indexCount={navIndex} indexVal={handleIndex} />
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRoomJoined: (data) => dispatch(setRoomJoined(data)),
    setNavIndex: (data) => dispatch(setNavIndex(data)),
    setSelectedClass: (data) => dispatch(setSelectedClass(data)),
    redirectToMainRoute: (data) => dispatch(redirectToMainRoute(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    navIndex: state.navIndex,
    showHeader: state.showHeader,
    roomJoined: state.roomJoined,
    otsRoomStatus: state.otsRoomStatus,
    selectedClass: state.selectedClass,
    isSideNavCollapse: state.isSideNavCollapse,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Main);
