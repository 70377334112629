import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Box, Grid, Card, Container, Typography, Button, Modal, Paper, Tooltip, IconButton, CardActions, CardContent, TextField, LinearProgress, CircularProgress } from "@material-ui/core"
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate"
import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles"
import EditIcon from "@material-ui/icons/Edit"
import { fetchProfileDetails, updateTeacherPassword, teacherProfileBioUpdate, teacherProfileUpdate, teacherProfileImageUpdate } from "../../actions/actions"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert" 

const useStyles = makeStyles(_ => ({
	root: {},
	editProfileModal: {
		margin: "10vh",
		outline: "none",
		backgroundColor: "white",
		overflow: "scroll",
	},
}))


function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

const EditProfileModal = props => {


	const { editingt, stopEditing, teacherProfileDetails, loginData, teacherImageUpdate } = props;
	console.log('props here', props)
	const [editState, setEditState] = useState({
		name: "",
		firstName: "",
		lastName: "",
		bio: "",
		imageURL: "",
		headShotURL: "",
		Image43URL: "",
		heroImage: undefined,
		headShotImage: undefined,
		image43Image: undefined,
		token: "",
		teacherID: "",

		// probably a better way to do this but some bools so we can tell whats been changed -
		// to only send in request the fields to be updated
		nameC: false,
		firstNameC: false,
		lastNameC: false,
		bioC: false,
		imageURLC: false,
		headShotImageC: false,
		image43ImageC: false,
	})
	// const data = {
	// 	token: loginData ? loginData.access : null,
	// 	teacherId: loginData ? loginData.userID : null,
	// };

	const [editing, setEditing] = useState(false)

	const [change, setChange] = useState(false)
	// const [heroImage, setHeroImage] = useState()
	// const [heroImageUploadStatus, setHeroImageUploadStatus] = useState(false)

	React.useEffect(
		() => {
			if (teacherProfileDetails.Bio) {
				setEditState({
					name: teacherProfileDetails.Teacher.name,
					firstName: teacherProfileDetails.Teacher.firstName,
					lastName: teacherProfileDetails.Teacher.lastName,
					bio: teacherProfileDetails.Bio.bio,
					imageURL: teacherProfileDetails.Bio.imageURL,
					token: loginData.access,
					teacherID: loginData.userID,
				})
			}
			setEditing(false)
		},
		[teacherProfileDetails]
	)

	const handleChange = e => {
		// if file check if it's allowed
		if (e.target.files) {
			var file = e.target.files[0]
			var imgType = e.target.name

			console.log("new image")
			console.log(`got image\ntarget.result: ${e.target.result}`)
			var reader = new FileReader()
			//Read the contents of Image File.
			reader.readAsDataURL(e.target.files[0])
			reader.onload = function (e) {
				//Initiate the JavaScript Image object.
				var image = new Image()

				//Set the Base64 string return from FileReader as source.
				image.src = e.target.result
				console.log(`check: ${e.target.files}`)

				//Validate the File Height and Width.
				image.onload = function () {
					var height = this.height
					var width = this.width
					console.log(`width: ${width}\nheight: ${height}`)

					// checks are dependant on what image is intended for
					// hero image width 1920, 1080 // 16:9
					// 43 image width 600, height 450 4:3
					// headshot image 200x200 square
					var widthCheck
					var ratioCheck
					var ratioPretty
					var tolerance = 0.01 // 1% tolerance
					if (imgType === "heroImage") {
						widthCheck = 1920
						ratioCheck = 16 / 9
						ratioPretty = "16:9"
					} else if (imgType === "headShotImage") {
						widthCheck = 600
						ratioCheck = 4 / 3
						ratioPretty = "4:3"
					} else if (imgType === "image43") {
						widthCheck = 200
						ratioCheck = 1
						ratioPretty = "Square"
					} else {
						alert(`error! ${imgType}`)
					}

					// check dimensions
					// within tolerance or greater than 1920 for width, within toelrance for ratio
					if (width > widthCheck - tolerance * widthCheck && Math.abs(width / height - ratioCheck) < ratioCheck * tolerance) {
						console.log("new image good")
						setChange(true)
						setEditState({
							...editState,
							[imgType]: file,
							[imgType + "C"]: true,
						})
						return true
					} else {
						// props.setSnackbar({ message: `image not allowed; ${imgType}s must be at least ${widthCheck} wide and be ${ratioPretty}`, open: true, severity: "error", backgroundColor: "red" })
						alert(`image not allowed; ${imgType}s must be at least ${widthCheck} wide and be ${ratioPretty}`)
						return false
					}
				}
			}
		} else {
			setChange(true)
			setEditState({
				...editState,
				[e.target.name]: e.target.value,
				[e.target.name + "C"]: editState[e.target.name] !== e.target.value,
			})
		}
	}

	const handleSaveClick = () => {
		setEditing(true)
		setChange(false)
		console.log('props', props)
		if (editState.heroImage) {
			//can call image upload function here
			setEditState({
				imageURL: editState.heroImage
			})
			props.teacherProfileImageUpdate(editState, 'imageURL', 'heroImage');

		}
		else if (editState.headShotImage) {
			setEditState({
				headShotURL: editState.headShotImage
			})
			props.teacherProfileImageUpdate(editState, 'headShotURL', 'headShotImage')

		}
		else if (editState.image43) {
			setEditState({
				image43URL: editState.image43Image
			})
			props.teacherProfileImageUpdate(editState, 'image43URL', 'image43Image')

		}
		//for bio update
		props.teacherProfileBioUpdate(editState)
		//for name and lastname
		props.teacherProfileUpdate(editState)

	}

	const handleCancelClick = () => {
		// setEditState({
		// 	// name: props.accountData.Teacher.name,
		// 	// firstName: props.accountData.Teacher.firstName,
		// 	// lastName: props.accountData.Teacher.lastName,
		// 	// bio: props.accountData.Bio.bio,
		// })
		stopEditing()
	}

	const classes = useStyles()

	return (
		<Modal disableEnforceFocus={true} disableAutoFocus={true} open={editingt} onClose={stopEditing} className={classes.editProfileModal}>
			<Card>
				<CardContent>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="h6">Edit Profile Information</Typography>
						</Grid>
						<Grid item xs={12} style={{ paddingTop: "2vh" }}>
							<Typography>Bio</Typography>
							<Divider />
						</Grid>

						<Grid item xs={12} style={{ padding: "2vh" }}>
							<TextField name="name" value={editState.name} onChange={handleChange} helperText="This is the name your students will see" variant="outlined" fullWidth placeholder="Display Name" />
						</Grid>
						<Grid item xs={12} style={{ padding: "2vh" }}>
							<TextField name="firstName" value={editState.firstName} onChange={handleChange} helperText="Your legally given first name" variant="outlined" fullWidth placeholder="First Name" />
						</Grid>
						<Grid item xs={12} style={{ padding: "2vh" }}>
							<TextField name="lastName" value={editState.lastName} onChange={handleChange} helperText="Your legally given last name" variant="outlined" fullWidth placeholder="Last Name" />
						</Grid>
						<Grid item xs={12} style={{ padding: "2vh" }}>
							<TextField name="bio" value={editState.bio} onChange={handleChange} multiline helperText="Some information about you for your students" variant="outlined" fullWidth placeholder="Bio" />
						</Grid>

						<Grid item xs={12}>
							<Typography>Hero Image</Typography>
							<Divider />
							<div style={{ padding: "2vh" }}>
								{editState.heroImage && (
									<Typography>{editState.heroImage.name}</Typography> // if uploading new show name otherwise show current hero image
								)}
								{!editState.heroImage && (
									<img
										src={teacherProfileDetails.Bio ?
											teacherProfileDetails.Bio.imageURL : null // if uploading new show name otherwise show current hero image
										}
										style={{ width: "50vh" }}
										alt="failed to load"
									/>
								)}

								<div style={{ display: "flex" }}>
									<div style={{ float: "left", margin: "2vh" }}>
										<Typography>Upload a different hero image</Typography>
									</div>

									<div>
										<Tooltip title="Upload hero image">
											<label htmlFor="upload-hero-image" style={{ cursor: "pointer" }}>
												<AddPhotoAlternateIcon fontSize="large" color="primary" />
											</label>
										</Tooltip>
										<input id="upload-hero-image" type="file" name="heroImage" onChange={handleChange} accept="image/*" style={{ opacity: 0 }} />
									</div>
								</div>
							</div>
						</Grid>

						<Grid item xs={12}>
							<Typography>Headshot Image</Typography>
							<Divider />
							<div style={{ padding: "2vh" }}>
								{editState.headShotImage && (
									<Typography>{editState.headShotImage.name}</Typography> // if uploading new show name otherwise show current hero image
								)}
								{!editState.headShotImage && (
									<img
										src={teacherProfileDetails.Bio ?
											teacherProfileDetails.Bio.headShotURL : null // if uploading new show name otherwise show current hero image
										}
										style={{ width: "50vh" }}
										alt="headshot failed to load"
									/>
								)}

								<div style={{ display: "flex" }}>
									<div style={{ float: "left", margin: "2vh" }}>
										<Typography>Upload a different headshot image</Typography>
									</div>

									<div>
										<Tooltip title="Upload headshot">
											<label for="upload-headshot-image" style={{ cursor: "pointer" }}>
												<AddPhotoAlternateIcon fontSize="large" color="primary" />
											</label>
										</Tooltip>
										<input id="upload-headshot-image" type="file" name="headShotImage" onChange={handleChange} accept="image/*" style={{ opacity: 0 }} />
									</div>
								</div>
							</div>
						</Grid>

						<Grid item xs={12}>
							<Typography>Image43</Typography>
							<Divider />
							<div style={{ padding: "2vh" }}>
								{editState.image43 && (
									<Typography>{editState.image43.name}</Typography> // if uploading new show name otherwise show current hero image
								)}
								{!editState.image43 && (
									<img
										src={teacherProfileDetails.Bio ?
											teacherProfileDetails.Bio.image43URL : null // if uploading new show name otherwise show current hero image
										}
										style={{ width: "50vh" }}
										alt="43 failed to load"
									/>
								)}

								<div style={{ display: "flex" }}>
									<div style={{ float: "left", margin: "2vh" }}>
										<Typography>Upload a different 43image</Typography>
									</div>

									<div>
										<Tooltip title="Upload 4:3 image">
											<label for="upload-43-image" style={{ cursor: "pointer" }}>
												<AddPhotoAlternateIcon fontSize="large" color="primary" />
											</label>
										</Tooltip>
										<input id="upload-43-image" type="file" name="image43" onChange={handleChange} accept="image/*" style={{ opacity: 0 }} />
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</CardContent>

				<CardActions style={{ padding: "2vh" }}>
					<Button disabled={!change} variant="contained" onClick={handleSaveClick} style={{ color: "white", backgroundColor: "#8cb494" }}>
						{editing && <CircularProgress size={15} style={{ marginRight: "1vh" }} />}
						Save Changes
					</Button>

					<Button style={{ color: "#343434" }} onClick={(handleCancelClick)}>
						Cancel Editing
					</Button>
				</CardActions>
			</Card>
		</Modal>
	)
}

const EditAccountModal = props => {

	const { editingAccount, stopEditingAccount, teacherProfilePasswordUpdate } = props
	const [snackbar, setSnackbar] = useState({ severity: undefined, message: undefined, open: false })
	const [editPasswordState, setEditPasswordState] = useState({
		currentPassword: "",
		newPassword: "",
		newPasswordConfirm: "",
		errorNewPasswordConfirm: false,
		token: "",
		teacherId: "",
	})

	const handleSaveClick = () => {
		props.updateTeacherPassword(editPasswordState)

		// 	setSnackbar({ message: `Couldn't update password - `, open: true, severity: "error", backgroundColor: "red" })
		// 	return

		// setSnackbar({ message: "Password Changed", open: true, severity: "success", backgroundColor: "#8cb494" })


	}

	const handleCancelClick = () => {
		stopEditingAccount()
	}

	const handleTextFieldChange = e => {
		let errorNewPasswordConfirm = false

		if (e.target.name === "newPassword") {
			// only sure error if different and newPasswordConfirm isn't empty - implies user went back to change password after entering confirm
			errorNewPasswordConfirm = e.target.value !== editPasswordState.newPasswordConfirm && editPasswordState.newPasswordConfirm.length > 3
		} else if (e.target.name === "newPasswordConfirm") {
			// only set error on confirm because unnecessary red is annoying
			errorNewPasswordConfirm = e.target.value !== editPasswordState.newPassword
		} else {
			errorNewPasswordConfirm = editPasswordState.newPassword !== editPasswordState.newPasswordConfirm
		}

		setEditPasswordState({
			...editPasswordState,
			[e.target.name]: e.target.value,
			errorNewPasswordConfirm,
			teacherId: props.loginData.userID,
			token: props.loginData.access,
		})
	}

	const classes = useStyles()

	useEffect(() => {
		if (teacherProfilePasswordUpdate.status == "200") {
			setSnackbar({ message: "Password Changed", open: true, severity: "success", backgroundColor: "#8cb494" })
		}
		else {
			console.error("Couldn't update password ")
			//setSnackbar({ message: `Couldn't update password `, open: true, severity: "error", backgroundColor: "red" })
		}
		// else if( teacherProfilePasswordUpdate.status !== 200 ){

		// 	setSnackbar({ message: `Couldn't update password - ${teacherProfilePasswordUpdate.status}`, open: true, severity: "error", backgroundColor: "red" })
		// }
		// else{
		// 	return false;
		// }
	}, [teacherProfilePasswordUpdate]);



	return (
		<>
			<Snackbar
				open={snackbar.open}
				autoHideDuration={5000}
				onClose={() => {
					setSnackbar({ open: false, message: snackbar.message, severity: snackbar.severity })
				}}
			>
				<Alert severity={snackbar.severity} style={snackbar.backgroundColor ? { backgroundColor: snackbar.backgroundColor } : { backgroundColor: "#343434" }}>
					{snackbar.message}
				</Alert>
			</Snackbar>
			<Modal disableEnforceFocus={true} disableAutoFocus={true} open={editingAccount} close={stopEditingAccount} className={classes.editProfileModal}>
				<Card>
					<CardContent>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="h6">Edit Account Settings</Typography>
							</Grid>
							<Grid item xs={12} s={12} md={12} lg={12} style={{ padding: "2vh" }}>
								<TextField name="currentPassword" value={editPasswordState.currentPassword} variant="outlined" fullWidth placeholder="Current Password" onChange={handleTextFieldChange} error={editPasswordState.errorCurrentPassword} />
							</Grid>
							<Grid item xs={12} s={12} md={12} lg={12} style={{ padding: "2vh" }}>
								<TextField name="newPassword" value={editPasswordState.NewPassword} variant="outlined" fullWidth placeholder="New Password" onChange={handleTextFieldChange} />
							</Grid>
							<Grid item xs={12} s={12} md={12} lg={12} style={{ padding: "2vh" }}>
								<TextField name="newPasswordConfirm" value={editPasswordState.NewPasswordConfirm} variant="outlined" fullWidth placeholder="Confirm New Password" onChange={handleTextFieldChange} error={editPasswordState.errorNewPasswordConfirm} helperText={editPasswordState.errorNewPasswordConfirm ? "New password and confirm new password do not match" : ""} />
							</Grid>
						</Grid>
					</CardContent>

					<CardActions style={{ padding: "2vh" }}>
						<Button variant="contained" onClick={handleSaveClick} style={{ color: "white", backgroundColor: "#8cb494" }}>
							Save Changes
						</Button>

						<Button style={{ color: "#343434" }} onClick={(handleCancelClick)}>
							Cancel Editing
						</Button>
					</CardActions>
				</Card>
			</Modal>
		</>
	)
}



const ProfileAndAccount = (props) => {

	const [snackbar, setSnackbar] = useState({ severity: undefined, message: undefined, open: false })
	const { loginData, teacherProfileDetails, teacherProfilePasswordUpdate, teacherImageUpdate, teacherProfileImageUpdate, teacherProfileUpdate, teacherProfileBioUpdate, updateTeacherPassword } = props;

	useEffect(async () => {
		//componentDidMount
		// await props.getAccountData() // await on first render after mount so we can prefill data in edit modal
		// const refresh = setInterval(() => props.getAccountData(), 10000)


		//props.getOtsClassStatus(false);
		const data = {
			token: loginData ? loginData.access : null,
			teacherId: loginData ? loginData.userID : null,
		};
		props.fetchProfileDetails(data);


	}, []);

	useEffect(() => {
		if (props.tokenExpire) {
		  props.history.push("/")
		}
	  }, [props.tokenExpire]);

	useEffect(() => {

		if (teacherProfileDetails.Teacher) {
			setSnackbar({ message: "Profile Updated", open: true, severity: "success", backgroundColor: "#8cb494" })
		}
	}, [teacherProfileDetails]);

	useEffect(() => {
		//componentDidUnMount
		return () => {
			// stop and remove refresh timer
			// clearInterval(this.refresh)
			// this.refresh = null
		}
	}, []);

	const [editingAccount, setStartEditingAccount] = useState(false)
	const [editing, setstartEditing] = useState(false)


	const startEditing = () => {
		setstartEditing(true)
	}
	const stopEditing = () => {
		setstartEditing(false)
	}
	const startEditingAccount = () => {
		setStartEditingAccount(true)
	}
	const stopEditingAccount = () => {
		setStartEditingAccount(false)
	}

	return (
		<>


			<div id="editmodal">
				<EditProfileModal editingt={editing} stopEditing={stopEditing} teacherProfileDetails={teacherProfileDetails} loginData={loginData} teacherImageUpdate={teacherImageUpdate} teacherProfileImageUpdate={teacherProfileImageUpdate} teacherProfileBioUpdate={teacherProfileBioUpdate} teacherProfileUpdate={teacherProfileUpdate} />
			</div>

			<div id="editaccountmodal">
				<EditAccountModal setSnackbar={setSnackbar} loginData={loginData} editingAccount={editingAccount} stopEditingAccount={stopEditingAccount} teacherProfilePasswordUpdate={teacherProfilePasswordUpdate} updateTeacherPassword={updateTeacherPassword} />
			</div>

			<div style={{ backgroundColor: "black", top: 100, marginBottom: 50 }}>

				<img src={teacherProfileDetails.Teacher ? teacherProfileDetails.Bio.imageURL : null} alt="failed to load" width="100%" height="100%" style={{ opacity: 0.35 }} />

				<div style={{ position: "absolute", top: "10vh", right: "4.5vh", zIndex: "100" }}>
					<Tooltip placement="left" title={<Typography style={{ color: "white" }}>Edit Your Profile Information</Typography>}>
						<div onClick={() => { startEditing() }}>
							<IconButton >
								<EditIcon style={{ fontSize: 50, color: "#8cb494" }} />
							</IconButton></div>
					</Tooltip>
				</div>
				<Card style={{ width: "100%", color: "white", height: "100%", position: "absolute", top: 60, backgroundColor: "transparent" }}>
					<Grid container style={{ padding: "8vh", marginTop: "25%" }}>
						<Grid item xs={12}>
							<Typography variant="h1" style={{ color: "white" }}>
								{teacherProfileDetails.Teacher ? (
									<>
										<span>{teacherProfileDetails.Teacher.firstName}</span>
										<span>{teacherProfileDetails.Teacher.lastName}</span>
									</>
								) : (
									null
								)}

							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6">{"	"}</Typography>
						</Grid>
					</Grid>
				</Card>
			</div>

			<Container maxWidth="lg">
				<Grid container style={{ margin: 15 }}>
					<Grid item xs={12} style={{ marginBottom: 15 }}>
						<Typography variant="h5">About {teacherProfileDetails.Teacher ? (
							<>
								<span>{teacherProfileDetails.Teacher.name}</span>
							</>
						) : (
							null
						)}
						</Typography>
						<Divider color="secondary" style={{ width: "30vh" }} />
					</Grid>

					<Grid item xs={8}>
						<Box lineHeight={2} fontSize="2.5vh">
							{teacherProfileDetails.Teacher ? (
								<>
									<span>{teacherProfileDetails.Bio.bio}</span>
								</>
							) : (
								null
							)}
						</Box>
					</Grid>
				</Grid>
			</Container>

			<Divider variant="middle" />

			<Container maxWidth="lg" style={{ marginTop: "40vh" }}>
				<Paper elevation={3}>
					<Grid container style={{ margin: 15 }}>
						<Grid item xs={12}>
							<Typography variant="h5">Account Settings</Typography>
						</Grid>

						<Grid item xs={6}>
							<Button variant="contained" style={{ margin: 25, backgroundColor: "#343434", color: "white" }} onClick={startEditingAccount}>
								Edit Account Information Including Password
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProfileDetails: (data) => dispatch(fetchProfileDetails(data)),
		updateTeacherPassword: (data) => dispatch(updateTeacherPassword(data)),
		teacherProfileImageUpdate: (state, imageUrl, ImageType) => dispatch(teacherProfileImageUpdate(state, imageUrl, ImageType)),
		teacherProfileBioUpdate: (data) => dispatch(teacherProfileBioUpdate(data)),
		teacherProfileUpdate: (data) => dispatch(teacherProfileUpdate(data)),
	};
};

const mapStateToProps = (state) => {
	return {
		tokenExpire: state.tokenExpire,
		loginData: state.loginData,
		isLogout: state.isLogout,
		teacherProfileDetails: state.teacherProfileDetails,
		teacherProfilePasswordUpdate: state.teacherProfilePasswordUpdate,
		teacherImageUpdate: state.teacherImageUpdate,

	};
};
export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(ProfileAndAccount);